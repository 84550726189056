import type {State} from '../store';

export const SET_ERROR = 'SET_ERROR';
type SET_ERROR = typeof SET_ERROR;

export const SET_LOADING = 'SET_LOADING';
type SET_LOADING = typeof SET_LOADING;

export const setError = (
  error: boolean,
): {type: SET_ERROR; payload: boolean} => ({
  type: SET_ERROR,
  payload: error,
});

export const setLoading = (
  loading: boolean,
): {type: SET_LOADING; payload: boolean} => ({
  type: SET_LOADING,
  payload: loading,
});

export type Action =
  | ReturnType<typeof setLoading>
  | ReturnType<typeof setError>;

export default (state: State, action: Action) => {
  const {type, payload} = action;
  switch (type) {
    case SET_ERROR: {
      return {
        ...state,
        error: payload,
      };
    }
    case SET_LOADING: {
      return {
        ...state,
        loading: payload,
      };
    }
    default:
      console.log('action type not found: ', type);
      return state;
  }
};
