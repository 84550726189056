type GetLegalDisclaimerProps = {
  partnerName?: string;
  isShowSettlementCostsFromPartner?: boolean;
};

export const getLegalDisclaimer = ({
  partnerName,
  isShowSettlementCostsFromPartner,
}: GetLegalDisclaimerProps): string => {
  if (partnerName && isShowSettlementCostsFromPartner) {
    return `*The estimates provided here are for informational purposes only and should not be relied upon as actual closing figures. Compass and ${partnerName} offer no guarantee or warranty of results. Calculations include previously entered commission percentages. Property taxes are presumed to be reliable but may not be accurate. Contact ${partnerName} prior to going under contract for more details.`;
  }

  return '*The estimates provided here are for informational purposes only and should not be relied upon as actual closing figures. Compass offers no guarantee or warranty of results. Calculations include previously entered commission percentages. Property taxes are presumed to be reliable but may not be accurate.';
};
