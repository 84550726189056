import React, {FC, createRef, useEffect, useState, RefObject} from 'react';
import styled from '@emotion/styled';

import {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';
import {Adjustment} from '@uc/thrift2npme/dist/valuation/ai_valuation/adjustments';
import {
  AgentInputs,
  CmaOutputSection,
} from '@uc/thrift2npme/dist/cma/cma_models';
import {color, mq} from '@uc/cx-design-tokens';
import {Icon, Button} from '@uc/cx.react';

import {
  type AllDisplayFieldsType,
  type PhotoImageClickType,
  type ListingPhotoClickType,
} from '@/types/types';
import {scrollContainerToElement} from '@/components/pages/Web/helpers/scrollContainerToElement';
import {
  ADJUSTED_PRICE_BOX_HEIGHT,
  ADJUSTMENTS_ITEM_HEIGHT,
  COMP_COLUMN_WIDTH,
  COMP_COLUMN_WIDTH_MOBILE,
  PHOTO_ADDRESS_HEIGHT,
  TOGGLE_ADJUSTMENTS_HEIGHT,
  ViewKeys,
} from './constants';
import {PropertyColumn} from './PropertyColumn';
import {ViewChooser} from './ViewChooser';
import {useResponsive} from '@/hooks';

export const getAdjustmentsBlockHeight = (
  compListings: ProcessedListing[],
  appliedAdjustments: {[key: string]: Adjustment[]},
  isAdjustmentsBlockExpanded: boolean,
) => {
  let adjustmentsBlockHeight = 0;
  compListings.forEach(listing => {
    const propertyAppliedAdjustments =
      listing.listingIdSHA && listing.listingIdSHA in appliedAdjustments
        ? appliedAdjustments[listing.listingIdSHA] || []
        : [];
    const shownAdjustmentsCount = isAdjustmentsBlockExpanded
      ? propertyAppliedAdjustments.length
      : Math.min(3, propertyAppliedAdjustments.length);

    const expandToggleHeight =
      propertyAppliedAdjustments.length > 3 ? TOGGLE_ADJUSTMENTS_HEIGHT : 0;

    const adjustedPriceBoxHeight = propertyAppliedAdjustments.length
      ? ADJUSTED_PRICE_BOX_HEIGHT
      : 0;

    adjustmentsBlockHeight = Math.max(
      adjustmentsBlockHeight,
      shownAdjustmentsCount * ADJUSTMENTS_ITEM_HEIGHT +
        expandToggleHeight +
        adjustedPriceBoxHeight,
    );
  });
  return adjustmentsBlockHeight;
};

type SideBySideComparisonProps = {
  cmaId: string;
  subjectProperty: ProcessedListing;
  compListings: ProcessedListing[];
  agentInputs: AgentInputs | undefined;
  isPreview: boolean;
  allDisplayFields: AllDisplayFieldsType;
  sectionRef?: React.Ref<HTMLElement | null>;
  viewPageRef?: RefObject<HTMLDivElement>;
  onPhotoImageClick: PhotoImageClickType;
  onListingPhotoClick: ListingPhotoClickType;
};

export const SideBySideComparison: FC<SideBySideComparisonProps> = ({
  cmaId,
  subjectProperty,
  compListings,
  agentInputs,
  isPreview,
  allDisplayFields,
  sectionRef,
  viewPageRef,
  onPhotoImageClick,
  onListingPhotoClick,
}) => {
  const appliedAdjustments = agentInputs?.appliedAdjustments || {};
  const presentationalSettings = agentInputs?.presentationalSettings || {};
  const {visibleSections = []} = presentationalSettings || {};

  const comparablePropertiesSectionDetails =
    visibleSections.find(
      visibleSection =>
        visibleSection.sectionType === CmaOutputSection.SIDE_BY_SIDE_COMPARISON,
    )?.comparablePropertiesSection || {};
  const hiddenSideBySideListingAttributes =
    comparablePropertiesSectionDetails.hiddenSideBySideListingAttributes || [];
  const hideAgentNotes = comparablePropertiesSectionDetails.hideAgentNotes;

  const [columnView, setColumnView] = useState<ViewKeys>(
    ViewKeys.LISTING_DETAILS,
  );
  const [isAdjustmentsBlockExpanded, setIsAdjustmentsBlockExpanded] =
    useState(false);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const [compContainerLeftPosition, setCompContainerLeftPosition] = useState(0);
  const [tooltipState, setTooltipState] = useState<{
    boundingRect: DOMRect;
    index: number;
    text: string;
  } | null>(null);

  const {isMobile} = useResponsive();

  const onViewChooserTokenClick = () => {
    if (sectionRef !== null && typeof sectionRef === 'object') {
      scrollContainerToElement(viewPageRef, sectionRef);
    }
  };

  const adjustmentsBlockHeight = getAdjustmentsBlockHeight(
    compListings,
    appliedAdjustments,
    isAdjustmentsBlockExpanded,
  );

  const toggleAdjustmentsBlock = () => {
    setIsAdjustmentsBlockExpanded(!isAdjustmentsBlockExpanded);
  };

  const compListingContainerRef: React.RefObject<HTMLDivElement> = createRef();

  const scrollCompListingContainer = (val: number) => {
    if (compListingContainerRef.current) {
      const scrollPosition = compListingContainerRef.current.scrollLeft;
      const containerWidth = compListingContainerRef.current.offsetWidth;
      const contentWidth = compListingContainerRef.current.scrollWidth;
      if (val > 0 && contentWidth - scrollPosition - containerWidth < val) {
        val = contentWidth - scrollPosition - containerWidth;
      } else if (val < 0 && scrollPosition + val < 0) {
        val = 0 - scrollPosition;
      }
      compListingContainerRef.current.scroll?.({
        left: scrollPosition + val,
        behavior: 'smooth',
      });
    }
  };

  const onScrollCheckToHideArrow = () => {
    if (compListingContainerRef.current) {
      const scrollPosition = compListingContainerRef.current.scrollLeft;
      const containerWidth = compListingContainerRef.current.offsetWidth;
      const contentWidth = compListingContainerRef.current.scrollWidth;

      if (scrollPosition === 0) {
        setCanScrollLeft(false);
      } else {
        setCanScrollLeft(true);
      }
      if (scrollPosition === contentWidth - containerWidth) {
        setCanScrollRight(false);
      } else {
        setCanScrollRight(true);
      }
    }
  };

  // hide arrow is we don't need to scroll
  useEffect(() => {
    const compContainerEl = compListingContainerRef.current;
    if (compContainerEl) {
      const containerWidth = compContainerEl.offsetWidth;
      const contentWidth = compContainerEl.scrollWidth;
      const compContainerStart = compContainerEl?.getBoundingClientRect()?.left;
      setCompContainerLeftPosition(compContainerStart);
      if (contentWidth <= containerWidth) {
        setCanScrollRight(false);
      }
    }
  }, []);

  const adjustmentsExist = Object.keys(appliedAdjustments).length > 0;

  const scrollArrowOffset = PHOTO_ADDRESS_HEIGHT + adjustmentsBlockHeight + 90;

  return (
    <>
      <Wrapper isPreview={isPreview}>
        {!isMobile && (
          <SubjectContainer>
            <PropertyColumn
              agentInputs={agentInputs}
              columnView={columnView}
              isSubject
              listing={subjectProperty!}
              adjustmentsBlockHeight={adjustmentsBlockHeight}
              adjustmentsExist={adjustmentsExist}
              allDisplayFields={allDisplayFields}
              hiddenSideBySideListingAttributes={
                hiddenSideBySideListingAttributes
              }
              hideAgentNotes={hideAgentNotes}
              onPhotoImageClick={onPhotoImageClick}
              onListingPhotoClick={onListingPhotoClick}
            />
          </SubjectContainer>
        )}
        <CompListingsScrollWrapper>
          <CompListingsContainer
            ref={compListingContainerRef}
            onScroll={() => onScrollCheckToHideArrow()}
          >
            {isMobile && (
              <PropertyColumn
                agentInputs={agentInputs}
                columnView={columnView}
                isSubject
                listing={subjectProperty}
                adjustmentsBlockHeight={adjustmentsBlockHeight}
                adjustmentsExist={adjustmentsExist}
                allDisplayFields={allDisplayFields}
                hiddenSideBySideListingAttributes={
                  hiddenSideBySideListingAttributes
                }
                hideAgentNotes={hideAgentNotes}
                onPhotoImageClick={onPhotoImageClick}
                onListingPhotoClick={onListingPhotoClick}
              />
            )}
            {compListings?.map((compListing, index) => (
              <PropertyColumn
                agentInputs={agentInputs}
                columnView={columnView}
                isSubject={false}
                index={index}
                key={`${compListing.listingIdSHA}-${columnView}`}
                listing={compListing}
                adjustmentsBlockHeight={adjustmentsBlockHeight}
                appliedAdjustments={appliedAdjustments}
                isAdjustmentsBlockExpanded={isAdjustmentsBlockExpanded}
                toggleAdjustmentsBlock={toggleAdjustmentsBlock}
                setTooltipState={setTooltipState}
                adjustmentsExist={adjustmentsExist}
                allDisplayFields={allDisplayFields}
                hiddenSideBySideListingAttributes={
                  hiddenSideBySideListingAttributes
                }
                hideAgentNotes={hideAgentNotes}
                onPhotoImageClick={onPhotoImageClick}
                onListingPhotoClick={onListingPhotoClick}
              />
            ))}
            {tooltipState && (
              <PopTip
                className="cx-poptip cx-poptip--bottom"
                rect={tooltipState?.boundingRect}
                compPosition={tooltipState?.index + 1}
                containerLeftPosition={compContainerLeftPosition}
                data-tn="poptip"
              >
                {tooltipState?.text}
              </PopTip>
            )}
          </CompListingsContainer>
          <LeftSlideButton
            isShow={canScrollLeft}
            variant="naked"
            aria-label="slide to left button"
            topOffset={scrollArrowOffset}
            onClick={() => scrollCompListingContainer(-COMP_COLUMN_WIDTH)}
          >
            <IconChevronLeft />
          </LeftSlideButton>
          <RightSlideButton
            isShow={canScrollRight}
            variant="naked"
            aria-label="slide to right button"
            topOffset={scrollArrowOffset}
            onClick={() => scrollCompListingContainer(COMP_COLUMN_WIDTH)}
          >
            <IconChevronRight />
          </RightSlideButton>
        </CompListingsScrollWrapper>
      </Wrapper>
      <ViewChooser
        isPreview={isPreview}
        cmaId={cmaId}
        subjectProperty={subjectProperty}
        compListings={compListings}
        columnView={columnView}
        setColumnView={setColumnView}
        onViewChooserTokenClick={onViewChooserTokenClick}
      />
    </>
  );
};

const Wrapper = styled.div<{isPreview: boolean}>`
  background-color: ${color.background};
  position: relative;
  display: flex;
  margin-bottom: -174px;
  z-index: 0;
  ${(props: {isPreview: boolean}) => props.isPreview && 'min-height: 50vh;'};
  // TODO: Once we can use an extensible Poptip from DS, replace the
  // class targeting here with the Poptip component
  .cx-react-poptip {
    max-width: 250px;
  }
  @media ${mq.maxWidthMobileLandscape} {
    width: 100%;
    margin-bottom: 0;
    // TODO: Once we can use an extensible Poptip from DS, replace the
    // class targeting here with the Poptip component
    .cx-react-poptip {
      max-width: ${COMP_COLUMN_WIDTH_MOBILE}px;
    }
  }
`;

const SubjectContainer = styled.div`
  display: flex;
  vertical-align: top;
  padding: 10px 0;
  box-shadow: 5px 0px 5px -3px ${color.dropShadow};
  &:hover ~ .cx-react-poptip {
    display: block;
  }
`;

const CompListingsScrollWrapper = styled.div`
  position: relative;
  display: flex;
  vertical-align: top;
  width: calc(100% - 250px);
  ~ .cx-react-poptip {
    display: none;
  }
  @media ${mq.maxWidthMobileLandscape} {
    width: 100%;
    ~ .cx-react-poptip {
      display: block;
    }
  }
`;

const CompListingsContainer = styled.div`
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  padding: 10px 5px;
  padding-bottom: 0;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${color.grey80};
    box-shadow: 0 0 1px ${color.grey10};
  }
  @media ${mq.maxWidthMobileLandscape} {
    padding: 0;
    box-shadow: none;
  }
`;

const SlideButton = styled(Button)<{topOffset: number; isShow?: boolean}>`
  &.cx-react-button {
    position: absolute;
  }
  width: 35px;
  height: 35px;
  top: ${(props: {topOffset: number}) => props.topOffset}px;
  border-radius: 50%;
  padding-top: 5px;
  padding-left: 7px;
  background-color: ${color.black};
  cursor: pointer;
  &:hover {
    background-color: ${color.black};
  }

  ${({isShow}) => `display: ${isShow ? 'block' : 'none'}`}
`;

const LeftSlideButton = styled(SlideButton)`
  left: 10px;
`;

const RightSlideButton = styled(SlideButton)`
  right: 10px;
`;

const sharedIconStyles = `
  width: 12px;
  height: 12px;
  transform: scale(1.3);
  position: absolute;
  top: calc(50% - 6px);
  left: calc(50% - 6px);
  fill: ${color.white};
`;

const IconChevronLeft = styled(Icon.ChevronLeft)`
  ${sharedIconStyles}
`;

const IconChevronRight = styled(Icon.ChevronRight)`
  ${sharedIconStyles}
`;

const PopTip = styled.div<{
  rect: DOMRect;
  compPosition: number;
  containerLeftPosition: number;
}>`
  display: block;
  width: ${COMP_COLUMN_WIDTH}px;
  white-space: break-spaces;
  ${({rect, containerLeftPosition}) => `
      top: ${rect?.top - 30}px;
      left: ${rect?.left - containerLeftPosition}px;
    `}
  @media ${mq.maxWidthMobileLandscape} {
    ${({compPosition, containerLeftPosition}) => `
        width: ${COMP_COLUMN_WIDTH_MOBILE}px;
        left: ${compPosition * COMP_COLUMN_WIDTH_MOBILE - containerLeftPosition}px;
      `};
  }
`;
