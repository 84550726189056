import styled from '@emotion/styled';
import React from 'react';

import {color, mq} from '@uc/cx-design-tokens';

import {agentAvatarSrc} from '@/components/pages/PreviewPage/assets/AgentAvatar';

interface Props {
  className?: string;
  avatarUrl?: string;
}

const StyledAgentAvatar = styled.div<Props>`
  background-image: url(${(props: Props) => props.avatarUrl || agentAvatarSrc});
  background-size: cover;
  background-color: ${color.white};
  width: 104px;
  height: 104px;
  vertical-align: middle;
  border-radius: 0;
  flex-shrink: 0;

  @media ${mq.maxWidthMobileLandscape} {
    width: 80px;
    height: 80px;
  }
`;

export const AgentAvatar: React.FC<Props> = ({className, avatarUrl}: Props) => (
  <StyledAgentAvatar
    data-tn="agent-avatar"
    avatarUrl={avatarUrl}
    className={`${className} cx-avatar cx-avatar--xxl cx-avatar--image`}
  />
);
