import {
  SellerNetSheetCost,
  SellerNetSheetCostInputComponentType,
} from '@uc/thrift2npme/dist/apex_proxy/apex_proxy_common';
import {NetSheetsLineItem} from '@uc/thrift2npme/dist/cma/cma_models';

const UNKNOWN_ERROR = {
  response: {
    status: 1000,
    statusText: 'An unknown error occurred during fetch',
  },
};

export class FetchError implements Partial<Response> {
  status: number;
  statusText: string;
  constructor(response?: Response) {
    this.status = response?.status || UNKNOWN_ERROR.response.status;
    this.statusText = response?.statusText || UNKNOWN_ERROR.response.statusText;
  }
}

export const convertSellerNetCostsToNetSheetGeneric = (
  costs: SellerNetSheetCost[],
): NetSheetsLineItem[] => {
  return costs?.map(
    ({
      name,
      values = [],
      isPercentage,
      id,
      isRange,
      componentType,
      isOverwrite,
    }) => {
      const [minValue = '', maxValue = ''] = values;
      const isStringValue =
        componentType === SellerNetSheetCostInputComponentType.RADIO;

      return {
        name,
        minValue,
        maxValue,
        isPercentage: isPercentage ?? false,
        isRange: isRange ?? false,
        id,
        stringValue: isStringValue ? minValue : '',
        isStringValue,
        isOverwrite: isOverwrite ?? false,
      };
    },
  );
};
