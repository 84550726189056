import React from 'react';
import {MapMarker} from '@uc-maps/maps.react';

import {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';

import {mapPinTypeToColor} from '@/constants';
import {getPinType} from '@/utils/getPinType';
import {getCompLabel} from './helpers';

type MapProps = {
  compListings: ProcessedListing[];
  subjectProperty: ProcessedListing;
};

const SubjectMarkerIcon = () => (
  <svg
    data-tn="subject-marker"
    width="40px"
    height="40px"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 2 2"
  >
    <circle cx="1" cy="1" r="0.5" fill="#000" stroke="#000" />
    <polygon
      points="0.5,.05 .2,0.9 0.95,.35 .05,.35 0.8,0.9"
      fill="#FFF"
      transform="translate(.5 .5)"
    />
  </svg>
);

const MarkerIcon = ({listing}: {listing: ProcessedListing}) => {
  const label = getCompLabel(listing);
  const pinColor = mapPinTypeToColor[getPinType(listing)];
  return (
    <svg data-tn={`marker-${listing.listingIdSHA}`} width="50px" height="50px">
      <svg
        viewBox="0 0 128 128"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <rect
          x="1"
          y="33"
          width="126"
          height="62"
          rx="1"
          strokeWidth="2"
          stroke="#ddd"
          fill={pinColor}
        />
        <polyline
          points="59,95 69,95"
          strokeWidth="3"
          strokeLinecap="round"
          stroke={pinColor}
        />
        <polyline
          points="58,95 64,102 70,95"
          stroke="#ddd"
          strokeWidth="2"
          strokeLinecap="round"
          fill={pinColor}
        />
      </svg>
      <text
        x="50%"
        y="53%"
        fill={label.color}
        fontWeight={label.fontWeight}
        fontFamily={label.fontFamily}
        fontSize="14px"
        dominantBaseline="middle"
        textAnchor="middle"
      >
        {label.text}
      </text>
    </svg>
  );
};

export const ComparableMapMarkers = ({
  compListings,
  subjectProperty,
}: MapProps) => {
  return (
    <>
      <MapMarker
        position={[
          subjectProperty.location?.longitude || 0,
          subjectProperty.location?.latitude || 0,
        ]}
      >
        <SubjectMarkerIcon />
      </MapMarker>
      {compListings.map(listing => (
        <MapMarker
          key={listing.listingIdSHA}
          position={[
            listing.location?.longitude || 0,
            listing.location?.latitude || 0,
          ]}
        >
          <MarkerIcon listing={listing} />
        </MapMarker>
      ))}
    </>
  );
};
