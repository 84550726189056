import produce from 'immer';

import {
  get as cmaServiceGetCma,
  update as cmaServiceUpdateCma,
} from '@uc/thrift2npme/dist/cma/cma_service.ucfetch';

import {ApiError} from '@/types/ApiError';
import {createCmaUpdateReducer} from '../cmaUpdatesUtils';
import IStoreState, {ThunkFunction} from '../types/Store';
import {AI_ACTION_TYPES} from './ai';
import {CMA_ACTION_TYPES, setCmaLastUpdateTime} from './cma';
import {COMPS_ACTION_TYPES} from './comps';
import {PRESENTATIONAL_SETTINGS_ACTION_TYPES} from './presentationalSettings';
import {PROPERTY_FIELDS_ACTION_TYPES} from './propertyFields';
import {SEARCH_QUERY_ACTION_TYPES} from './searchQuery';
import {SUBJECT_ASSETS_ACTION_TYPES} from './subjectAssets';
import type {ICma} from './types/Cma';
import ICmaUpdates from './types/CmaUpdates';
import {trackCmaUpdateFailure} from '@/utils/analyticTracks';
import {initialCma} from '@/context/utils';

const MAX_RETRY_COUNT = 3;

export const cmaUpdatesInitalState: ICmaUpdates = {
  hasUnsavedChanges: false,
  isSaving: false,
  error: null,
  // We want to keep track of the number of retries in case of an update
  // failure to prevent an infinite loop of them. This count gets reset back
  // to zero if an action that triggers a CMA save.
  retryCount: 0,
};

export const CMA_UPDATES_ACTION_TYPES = {
  UPDATE_CMA: 'UPDATE_CMA',
  UPDATE_CMA_SUCCESS: 'UPDATE_CMA_SUCCESS',
  UPDATE_CMA_FAILURE: 'UPDATE_CMA_FAILURE',
} as const;

// HELPERS
export const shouldUpdateCma = (cmaUpdates: ICmaUpdates) =>
  cmaUpdates.hasUnsavedChanges &&
  !cmaUpdates.isSaving &&
  cmaUpdates.retryCount < MAX_RETRY_COUNT;

const getFullCma = (cmaState: ICma, latestState: IStoreState) =>
  produce(cmaState, (draftState: ICma) => {
    if (draftState.cma.agentInputs) {
      draftState.cma.agentInputs = {
        ...draftState.cma.agentInputs,
        ...initialCma(latestState).agentInputs,
      };
    }
  });

// ACTIONS
const updateCmaActionCreator = () => ({
  type: CMA_UPDATES_ACTION_TYPES.UPDATE_CMA,
});

const updateCmaSuccess = () => ({
  type: CMA_UPDATES_ACTION_TYPES.UPDATE_CMA_SUCCESS,
});

const updateCmaFailure = (error: ApiError | null) => ({
  type: CMA_UPDATES_ACTION_TYPES.UPDATE_CMA_FAILURE,
  payload: {error},
});

export const getLatestUpdateTimestamp = async (
  cmaId: string,
  version: string,
) => {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const latestCmaResp = await cmaServiceGetCma(cmaId, version, {
      mask: ['metadata.lastUpdateTimeMs'],
    });
    return latestCmaResp?.cma?.metadata?.lastUpdateTimeMs;
  } catch (e) {}
};

// TODO (wei.liang@compass.com):  There is no guarantee that each cma update operation will be executed correctly
// when the application needs to update cma in parallel.
// THUNKS
export const updateCma =
  (): ThunkFunction => async (dispatch, state, getState) => {
    const latestState = getState();
    if (shouldUpdateCma(latestState.cmaUpdates as ICmaUpdates)) {
      dispatch(updateCmaActionCreator());
      const fullCmaToUpdate: ICma = getFullCma(latestState.cma, latestState);

      try {
        const resp = await cmaServiceUpdateCma(fullCmaToUpdate);
        dispatch(
          setCmaLastUpdateTime(resp?.cma?.metadata?.lastUpdateTimeMs as number),
        );
        dispatch(updateCmaSuccess());
      } catch (error) {
        const cmaId = latestState.cma?.cma?.cmaId as string;
        const cmaVersion = latestState.cma?.cma?.version as string;
        const currentLastUpdatedTime = fullCmaToUpdate?.cma?.metadata
          ?.lastUpdateTimeMs as number;
        // 400: In the event of a race condition, get the latest CMA and replace the lastUpdateTimeMs in context
        // with the one from the latest CMA response
        // 504: The network times out and no response is received from the api.
        // However, the server may have updated the cma, and it is also necessary to update the most recent lastUpdateTime
        if ([504, 400].includes(error?.response?.status)) {
          const latestUpdateTimestamp = await getLatestUpdateTimestamp(
            cmaId,
            cmaVersion,
          );
          if (
            latestUpdateTimestamp &&
            currentLastUpdatedTime < latestUpdateTimestamp
          ) {
            dispatch(setCmaLastUpdateTime(latestUpdateTimestamp));
          }
        }
        dispatch(updateCmaFailure(error));

        trackCmaUpdateFailure(cmaId, currentLastUpdatedTime);
      }
    }
  };

// REDUCERS
export const updateCmaReducer = (state: ICmaUpdates) =>
  produce(state, draftState => {
    // We want to reflect that the current changes are being saved.
    // This way we don't accidentally overwrite the whether the cma is in
    // a unsaved state upon a successful update.
    draftState.hasUnsavedChanges = false;
    draftState.isSaving = true;
    draftState.error = null;
  });

export const updateCmaSuccessReducer = (state: ICmaUpdates) =>
  produce(state, draftState => {
    draftState.isSaving = false;
    draftState.error = null;
    draftState.retryCount = 0;
  });

export const updateCmaFailureReducer = (
  state: ICmaUpdates,
  payload: {error: ApiError | null; retryCount: number},
) =>
  produce(state, draftState => {
    draftState.isSaving = false;
    draftState.error = payload.error;
    // Changes failed to save so we want to reflect that in the state.
    draftState.hasUnsavedChanges = true;
    draftState.retryCount = state.retryCount + 1;
  });

// Updates the state to show the CMA has unsaved changes.
export const hasUnsavedChangesReducer = (state: ICmaUpdates) =>
  produce(state, draftState => {
    draftState.hasUnsavedChanges = true;
    draftState.retryCount = 0;
  });

export default createCmaUpdateReducer([
  AI_ACTION_TYPES.AI_SET_MODAL_RESPONSE,
  CMA_ACTION_TYPES.DELETE_FIRST_SUBJECT_PROPERTY_MEDIA_ITEM,
  CMA_ACTION_TYPES.INSERT_SUBJECT_PROPERTY_MEDIA_ITEM,
  CMA_ACTION_TYPES.SET_AGENT_NOTES,
  CMA_ACTION_TYPES.SET_DISPLAY_LISTINGS,
  CMA_ACTION_TYPES.SET_CMA_ESTIMATE_PROPERTIES,
  CMA_ACTION_TYPES.SET_CMA_TITLE,
  CMA_ACTION_TYPES.SET_CMA_UPGRADE_RECOMMENDATION,
  CMA_ACTION_TYPES.SET_COMPS_SORT_ORDER,
  CMA_ACTION_TYPES.SET_IS_MANUAL_COMPS_SEARCH,
  CMA_ACTION_TYPES.SET_SUBJECT_PROPERTY,
  COMPS_ACTION_TYPES.SET_COMPS,
  PRESENTATIONAL_SETTINGS_ACTION_TYPES.SET_SECTIONS,
  PROPERTY_FIELDS_ACTION_TYPES.SET_PROPERTY_FIELDS,
  SEARCH_QUERY_ACTION_TYPES.SET_COMPS_BUILDER_INPUTS,
  SEARCH_QUERY_ACTION_TYPES.SET_SEARCH_QUERY,
  SEARCH_QUERY_ACTION_TYPES.SET_SEARCH_QUERY_SORT_ORDER,
  SUBJECT_ASSETS_ACTION_TYPES.SET_SUBJECT_ASSETS_SUCCESS,
  SUBJECT_ASSETS_ACTION_TYPES.SET_SUBJECT_PHOTO_DEFAULT,
  CMA_ACTION_TYPES.SET_MARKET_TRENDS_CHART_QUERY,
  CMA_ACTION_TYPES.SET_MARKET_REPORT_SETTINGS,
  CMA_ACTION_TYPES.ADD_CMA_NETSHEETS,
  CMA_ACTION_TYPES.ADD_NETSHEETS_TO_CMA,
  CMA_ACTION_TYPES.SET_CMA_NETSHEETS_INPUT,
  CMA_ACTION_TYPES.SET_CMA_NETSHEETS_RESULTS,
  CMA_ACTION_TYPES.DELETE_CMA_NETSHEETS,
  CMA_ACTION_TYPES.SET_CMA_LEAD_GENERATION,
  CMA_ACTION_TYPES.SET_CMA_NETSHEETS_CUSTOM_CLOSING_FEES,
  CMA_ACTION_TYPES.UPDATE_CMA_NETSHEETS,
  CMA_ACTION_TYPES.UPDATE_CONTACT_PARTNER_AGENT,
  CMA_ACTION_TYPES.UPDATE_PARTNER_RELATED_CMA_NETSHEETS,
  PRESENTATIONAL_SETTINGS_ACTION_TYPES.TOGGLE_SELLER_NETSHEET_SECTION,
]);
