import {NetSheetsGeneric} from '@uc/thrift2npme/dist/cma/cma_models';
import {Partner} from '@uc/thrift2npme/dist/apex_proxy/apex_proxy_common';
import {NetSheetsInputs} from '@uc/thrift2npme/dist/apex/apex_service';

import {
  getError,
  getOverAllowedError,
  getRequiredError,
  getSettlementDateIsRequiredFromPartner,
} from './utils';

export function getValidateFieldsError(
  generic: NetSheetsGeneric,
  netsheetsInputs?: NetSheetsInputs,
  partnerId?: Partner,
  email?: string,
  validateRequired?: boolean,
): string | undefined {
  const {
    salesPrice,
    mortgage,
    secondSellerMortgageBalance,
    predefinedFees,
    additionalFees,
  } = generic;
  const {settlementDateMs, showSettlementCostsFromPartner = false} =
    netsheetsInputs ?? {};

  const list = [
    salesPrice,
    mortgage,
    secondSellerMortgageBalance,
    predefinedFees,
    additionalFees,
  ].flat();
  let errors: (string | undefined)[] = [];
  if (validateRequired) {
    const isSettlementDateRequired = getSettlementDateIsRequiredFromPartner(
      partnerId,
      showSettlementCostsFromPartner,
    );
    const requiredErrors = [
      salesPrice,
      isSettlementDateRequired
        ? {
            id: 'settlementDate',
            isStringValue: true,
            stringValue: settlementDateMs
              ? new Date(settlementDateMs).toISOString().split('T')[0]
              : '',
          }
        : undefined,
    ].map(getRequiredError);

    if (requiredErrors.length) {
      errors = errors.concat(requiredErrors);
    }
  }
  const validateErrors = list.map(getError);
  if (validateErrors?.length) {
    errors = errors.concat(validateErrors);
  }
  if (showSettlementCostsFromPartner && partnerId && email) {
    const errorForSalesPrice = getOverAllowedError(
      salesPrice,
      partnerId,
      email,
    );
    if (errorForSalesPrice) {
      errors.push(errorForSalesPrice);
    }
  }

  return errors.filter(Boolean)[0];
}
