import {
  QueryClient,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import {useMemo} from 'react';
import {isEqual} from 'lodash';

import {
  getSellerNetSheetCalculation,
  SellerNetSheetCalculationRequest,
  SellerNetSheetCalculationResponse,
} from '@uc/thrift2npme/dist/apex_proxy/apex_proxy_service.ucfetch';
import {
  NetSheetsGeneric,
  NetSheetsLineItem,
} from '@uc/thrift2npme/dist/cma/cma_models';
import {Partner} from '@uc/thrift2npme/dist/apex_proxy/apex_proxy_common';

import {usePartnerResultsToGeneric} from './usePartnerResultsToGeneric';
import {useStateValue} from '@/context/state';
import {useIsTNEPartnerAvailable} from './useIsTNEPartnerAvailable';
import {getValidateFieldsError} from '@/utils/netsheets/utils';
import {useDebounce} from './useDebounce';
import {convertSingleValueToRange} from '@/utils/netsheet-utils';
import {Dispatch} from '@/context/types/Store';
import updateGenericNetsheets from '@/context/ducks/netsheets/updateGenericNetsheets';

export const CALCULATION_DEBOUNCE_TIME = 300;

const API_KEY = 'getSellerNetSheetCalculation';
const MAX_RETRY_TIMES = 1;

const calculationCacheKey = (request?: SellerNetSheetCalculationRequest) => {
  return [API_KEY, request];
};

export const useSellerNetSheetCalculation = () => {
  const isTNEPartnerSpecificSNSEnabled = useIsTNEPartnerAvailable();
  const queryClient = useQueryClient();
  const [state, dispatch] = useStateValue();
  const {
    partnerConfig,
    cma: {cma},
  } = state ?? {};
  // when is set to false, BE will use the default calculation by setting partnerId to DEFAULT
  const netsheetsInputs = cma?.agentInputs?.netsheets?.input ?? {};
  const {settlementDateMs, showSettlementCostsFromPartner = false} =
    netsheetsInputs;

  const {
    partnerId,
    partnerMetadata = {},
    cityCode,
    county,
  } = partnerConfig ?? {};
  const generic = usePartnerResultsToGeneric();

  const error = useMemo(() => {
    return getValidateFieldsError(
      generic,
      netsheetsInputs,
      partnerId,
      partnerMetadata.email,
      true,
    );
  }, [generic, netsheetsInputs]);

  const {
    additionalFees: otherCosts = [],
    predefinedFees: closingCosts = [],
    mortgage = {},
    salesPrice = {},
    secondSellerMortgageBalance = {},
  } = generic;

  const isSalesPriceExist = Boolean(salesPrice.minValue || salesPrice.maxValue);

  const convertedSalesPrice = convertSingleValueToRange(salesPrice);
  const requestData: SellerNetSheetCalculationRequest = {
    general: [
      // explicitly declaim the id prop, because when update the listing price, we may lost id prop
      {...convertedSalesPrice, id: 'salesPrice'},
      mortgage,
      secondSellerMortgageBalance,
      {
        id: 'settlementDate',
        name: 'Settlement Date',
        isStringValue: true,
        stringValue: settlementDateMs
          ? new Date(settlementDateMs).toISOString().split('T')[0]
          : '',
      },
    ],
    partnerId: showSettlementCostsFromPartner ? partnerId : Partner.DEFAULT,
    closingCosts: closingCosts.map(item => {
      if (item.isRange) {
        return convertSingleValueToRange(item);
      }
      return item;
    }),
    otherCosts: otherCosts.map(item => {
      if (item.isRange) {
        return {
          ...convertSingleValueToRange(item),
          isCustom: true,
        };
      }
      return {...item, isCustom: true};
    }),
    cityCode,
    county,
  };
  const filteredRequestData = removeCostsWithoutValueSet(requestData);
  const debouncedValues = useDebounce(
    filteredRequestData,
    CALCULATION_DEBOUNCE_TIME,
  );

  // Check if debounced values are up-to-date
  const isDebouncedCurrent = useMemo(() => {
    return isEqual(debouncedValues, filteredRequestData);
  }, [debouncedValues, filteredRequestData]);

  const shouldRequest =
    isTNEPartnerSpecificSNSEnabled &&
    isSalesPriceExist &&
    !error &&
    isDebouncedCurrent;

  const query: UseQueryResult<SellerNetSheetCalculationResponse, Error> =
    useQuery(
      calculationCacheKey(debouncedValues),
      async () => {
        try {
          const cachedData = queryClient.getQueryData(
            calculationCacheKey(debouncedValues),
          );
          if (cachedData) return cachedData;

          const data = await getSellerNetSheetCalculation(debouncedValues);
          manuallySetCalculationCache(data, requestData, queryClient);
          fillInValueToInputBoxFromBEResults(dispatch, generic, data);
          return data;
        } catch (error) {
          throw new FetchError(error.response);
        }
      },
      {
        enabled: shouldRequest,
        staleTime: 5 * 60 * 1000,
        cacheTime: 10 * 60 * 1000,
        refetchOnWindowFocus: false,
        retry: MAX_RETRY_TIMES,
      },
    );

  return query;
};

// Why? Cus in CHARTWELL, we need to cache the result,
// if BE returns these two fields, we know if we pre-fill the values,will get the same result
// By this way, can reduce one request once sales price changed.
export const manuallySetCalculationCache = (
  result: SellerNetSheetCalculationResponse,
  request: SellerNetSheetCalculationRequest,
  queryClient: QueryClient,
) => {
  const upcomingRequest = {
    ...request,
    closingCosts: request?.closingCosts?.map(cost => {
      const value = result.transferAndPropertyCosts?.find(
        item => item.id === cost.id,
      );
      if (isTransferTax(value?.id)) {
        return {
          ...cost,
          minValue: value?.minValue,
          maxValue: value?.maxValue,
        };
      } else {
        return cost;
      }
    }),
  };
  queryClient.setQueryData<SellerNetSheetCalculationResponse>(
    calculationCacheKey(removeCostsWithoutValueSet(upcomingRequest)),
    result,
  );
};

// The transfer taxes input boxes need to keep the same as the calculation result
export const fillInValueToInputBoxFromBEResults = (
  dispatch: Dispatch,
  generic: NetSheetsGeneric,
  snsCalculationResult: SellerNetSheetCalculationResponse,
) => {
  const {transferAndPropertyCosts = []} = snsCalculationResult;
  const {predefinedFees = [], salesPrice} = generic;
  const updatedFees = [...predefinedFees];
  let shouldUpdateFields = false;

  transferAndPropertyCosts.forEach(item => {
    const {id, minValue, maxValue, name} = item;

    if (isTransferTax(id) && (Number(minValue) || Number(maxValue))) {
      const feeIndex = updatedFees.findIndex(fee => fee.name === name);
      const updatedFee = updatedFees[feeIndex];
      if (
        feeIndex !== -1 &&
        (minValue !== updatedFee.minValue || maxValue !== updatedFee.maxValue)
      ) {
        shouldUpdateFields = true;
        // only update the fields when sales price min/max value exists
        updatedFees[feeIndex] = {
          ...updatedFee,
          minValue: salesPrice?.minValue ? minValue : '',
          maxValue: salesPrice?.maxValue ? maxValue : '',
        };
      }
    }
  });

  if (shouldUpdateFields) {
    dispatch(
      updateGenericNetsheets({
        ...generic,
        predefinedFees: updatedFees,
      }),
    );
  }
};

export const isTransferTax = (id?: string): boolean =>
  id === 'cityTransferTax' || id === 'countyTransferTax';

const filterCostsWithoutValueSet = (items: NetSheetsLineItem[]) =>
  items.filter(({minValue, maxValue, isStringValue, stringValue}) => {
    if (isStringValue && stringValue) {
      return true;
    }
    if (!isStringValue && (Boolean(minValue) || Boolean(maxValue))) {
      return true;
    }
    return false;
  });

// extract the last one from the costs, this is saved for the total value that will be used at the breakdown price
export const decorateCalculationResults = (
  result: SellerNetSheetCalculationResponse,
): SellerNetSheetCalculationResponse => {
  const {
    otherCosts = [],
    brokerCommissionCosts = [],
    transferAndPropertyCosts = [],
    settlementCosts = [],
    ...rest
  } = result;
  return {
    otherCosts: removeLastElementFromArray(otherCosts),
    brokerCommissionCosts: removeLastElementFromArray(brokerCommissionCosts),
    settlementCosts: removeLastElementFromArray(settlementCosts),
    transferAndPropertyCosts: removeLastElementFromArray(
      transferAndPropertyCosts,
    ),
    ...rest,
  };
};

// Combine all otherCosts names and return statistical result
const getOtherCostsStatisticalResults = (
  items: NetSheetsLineItem[],
): NetSheetsLineItem[] => {
  if (!items.length) return [];

  const lastElement = items.at(-1);
  const allNames = items
    .slice(0, -1)
    .map(item => item.name)
    .join(', ');
  return [
    {
      ...lastElement,
      name: allNames || lastElement?.name,
    },
  ];
};

// Get only the statistical information (last element) from each array
export const getStatisticalResults = (
  result: SellerNetSheetCalculationResponse,
): SellerNetSheetCalculationResponse => {
  const {
    otherCosts = [],
    brokerCommissionCosts = [],
    transferAndPropertyCosts = [],
    settlementCosts = [],
    ...rest
  } = result;
  return {
    otherCosts: getOtherCostsStatisticalResults(otherCosts),
    brokerCommissionCosts: getLastElementAsArray(brokerCommissionCosts),
    settlementCosts: getLastElementAsArray(settlementCosts),
    transferAndPropertyCosts: getLastElementAsArray(transferAndPropertyCosts),
    ...rest,
  };
};

const removeLastElementFromArray = (items: NetSheetsLineItem[]) =>
  items.slice(0, items.length - 1);

const getLastElementAsArray = (
  items: NetSheetsLineItem[],
): NetSheetsLineItem[] => {
  if (!items.length) {
    return [];
  }
  const totalNetSheet = items[items.length - 1];
  // overwrite the name and sub name for total price rendering
  return [
    {
      ...totalNetSheet,
      name: totalNetSheet.subName,
      subName: '',
    },
  ];
};

const removeCostsWithoutValueSet = ({
  general = [],
  otherCosts = [],
  closingCosts = [],
  ...rest
}: SellerNetSheetCalculationRequest) => {
  return {
    general: filterCostsWithoutValueSet(general),
    otherCosts: filterCostsWithoutValueSet(otherCosts),
    closingCosts: filterCostsWithoutValueSet(closingCosts),
    ...rest,
  };
};

const UNKNOWN_ERROR = {
  response: {
    status: 1000,
    statusText: 'An unknown error occurred during fetch',
  },
};

class FetchError implements Partial<Response> {
  status: number;
  statusText: string;
  constructor(response?: Response) {
    this.status = response?.status || UNKNOWN_ERROR.response.status;
    this.statusText = response?.statusText || UNKNOWN_ERROR.response.statusText;
  }
}
