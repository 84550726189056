import styled from '@emotion/styled';
import groupBy from 'lodash.groupby';
import React, {useState, FC} from 'react';

import {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';
import {AgentInputs} from '@uc/thrift2npme/dist/cma/cma_models';

import {type AllDisplayFieldsType} from '@/types/types';
import {
  statusToDisplayStatus,
  displayStatuses,
} from '@/utils/listingStatusMaps';
import {SectionTitle} from '@/components/pages/Web/components/sectionTitle/sectionTitle';
import {DataGrid} from './DataGrid';
import {FixedColumn} from './FixedColumn';

type Props = {
  className?: string;
  isPreview: boolean;
  subjectProperty: ProcessedListing;
  compListings: ProcessedListing[];
  allDisplayFields: AllDisplayFieldsType;
  agentInputs: AgentInputs | undefined;
  onPropertyModalListingIndexSet: (index: number) => void;
};

export const StatusComparison: FC<Props> = ({
  className,
  isPreview,
  subjectProperty,
  compListings,
  allDisplayFields,
  agentInputs,
  onPropertyModalListingIndexSet,
}) => {
  const listingsByDisplayStatus = groupBy(
    compListings.map((listing, originalIndex) => ({
      originalIndex,
      listing,
    })),
    ({listing: {status}}) =>
      status !== undefined && statusToDisplayStatus[status]?.key,
  );

  const sortedListingsGroups = Object.entries(listingsByDisplayStatus).sort(
    ([a], [b]) => displayStatuses[a]?.sortOrder - displayStatuses[b]?.sortOrder,
  );

  const [expandedGroups, setExpandedGroups] = useState(
    sortedListingsGroups.reduce<Record<string, boolean>>(
      (groups, group) => ({...groups, [group[0]]: true}),
      {},
    ),
  );

  const toggleGroupIsExpanded = (group: string) => {
    const newGroups = {...expandedGroups};
    if (group in expandedGroups) {
      delete newGroups[group];
    } else {
      newGroups[group] = true;
    }
    setExpandedGroups(newGroups);
  };

  return (
    <>
      {!isPreview && (
        <SectionTitle
          title="Status Comparison"
          subtitle={`(${Object.keys(listingsByDisplayStatus).length} statuses)`}
        />
      )}
      <Wrapper className={className} isPreview={isPreview}>
        <FixedColumn
          subjectProperty={subjectProperty}
          compsGroups={sortedListingsGroups}
          expandedGroups={expandedGroups}
          toggleGroupIsExpanded={toggleGroupIsExpanded}
          onColumnRowClick={onPropertyModalListingIndexSet}
        />
        <DataGrid
          agentInputs={agentInputs}
          subjectProperty={subjectProperty}
          compsGroups={sortedListingsGroups}
          expandedGroups={expandedGroups}
          allDisplayFields={allDisplayFields}
          onDataCellClick={onPropertyModalListingIndexSet}
        />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div<{isPreview?: boolean}>`
  display: flex;
  position: relative;
  ${(props: {isPreview?: boolean}) => props.isPreview && 'min-height: 50vh;'};
`;
