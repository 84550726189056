import React from 'react';

export const NetsheetAmount: React.FC<{
  amount?: string[];
  showUniqueValueWithAsterisk?: boolean;
}> = ({amount, showUniqueValueWithAsterisk}) => {
  if (!amount || amount.length === 0 || amount.length > 2) {
    return null;
  }
  if (amount.length === 1) {
    return <span>{amount}</span>;
  }
  const [min, max = ''] = amount;
  // amount2 may have asterisk, so the real amount need to split
  const realMaxAmount = max?.split('*')[0];

  if (showUniqueValueWithAsterisk) {
    return <span>{min === realMaxAmount ? max : `${min} - ${max}`}</span>;
  }

  return <span>{min === max ? min : `${min} - ${max}`}</span>;
};
