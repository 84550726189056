import produce from 'immer';

import {Asset} from '@uc/thrift2npme/dist/asset_library/asset_library_models';
import {
  Cma,
  CompsOrder,
  CustomListingAttribute,
  DisplayListing,
  LotSizeUnit,
  MarketReportSettings,
  PresentationalSectionSettings,
  PriceEstimateFeedback,
  UpgradeItem,
} from '@uc/thrift2npme/dist/cma/cma_models';
import {
  loadListings,
  OptionalField,
  TranslationLoadListingsResponse,
} from '@uc/thrift2npme/dist/listing_translation/listing_translation_service.ucfetch';
import {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';
import {
  SearchQuery as ThriftSearchQuery,
  SORT_ORDER,
} from '@uc/thrift2npme/dist/search/search';
import {QueryParams as ChartQueryParams} from '@uc/thrift2npme/dist/trend_chart/trend_chart_service';
import {
  Adjustment,
  AdjustmentFeedback,
} from '@uc/thrift2npme/dist/valuation/ai_valuation/adjustments';

import {createReducer} from '../createReducer';
import type {ThunkFunction} from '../types/Store';
import {AI_ACTION_TYPES} from './ai';
import {COMPS_ACTION_TYPES} from './comps';
import addCmaNetsheetsReducer from './netsheets/addCmaNetsheetsReducer';
import addNetsheetsToCmaReducer from './netsheets/addNetsheetsToCmaReducer';
import deleteCmaNetsheetsReducer from './netsheets/deleteCmaNetsheetsReducer';
import setCmaNetsheetsCustomClosingFeesReducer from './netsheets/setCmaNetsheetsCustomClosingFeesReducer';
import setCmaNetsheetsInputReducer from './netsheets/setCmaNetsheetsInputReducer';
import setCmaNetsheetsResultsReducer from './netsheets/setCmaNetsheetsResultsReducer';
import setPartnerCmaLeadGenerationReducer from './netsheets/setPartnerLeadGenerationReducer';
import updateCmaNetsheetsReducer from './netsheets/updateCmaNetsheetsReducer';
import updateGenericNetsheetsReducer, {
  updateGenericSalesPriceReducer,
} from './netsheets/updateGenericNetsheetsReducer';
import {PRESENTATIONAL_SETTINGS_ACTION_TYPES} from './presentationalSettings';
import {clearTableEdits, PROPERTY_FIELDS_ACTION_TYPES} from './propertyFields';
import {SEARCH_QUERY_ACTION_TYPES} from './searchQuery';
import {SUBJECT_ASSETS_ACTION_TYPES} from './subjectAssets';
import type {ICma, MediaItem} from './types/Cma';
import PropertyField from './types/PropertyField';
import {updateContactPartnerAgentReducer} from './netsheets/updateContactPartnerAgentReducer';
import {updateShowSettlementCostsFromPartnerReducer} from './netsheets/updateShowSettlementCostsFromPartnerReducer';
import {updatePartnerRelatedCmaNetsheetsReducer} from './netsheets/updatePartnerRelatedCmaNetsheetsReducer';

export const cmaInitalState: ICma = {
  cma: {} as Cma,
  error: null, // Change this to a bool, string or whatever feels most appropriate.
  hasUnsavedChanges: false,
  isSaving: false,
};

export const CMA_ACTION_TYPES = {
  DELETE_FIRST_SUBJECT_PROPERTY_MEDIA_ITEM:
    'DELETE_FIRST_SUBJECT_PROPERTY_MEDIA_ITEM',
  INSERT_SUBJECT_PROPERTY_MEDIA_ITEM: 'INSERT_SUBJECT_PROPERTY_MEDIA_ITEM',
  SET_AGENT_NOTES: 'SET_AGENT_NOTES',
  SET_COMP_IDS: 'SET_COMP_IDS',
  SET_COMPS_SORT_ORDER: 'SET_COMPS_SORT_ORDER',
  SET_CMA_ESTIMATE_PROPERTIES: 'SET_CMA_ESTIMATE_PROPERTIES',
  SET_CMA_ESTIMATE_PROPERTIES_WITHOUT_UPDATE:
    'SET_CMA_ESTIMATE_PROPERTIES_WITHOUT_UPDATE',
  SET_CMA_TITLE: 'SET_CMA_TITLE',
  SET_CMA_UPGRADE_RECOMMENDATION: 'SET_CMA_UPGRADE_RECOMMENDATION',
  SET_DISPLAY_LISTINGS: 'SET_DISPLAY_LISTINGS',
  SET_IS_MANUAL_COMPS_SEARCH: 'SET_IS_MANUAL_COMPS_SEARCH',
  SET_LAST_UPDATE_TIME: 'SET_LAST_UPDATE_TIME',
  SET_SUBJECT_PROPERTY: 'SET_SUBJECT_PROPERTY',
  SET_MARKET_TRENDS_CHART_QUERY: 'SET_MARKET_TRENDS_CHART_QUERY',
  SET_MARKET_REPORT_SETTINGS: 'SET_MARKET_REPORT_SETTINGS',
  ADD_CMA_NETSHEETS: 'ADD_CMA_NETSHEETS',
  ADD_NETSHEETS_TO_CMA: 'ADD_NETSHEETS_TO_CMA',
  DELETE_CMA_NETSHEETS: 'DELETE_CMA_NETSHEETS',
  SET_CMA_NETSHEETS_INPUT: 'SET_CMA_NETSHEETS_INPUT',
  SET_CMA_NETSHEETS_INPUT_WITHOUT_UPDATE:
    'SET_CMA_NETSHEETS_INPUT_WITHOUT_UPDATE',
  SET_CMA_NETSHEETS_RESULTS: 'SET_CMA_NETSHEETS_RESULTS',
  SET_CMA_NETSHEETS_RESULTS_WITHOUT_UPDATE:
    'SET_CMA_NETSHEETS_RESULTS_WITHOUT_UPDATE',
  SET_CMA_LEAD_GENERATION: 'SET_CMA_LEAD_GENERATION',
  SET_CMA_NETSHEETS_CUSTOM_CLOSING_FEES:
    'SET_CMA_NETSHEETS_CUSTOM_CLOSING_FEES',
  UPDATE_CMA_NETSHEETS: 'UPDATE_CMA_NETSHEETS',
  UPDATE_GENERIC_NETSHEETS: 'UPDATE_GENERIC_NETSHEETS',
  UPDATE_GENERIC_SALES_PRICE: 'UPDATE_GENERIC_SALES_PRICE',
  UPDATE_CONTACT_PARTNER_AGENT: 'UPDATE_CONTACT_PARTNER_AGENT',
  UPDATE_SHOW_SETTLEMENT_COSTS_FROM_PARTNER:
    'UPDATE_SHOW_SETTLEMENT_COSTS_FROM_PARTNER',
  UPDATE_SELLER_NET_SHEET_CALCULATION_RESULT:
    'UPDATE_SELLER_NET_SHEET_CALCULATION_RESULT',
  UPDATE_PARTNER_RELATED_CMA_NETSHEETS: 'UPDATE_PARTNER_RELATED_CMA_NETSHEETS',
} as const;

// ACTIONS
export const setCmaTitle = (title: string) => ({
  type: CMA_ACTION_TYPES.SET_CMA_TITLE,
  payload: {title},
});

export const setCmaEstimateProperties = ({
  suggestedPrice,
  remarks,
  feedback,
  update = true,
}: {
  suggestedPrice?: string;
  remarks?: string;
  feedback?: PriceEstimateFeedback;
  update?: boolean;
}) => ({
  type: update
    ? CMA_ACTION_TYPES.SET_CMA_ESTIMATE_PROPERTIES
    : CMA_ACTION_TYPES.SET_CMA_ESTIMATE_PROPERTIES_WITHOUT_UPDATE,
  payload: {
    inputModifiedMs: Number(new Date()),
    suggestedPrice,
    remarks,
    feedback,
  },
});

export const setCmaUpgradeRecommendation = ({
  estimatedTotalCost,
  estimatedTotalImpact,
  upgradeItems,
}: {
  estimatedTotalCost: string;
  estimatedTotalImpact: string;
  upgradeItems: UpgradeItem[];
}) => ({
  type: CMA_ACTION_TYPES.SET_CMA_UPGRADE_RECOMMENDATION,
  payload: {estimatedTotalCost, estimatedTotalImpact, upgradeItems},
});

export const setIsManualCompsSearch = (isManualCompsSearch: boolean) => ({
  type: CMA_ACTION_TYPES.SET_IS_MANUAL_COMPS_SEARCH,
  payload: {isManualCompsSearch},
});

export const setMarketReportSettings = (
  marketReportSettings: MarketReportSettings,
) => ({
  type: CMA_ACTION_TYPES.SET_MARKET_REPORT_SETTINGS,
  payload: {marketReportSettings},
});

export const setSubjectProperty = (
  subjectProperty: ProcessedListing | undefined | null,
  lotSizeUnit: LotSizeUnit | undefined | null,
) => ({
  type: CMA_ACTION_TYPES.SET_SUBJECT_PROPERTY,
  payload: {subjectProperty, lotSizeUnit},
});

export const setAgentNotes = (listingId: string, compsNotes: string) => ({
  type: CMA_ACTION_TYPES.SET_AGENT_NOTES,
  payload: {listingId, compsNotes},
});

export const setCmaLastUpdateTime = (lastUpdateTimeMs: number) => ({
  type: CMA_ACTION_TYPES.SET_LAST_UPDATE_TIME,
  payload: {lastUpdateTimeMs},
});

export const insertCmaSubjectPropertyMediaItem = (mediaItem: MediaItem) => ({
  type: CMA_ACTION_TYPES.INSERT_SUBJECT_PROPERTY_MEDIA_ITEM,
  payload: {mediaItem},
});

export const deleteFirstCmaSubjectPropertyMediaItem = () => ({
  type: CMA_ACTION_TYPES.DELETE_FIRST_SUBJECT_PROPERTY_MEDIA_ITEM,
});

export const setCompsSortOrder = (sortOrder: CompsOrder) => ({
  type: CMA_ACTION_TYPES.SET_COMPS_SORT_ORDER,
  payload: {sortOrder},
});

export const setDisplayListings = (
  editedDisplayListings: {[key: string]: DisplayListing} | undefined,
  origDisplayListings: {[key: string]: DisplayListing} | undefined,
) => ({
  type: CMA_ACTION_TYPES.SET_DISPLAY_LISTINGS,
  payload: {
    editedDisplayListings,
    origDisplayListings,
  },
});

export const setMarketTrendsChartQuery = (chartQuery: ChartQueryParams) => ({
  type: CMA_ACTION_TYPES.SET_MARKET_TRENDS_CHART_QUERY,
  payload: {chartQuery},
});

// REDUCERS
const setCmaTitleReducer = (state: ICma, payload: {title: string}) => {
  return produce(state, draftState => {
    if (draftState.cma.metadata) {
      draftState.cma.metadata.title = payload.title;
    }
  });
};

const setCmaUpgradeRecommendationReducer = (
  state: ICma,
  payload: {
    estimatedTotalCost: string;
    estimatedTotalImpact: string;
    upgradeItems: UpgradeItem[];
  },
) =>
  produce(state, draftState => {
    if (draftState.cma.agentInputs) {
      draftState.cma.agentInputs.upgradeRecommendation = {
        estimatedTotalCost: payload.estimatedTotalCost,
        estimatedTotalImpact: payload.estimatedTotalImpact,
        upgradeItems: payload.upgradeItems,
      };
    }
  });

const setCmaEstimatePropertiesReducer = (
  state: ICma,
  payload: {
    suggestedPrice: string;
    remarks: string;
    inputModifiedMs: number;
    feedback: PriceEstimateFeedback;
  },
) =>
  produce(state, draftState => {
    if (draftState.cma.agentInputs) {
      if (payload.feedback) {
        draftState.cma.agentInputs.priceEstimateFeedback = payload.feedback;
      }

      draftState.cma.agentInputs.remarks = payload.remarks;

      if (state.cma.agentInputs?.price?.value === payload.suggestedPrice) {
        return;
      }

      draftState.cma.agentInputs.price = {
        value: payload.suggestedPrice,
      };

      if (
        state.cma.agentInputs?.netsheets &&
        draftState.cma.agentInputs.netsheets
      ) {
        draftState.cma.agentInputs.netsheets.inputModifiedMs =
          payload.inputModifiedMs;
      }
    }
  });

const deleteFirstSubjectPropertyMediaItemReducer = (state: ICma) =>
  produce(state, draftState => {
    if (draftState.cma?.agentInputs?.subjectProperty?.media) {
      draftState.cma.agentInputs.subjectProperty.media.shift();
    }
  });

const insertSubjectPropertyMediaItemReducer = (
  state: ICma,
  payload: {
    mediaItem: {
      category: number;
      height: number;
      width: number;
      originalUrl: string;
      thumbnailUrl: string;
    };
  },
) =>
  produce(state, draftState => {
    if (draftState.cma?.agentInputs?.subjectProperty?.media) {
      draftState.cma.agentInputs.subjectProperty.media.unshift(
        payload.mediaItem,
      );
    } else if (draftState.cma?.agentInputs?.subjectProperty) {
      draftState.cma.agentInputs.subjectProperty.media = [payload.mediaItem];
    }
  });

const setIsManualCompsSearchReducer = (
  state: ICma,
  payload: {
    isManualCompsSearch: boolean;
  },
) =>
  produce(state, draftState => {
    if (draftState.cma?.agentInputs) {
      draftState.cma.agentInputs.isManualCompsSearch =
        payload.isManualCompsSearch;
    }
  });

const setSubjectPropertyReducer = (
  state: ICma,
  payload: {
    subjectProperty: ProcessedListing;
    lotSizeUnit: LotSizeUnit;
  },
) =>
  produce(state, draftState => {
    if (draftState.cma?.agentInputs) {
      draftState.cma.agentInputs.subjectProperty = payload.subjectProperty;
      if (typeof payload.lotSizeUnit !== 'undefined') {
        draftState.cma.agentInputs.lotSizeUnit = payload.lotSizeUnit;
      }
    }
  });

const setCompIdsReducer = (
  state: ICma,
  payload: {
    compListings: ProcessedListing[];
  },
) =>
  produce(state, draftState => {
    if (draftState.cma?.agentInputs?.compIds) {
      draftState.cma.agentInputs.compIds = (payload.compListings || [])
        .map(listing => listing?.listingIdSHA as string)
        .filter(Boolean);
    }
  });

const setAgentNotesReducer = (
  state: ICma,
  payload: {
    listingId: string;
    compsNotes: string;
  },
) =>
  produce(state, draftState => {
    if (draftState.cma?.agentInputs) {
      if (!draftState.cma?.agentInputs?.compsNotes) {
        draftState.cma.agentInputs.compsNotes = {};
      }
      draftState.cma.agentInputs.compsNotes[payload.listingId] =
        payload.compsNotes;
    }
  });

const setCmaLastUpdateTimeReducer = (
  state: ICma,
  payload: {
    lastUpdateTimeMs: number;
  },
) =>
  produce(state, draftState => {
    if (
      draftState.cma?.metadata &&
      payload.lastUpdateTimeMs > (draftState.cma.metadata.lastUpdateTimeMs || 0)
    ) {
      draftState.cma.metadata.lastUpdateTimeMs = payload.lastUpdateTimeMs;
    }
  });

const setSearchQueryReducer = (
  state: ICma,
  payload: {
    searchQuery: ThriftSearchQuery;
  },
) =>
  produce(state, draftState => {
    if (draftState.cma?.agentInputs) {
      draftState.cma.agentInputs.compSearchQuery = payload.searchQuery;
    }
  });

const setCompsBuilderInputsReducer = (
  state: ICma,
  payload: {
    cqbId: string;
  },
) =>
  produce(state, draftState => {
    draftState.cma.compsBuilderInputs = {
      cqbId: payload.cqbId,
    };
  });

const setSubjectAssetsReducer = (
  state: ICma,
  payload: {
    assets: Asset[];
  },
) =>
  produce(state, draftState => {
    if (draftState.cma?.agentInputs) {
      draftState.cma.agentInputs.assets = payload.assets;
    }
  });

const setSearchQuerySortOrderReducer = (
  state: ICma,
  payload: {
    sortOrder: SORT_ORDER;
  },
) =>
  produce(state, draftState => {
    if (draftState.cma?.agentInputs?.compSearchQuery) {
      draftState.cma.agentInputs.compSearchQuery.sortOrder = payload.sortOrder;
    }
  });

const setCompAdjustmentsReducer = (
  state: ICma,
  payload: {
    appliedAdjustments: {[key: string]: Adjustment[]};
    feedback: {[key: string]: AdjustmentFeedback};
  },
) =>
  produce(state, draftState => {
    if (draftState.cma?.agentInputs) {
      draftState.cma.agentInputs.appliedAdjustments =
        payload.appliedAdjustments;
      draftState.cma.agentInputs.adjustmentFeedback = payload.feedback;
    }
  });

const setPropertyFieldsReducer = (
  state: ICma,
  payload: {
    propertyFields: (PropertyField & CustomListingAttribute)[];
  },
) =>
  produce(state, draftState => {
    if (draftState.cma?.agentInputs) {
      draftState.cma.agentInputs.selectListingAttributes =
        payload.propertyFields;
    }
  });

const setSubjectPhotoDefaultReducer = (state: ICma) =>
  produce(state, draftState => {
    if (draftState.cma?.agentInputs) {
      draftState.cma.agentInputs.assets = [];
    }
  });

const setCompsSortOrderReducer = (
  state: ICma,
  payload: {
    sortOrder: CompsOrder;
  },
) =>
  produce(state, draftState => {
    if (draftState.cma?.agentInputs) {
      draftState.cma.agentInputs.compsOrder = payload.sortOrder;
    }
  });

const setDisplayListingsReducer = (
  state: ICma,
  payload: {
    editedDisplayListings: {[key: string]: DisplayListing} | undefined;
    origDisplayListings: {[key: string]: DisplayListing} | undefined;
  },
) =>
  produce(state, draftState => {
    if (draftState.cma?.agentInputs) {
      draftState.cma.agentInputs.editedDisplayListings =
        payload.editedDisplayListings;
      draftState.cma.agentInputs.origDisplayListings =
        payload.origDisplayListings;
    }
  });

const setPresentationalSettingsReducer = (
  state: ICma,
  payload: {
    visibleSections: PresentationalSectionSettings[];
    hiddenSections: PresentationalSectionSettings[];
  },
) =>
  produce(state, draftState => {
    if (draftState.cma?.agentInputs) {
      draftState.cma.agentInputs.presentationalSettings = {
        visibleSections: payload.visibleSections,
        hiddenSections: payload.hiddenSections,
      };
    }
  });

const setMarketTrendsChartQueryReducer = (
  state: ICma,
  payload: {
    chartQuery: ChartQueryParams;
  },
) =>
  produce(state, draftState => {
    if (draftState.cma?.agentInputs) {
      draftState.cma.agentInputs.chartQuery = payload.chartQuery;
    }
  });

const setMarketReportSettingsReducer = (
  state: ICma,
  payload: {
    marketReportSettings: MarketReportSettings;
  },
) =>
  produce(state, draftState => {
    if (draftState.cma?.agentInputs) {
      draftState.cma.agentInputs.marketReportSettings =
        payload.marketReportSettings;
    }
  });

// THUNKS
export const resetSubjectProperty =
  (): ThunkFunction => async (dispatch, state, getState) => {
    const latestState = getState();
    const subjectId =
      latestState.cma.cma.agentInputs?.subjectProperty?.listingIdSHA;
    let loadListingsResp: null | TranslationLoadListingsResponse = null;

    if (subjectId) {
      loadListingsResp = await loadListings({
        listingIdSHAs: [subjectId],
        optionalFieldsToInclude: [
          OptionalField.CONTACT_INFOS,
          OptionalField.LISTING_DETAILS,
        ],
      });
    }

    if (loadListingsResp?.listings?.length) {
      const lotSizeUnit = latestState.cma.cma.agentInputs?.lotSizeUnit;
      dispatch(setSubjectProperty(loadListingsResp.listings[0], lotSizeUnit));
      dispatch(clearTableEdits(true));
    }
  };

export default createReducer<ICma>({
  [CMA_ACTION_TYPES.DELETE_FIRST_SUBJECT_PROPERTY_MEDIA_ITEM]:
    deleteFirstSubjectPropertyMediaItemReducer,
  [CMA_ACTION_TYPES.INSERT_SUBJECT_PROPERTY_MEDIA_ITEM]:
    insertSubjectPropertyMediaItemReducer,
  [CMA_ACTION_TYPES.SET_AGENT_NOTES]: setAgentNotesReducer,
  [CMA_ACTION_TYPES.SET_IS_MANUAL_COMPS_SEARCH]: setIsManualCompsSearchReducer,
  [CMA_ACTION_TYPES.SET_CMA_ESTIMATE_PROPERTIES]:
    setCmaEstimatePropertiesReducer,
  [CMA_ACTION_TYPES.SET_CMA_ESTIMATE_PROPERTIES_WITHOUT_UPDATE]:
    setCmaEstimatePropertiesReducer,
  [CMA_ACTION_TYPES.SET_CMA_TITLE]: setCmaTitleReducer,
  [CMA_ACTION_TYPES.SET_CMA_UPGRADE_RECOMMENDATION]:
    setCmaUpgradeRecommendationReducer,
  [CMA_ACTION_TYPES.SET_COMPS_SORT_ORDER]: setCompsSortOrderReducer,
  [CMA_ACTION_TYPES.SET_DISPLAY_LISTINGS]: setDisplayListingsReducer,
  [CMA_ACTION_TYPES.SET_LAST_UPDATE_TIME]: setCmaLastUpdateTimeReducer,
  [CMA_ACTION_TYPES.SET_SUBJECT_PROPERTY]: setSubjectPropertyReducer,
  [COMPS_ACTION_TYPES.SET_COMPS]: setCompIdsReducer,
  [CMA_ACTION_TYPES.SET_MARKET_TRENDS_CHART_QUERY]:
    setMarketTrendsChartQueryReducer,
  [AI_ACTION_TYPES.AI_SET_MODAL_RESPONSE]: setCompAdjustmentsReducer,
  [PRESENTATIONAL_SETTINGS_ACTION_TYPES.SET_SECTIONS]:
    setPresentationalSettingsReducer,
  [PROPERTY_FIELDS_ACTION_TYPES.SET_PROPERTY_FIELDS]: setPropertyFieldsReducer,
  [SEARCH_QUERY_ACTION_TYPES.SET_COMPS_BUILDER_INPUTS]:
    setCompsBuilderInputsReducer,
  [SEARCH_QUERY_ACTION_TYPES.SET_SEARCH_QUERY]: setSearchQueryReducer,
  [SEARCH_QUERY_ACTION_TYPES.SET_SEARCH_QUERY_SORT_ORDER]:
    setSearchQuerySortOrderReducer,
  [SUBJECT_ASSETS_ACTION_TYPES.SET_SUBJECT_ASSETS_SUCCESS]:
    setSubjectAssetsReducer,
  [SUBJECT_ASSETS_ACTION_TYPES.SET_SUBJECT_PHOTO_DEFAULT]:
    setSubjectPhotoDefaultReducer,
  [CMA_ACTION_TYPES.SET_MARKET_REPORT_SETTINGS]: setMarketReportSettingsReducer,
  [CMA_ACTION_TYPES.ADD_CMA_NETSHEETS]: addCmaNetsheetsReducer,
  [CMA_ACTION_TYPES.ADD_NETSHEETS_TO_CMA]: addNetsheetsToCmaReducer,
  [CMA_ACTION_TYPES.SET_CMA_NETSHEETS_INPUT]: setCmaNetsheetsInputReducer,
  [CMA_ACTION_TYPES.SET_CMA_NETSHEETS_INPUT_WITHOUT_UPDATE]:
    setCmaNetsheetsInputReducer,
  [CMA_ACTION_TYPES.SET_CMA_NETSHEETS_RESULTS]: setCmaNetsheetsResultsReducer,
  [CMA_ACTION_TYPES.SET_CMA_NETSHEETS_RESULTS_WITHOUT_UPDATE]:
    setCmaNetsheetsResultsReducer,
  [CMA_ACTION_TYPES.DELETE_CMA_NETSHEETS]: deleteCmaNetsheetsReducer,
  [CMA_ACTION_TYPES.SET_CMA_LEAD_GENERATION]:
    setPartnerCmaLeadGenerationReducer,
  [CMA_ACTION_TYPES.SET_CMA_NETSHEETS_CUSTOM_CLOSING_FEES]:
    setCmaNetsheetsCustomClosingFeesReducer,
  [CMA_ACTION_TYPES.UPDATE_CMA_NETSHEETS]: updateCmaNetsheetsReducer,
  [CMA_ACTION_TYPES.UPDATE_GENERIC_NETSHEETS]: updateGenericNetsheetsReducer,
  [CMA_ACTION_TYPES.UPDATE_GENERIC_SALES_PRICE]: updateGenericSalesPriceReducer,
  [CMA_ACTION_TYPES.UPDATE_CONTACT_PARTNER_AGENT]:
    updateContactPartnerAgentReducer,
  [CMA_ACTION_TYPES.UPDATE_SHOW_SETTLEMENT_COSTS_FROM_PARTNER]:
    updateShowSettlementCostsFromPartnerReducer,
  [CMA_ACTION_TYPES.UPDATE_PARTNER_RELATED_CMA_NETSHEETS]:
    updatePartnerRelatedCmaNetsheetsReducer,
});
