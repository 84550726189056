import styled from '@emotion/styled';
import React, {FC} from 'react';
import {
  Area,
  Bar,
  ComposedChart,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import {color, mq} from '@uc/cx-design-tokens';

import {DisplayStatusValue} from '@/types/types';
import {ChartMarker} from '@/components/ChartMarker';
import {ChartTooltip} from '@/components/ChartTooltip';
import {
  AxisKeyType,
  getMinMaxCoordinates,
} from '@/components/pages/PreviewPage/utils/chartUtils/calculateMinMaxCoordinates';
import {calculateMedianIndex} from '@/utils/calculateMedianIndex';
import {formatPriceNumber} from '@/utils/getPriceUtils';

export type ChartDataItem = {
  key?: string;
  x: number;
  y: number;
  width: number;
  height: number;
  radius: number;
  value: number;
  price: number;
  compNum: number;
  status?: Omit<DisplayStatusValue, 'key'> & {key: string};
  address?: string;
  coverPhoto?: string;
  priceText: string[];
};

type Props = {
  showToolTips?: boolean;
  data: ChartDataItem[];
};

export const AverageChart: FC<Props> = ({showToolTips = false, data}) => {
  const medianIndex = calculateMedianIndex(data);
  data[medianIndex].priceText = [
    'Median:',
    formatPriceNumber(data[medianIndex].price),
  ];

  const lowestPrice = data[0].price;
  const highestPrice = data[data.length - 1].price;

  const offset =
    lowestPrice === highestPrice ? 0.25 : (highestPrice - lowestPrice) * 0.013;
  // Sometimes properties can have the same price. Offset the x-axis
  // so that the chart dots don't overlap when the price is the same.
  let hasDuplicates = true;
  let timesOffset = 0;
  while (hasDuplicates) {
    hasDuplicates = false;
    for (let i = 1; i < data.length; i++) {
      if (data[i].x === data[i - 1].x) {
        hasDuplicates = true;
        timesOffset++;
        data[i - 1].x = data[i].x - offset;
      }
    }
  }

  const {xmin, xmax, ymin, ymax} = getMinMaxCoordinates(
    data,
    AxisKeyType.PRICE,
    AxisKeyType.PRICE,
    (timesOffset / 2) * offset,
  );

  // add cushion space to the left and right of the chart
  const cushionedData = [
    {
      x: xmin,
      price: xmin,
    },
    ...data,
    {
      x: xmax,
      price: xmax,
    },
  ];

  return (
    <Container data-tn="averageChart">
      <ResponsiveContainer>
        <ComposedChart data={cushionedData}>
          <Area
            type="linear"
            dataKey="price"
            fill={color.grey50}
            stroke={color.grey50}
          />
          <Bar dataKey="price" data={data} barSize={2} fill={color.white} />
          {data.map(d => (
            <Scatter
              key={d.key}
              name="scatter"
              data={[d]}
              shape={({compNum, priceText, ...props}) => (
                <ChartMarker
                  index={compNum}
                  priceText={priceText}
                  {...props}
                  z={0}
                  key={compNum}
                  textColor={d.status?.textColor}
                />
              )}
              fill={d.status?.color || 'black'}
            />
          ))}
          {showToolTips && <Tooltip content={ChartTooltip} />}
          <XAxis dataKey="x" type="number" hide domain={[xmin, xmax]} />
          <YAxis
            dataKey="price"
            type="number"
            hide
            domain={[ymin * 0.95, ymax * 1.1]}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  bottom: -5px;
  left: -5px;
  width: calc(100% + 10px);
  height: 60%;

  & {
    .recharts-surface {
      overflow: visible;
    }
  }

  ${({theme}) =>
    !theme.printMode &&
    `
      @media ${mq.maxWidthMobileLandscape} {
        height: 80%;
      }
    `}
`;
