import {useCallback, useMemo} from 'react';

import {
  NetSheetsGeneric,
  PriceEstimate,
} from '@uc/thrift2npme/dist/cma/cma_models';
import {SellerNetSheetPartnerConfigResponse} from '@uc/thrift2npme/dist/apex_proxy/apex_proxy_service.ucfetch';
import {parseInputPriceRange} from '@uc/cma-components';
import {
  SellerNetSheetCost,
  SellerNetSheetCostInputComponentType,
} from '@uc/thrift2npme/dist/apex_proxy/apex_proxy_common';

import mergePartnerResultsToGeneric from '@/pages/EstimatePage/netsheets/mergePartnerResultsToGeneric';
import {useStateValue} from '@/context/state';
import {ToolTipsProp} from '@/pages/EstimatePage/SellerNetSheetForPartner/ToolTips';
import {useIsTNEPartnerAvailable} from './useIsTNEPartnerAvailable';
import {convertSellerNetCostsToNetSheetGeneric} from './utils';

// Add props whatever we want for Line Items rendering.
export type NetSheetLinesItemWithOptions = {
  options?: string[];
  componentType?: SellerNetSheetCostInputComponentType;
  toolTips?: ToolTipsProp;
};

// This is used for some special cost line item with options
export type PredefinedCostsLineItemsConfig = {
  [key: string]: NetSheetLinesItemWithOptions;
};

const getToolTips = (id: string) => {
  const YEARLY_TAX_FEE_TYPE = 'yearlyTaxFeeAmount';
  const defaultToolTips = {title: '', body: ''};
  if (id === YEARLY_TAX_FEE_TYPE) {
    return {
      title: 'How is this calculated?',
      body: `If available, this value will be pulled from the property's listing page. Any changes will not update your listing on the MLS or Compass.com.`,
    };
  }
  return defaultToolTips;
};

export const usePartnerResultsToGeneric = (): NetSheetsGeneric => {
  const isTNEPartnerSpecificSNSEnabled = useIsTNEPartnerAvailable();
  const [state] = useStateValue();
  const {
    cma: {
      cma: {agentInputs},
    },
    partnerConfig,
  } = state;
  const {netsheets = {}, price = {}} = agentInputs!;

  return useMemo(() => {
    if (netsheets?.generic) {
      return netsheets.generic;
    }

    if (isTNEPartnerSpecificSNSEnabled) {
      return mergePartnerSpecificCostsToGeneric({
        price,
        partnerConfig,
      });
    }
    return mergePartnerResultsToGeneric(price, netsheets);
  }, [netsheets, isTNEPartnerSpecificSNSEnabled, price, partnerConfig]);
};

const mergePartnerSpecificCostsToGeneric = ({
  price,
  partnerConfig,
}: {
  price?: PriceEstimate;
  partnerConfig?: SellerNetSheetPartnerConfigResponse;
}): NetSheetsGeneric => {
  const {
    closingCosts = [],
    otherCosts = [],
    generalCosts = [],
  } = partnerConfig ?? {};
  const prices = parseInputPriceRange(price?.value);
  const [minPrice, maxPrice] = prices;

  const generalCostsConfig =
    convertSellerNetCostsToNetSheetGeneric(generalCosts);

  const salesPriceConfig = generalCostsConfig.find(
    cost => cost.id === 'salesPrice',
  );
  const mortgage1Config = generalCostsConfig.find(
    cost => cost.id === 'mortgage1',
  );
  const mortgage2Config = generalCostsConfig.find(
    cost => cost.id === 'mortgage2',
  );
  const salesPrice = {
    ...salesPriceConfig,
    minValue: minPrice,
    maxValue: maxPrice,
  };

  return {
    predefinedFees: convertSellerNetCostsToNetSheetGeneric(closingCosts),
    additionalFees: convertSellerNetCostsToNetSheetGeneric(otherCosts),
    salesPrice,
    mortgage: mortgage1Config,
    secondSellerMortgageBalance: mortgage2Config,
  };
};

// use id of the line item to save the config for costs.
export const useConvertCostsToSpecificLineItems = () => {
  return useCallback(
    (closingCosts: SellerNetSheetCost[]) =>
      closingCosts.reduce<PredefinedCostsLineItemsConfig>(
        (config, {id = '', componentType, options}) => {
          config[id] = {
            componentType,
            options,
            toolTips: getToolTips(id),
          };
          return config;
        },
        {},
      ),
    [],
  );
};
