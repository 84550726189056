import React, {useMemo} from 'react';
import styled from '@emotion/styled';

import {font, spacing, color, mq} from '@uc/cx-design-tokens';

import type {SectionProps} from '@/components/pages/PreviewPage/types';
import {NetsheetAmount} from './NetsheetAmount';
import {getAmount, matchMinMax} from '@/utils/netsheet-utils';
import {NetsheetGenericSection} from './NetsheetGenericSection';
import {NetsheetGroup} from '@/components/Netsheet/NetsheetGroup';

const FOOTER_INFO =
  '*Calculations include previously entered commission percentage. The information contained herein is being provided for informative purposes only and cannot be relied upon for accuracy. Property taxes are presumed to be reliable but are not guaranteed. Contact your agent for any questions.';

const Wrapper = styled.div<{isPreview?: boolean}>`
  padding: ${spacing['8x']};
  font-family: ${font.familySans};
  ${(props: {isPreview?: boolean}) => props.isPreview && 'min-height: 50vh;'};
  @media ${mq.maxWidthMobileLandscape} {
    padding: ${spacing['1x']};
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${color.text};
  font-weight: ${font.weightMedium};
  font-size: ${font.sizeHeader4};
  line-height: ${font.lineHeightCompact};
  margin-bottom: 20px;
  img {
    height: 32px;
    width: auto;
  }
`;

const SubTitle = styled.div`
  color: ${color.text};
  font-weight: ${font.weightMedium};
  font-size: ${font.sizeSubheader2};
  line-height: ${font.lineHeightBody};
`;

const Proceeds = styled.div`
  color: ${color.black};
  font-size: ${font.sizeXl};
  font-weight: ${font.weightMedium};
  line-height: ${font.lineHeightCompact};
`;

const TotalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${spacing['2x']} 0;
  border-top: 1px solid ${color.grey110};
  margin-top: ${spacing['4x']};
`;

const Total = styled.div`
  color: ${color.text};
  font-weight: ${font.weightMedium};
  font-size: ${font.sizeSubheader2};
  line-height: ${font.lineHeightBody};
`;

const TotalValue = styled.div`
  font-weight: ${font.weightMedium};
  color: ${color.grey120};
  font-size: ${font.sizeBody1};
  line-height: ${font.lineHeightBody};
`;

const Footer = styled.div`
  font-family: ${font.familyLegible};
  color: ${color.textSubtle};
  font-size: ${font.sizeCaption2};
  line-height: ${font.lineHeightCompact};
  margin-top: ${spacing['3x']};
`;

const SubTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${spacing['1x']} 0;
  border-top: 1px solid ${color.black};
  border-bottom: 1px solid ${color.black};
`;

export type NetItem = {
  title: string;
  minValue?: string;
  maxValue?: string;
  minPercent?: string;
  maxPercent?: string;
  description?: string[];
};

export type GroupProps = {
  header?: string;
  detail: NetItem[];
  includeSubtotal?: boolean;
  subtotalName?: string;
  subTotalDescriptions?: string[];
};

export const NetsheetSection: React.FC<SectionProps> = ({
  cma = {},
  isPreview,
}) => {
  const {agentInputs} = cma;
  const {netsheets} = agentInputs || {};
  const {minResults, maxResults} = netsheets || {};
  const {calculations: minCalc} = minResults || {};
  const {calculations: maxCalc} = maxResults || {};
  const additionalFee = minCalc?.additionalFees || maxCalc?.additionalFees;

  const equityDetail: NetItem[] = useMemo(() => {
    return [
      {
        title: 'Projected Sales Price',
        minValue: minCalc?.equityInfo?.salePrice,
        maxValue: maxCalc?.equityInfo?.salePrice,
      },
      {
        title: 'Current Mortgage Balance',
        minValue: minCalc?.equityInfo?.mortgageBalance,
        maxValue: maxCalc?.equityInfo?.mortgageBalance,
      },
      {
        title: 'Net Equity in Property',
        minValue: minCalc?.equityInfo?.netEquity,
        maxValue: maxCalc?.equityInfo?.netEquity,
        description: ['Projected sale price minus current mortgage balance'],
      },
    ];
  }, [minCalc, maxCalc]);

  // TODO: remove this if the backend api will remove the descriptions.
  // remove commissions description since we calucate them manually
  if (minCalc?.commissions || maxCalc?.commissions) {
    [...(minCalc?.commissions ?? []), ...(maxCalc?.commissions ?? [])].forEach(
      x => {
        x.description = undefined;
      },
    );
  }

  // for commissions, we need calulate the percentage
  // follow: https://compass-tech.atlassian.net/browse/WTL-405
  const costDetail: NetItem[] = useMemo(() => {
    return [
      ...matchMinMax(minCalc?.commissions, maxCalc?.commissions, [
        minCalc?.equityInfo?.salePrice,
        maxCalc?.equityInfo?.salePrice,
      ]),
      ...matchMinMax(minCalc?.fees, maxCalc?.fees),
    ];
  }, [minCalc, maxCalc]);

  const addtionalDetail = useMemo(() => {
    return matchMinMax(minCalc?.additionalFees, maxCalc?.additionalFees);
  }, [minCalc, maxCalc]);

  if (!!netsheets?.generic && !!netsheets.generic.salesPrice) {
    return (
      <NetsheetGenericSection
        generic={netsheets.generic}
        isPreview={isPreview}
      />
    );
  }

  if (!minCalc && !maxCalc) {
    return null;
  }

  return (
    <Wrapper isPreview={isPreview}>
      <Title>{'Seller net proceeds'}</Title>
      <SubTitleWrapper>
        <SubTitle>{'Estimated Net Proceeds'}</SubTitle>
        <Proceeds>
          <NetsheetAmount
            amount={getAmount({
              amount1: minCalc?.totalNetProceeds,
              amount2: maxCalc?.totalNetProceeds,
              asterisk: true,
            })}
          />
        </Proceeds>
      </SubTitleWrapper>
      <div>
        <NetsheetGroup
          header="Seller Price and Loan Information"
          detail={equityDetail}
        />
        <NetsheetGroup
          header="Estimated Closing Costs"
          detail={costDetail}
          includeSubtotal
          subtotalName="Subtotal in Fees, Premiums and Transaction Taxes"
          subTotalDescriptions={[
            'Purchase contract will determine who is paying these costs',
          ]}
        />
        {additionalFee && (
          <NetsheetGroup
            header="Additional Costs"
            detail={addtionalDetail}
            includeSubtotal
            subtotalName="Subtotal for Additional Costs"
          />
        )}
      </div>
      <TotalWrapper>
        <Total>{'Est. net proceeds'}</Total>
        <TotalValue>
          <NetsheetAmount
            amount={getAmount({
              amount1: minCalc?.totalNetProceeds,
              amount2: maxCalc?.totalNetProceeds,
              asterisk: true,
            })}
          />
        </TotalValue>
      </TotalWrapper>
      <Footer>{FOOTER_INFO}</Footer>
    </Wrapper>
  );
};
