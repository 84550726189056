import {useState, useEffect} from 'react';
import {isEqual} from 'lodash';
// "stolen" from apps/pipelines/src/client/hooks/useDebounce.ts

export function useDebounce<T>(value: T, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      if (!isEqual(value, debouncedValue)) {
        setDebouncedValue(value);
      }
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay, debouncedValue]);
  return debouncedValue;
}
