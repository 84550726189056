import styled from '@emotion/styled';
import React from 'react';

import {placeholderCoverSrc} from '@/components/pages/PreviewPage/assets/placeholderCoverImage';

interface Props {
  className?: string;
  listingPhotoUrl?: string;
}

interface StyledProps {
  listingPhotoUrl?: string;
}

const Photo = styled.div<StyledProps>`
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  background-position: 50%;
  ${({listingPhotoUrl}: StyledProps) =>
    listingPhotoUrl ? `background-image: url(${listingPhotoUrl});` : ''};
`;

export const ListingPhoto: React.FC<Props> = ({
  className,
  listingPhotoUrl,
}: Props) => {
  return (
    <Photo
      data-tn="cover-listing-photo"
      className={className}
      listingPhotoUrl={listingPhotoUrl || placeholderCoverSrc}
    />
  );
};
