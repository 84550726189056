import {Cma, CmaOutputSection} from '@uc/thrift2npme/dist/cma/cma_models';

export type AuthorInfo = {
  author: string;
  lastUpdateDate: string;
};

const getSNSAuthorInfo = (cma?: Cma, previewCma?: Cma): AuthorInfo => {
  const {owner: ownerInPreview} = previewCma ?? {};
  const {agentInputs, owner: ownerInCma} = cma ?? {};

  const {netsheets, presentationalSettings} = agentInputs || {};
  const {minResults, maxResults} = netsheets || {};
  const {visibleSections = []} = presentationalSettings || {};

  const getLastUpdateDate = () => {
    const updatedDate =
      minResults?.lastModifiedMs || maxResults?.lastModifiedMs
        ? new Date(
            Math.max(
              minResults?.lastModifiedMs || 0,
              maxResults?.lastModifiedMs || 0,
            ),
          )
        : undefined;
    if (updatedDate) {
      const day = updatedDate.getDate();
      const month = updatedDate.toLocaleString('default', {month: 'long'});
      const year = updatedDate.getFullYear();
      return `${month} ${day}, ${year}`;
    }
    return '';
  };

  const getAuthor = () => {
    if (ownerInCma?.displayName) {
      return ownerInCma?.displayName;
    }
    if (ownerInPreview?.displayName) {
      return ownerInPreview?.displayName;
    }
    const coverSection = visibleSections.find(
      section => section.sectionType === CmaOutputSection.COVER,
    );
    const {agentsInfo} = coverSection?.coverSection || {};
    if (Array.isArray(agentsInfo)) {
      return agentsInfo[0].name;
    }
    return '';
  };

  return {
    author: getAuthor() || '',
    lastUpdateDate: getLastUpdateDate(),
  };
};

export {getSNSAuthorInfo};
