import {NetSheetsLineItem} from '@uc/thrift2npme/dist/cma/cma_models';
import {Partner} from '@uc/thrift2npme/dist/apex_proxy/apex_proxy_common';

import {
  ErrorMessage,
  getMaximumSNSCalculationSupportedPrice,
  RequiredFieldsError,
} from './utils';

export function getError(item?: NetSheetsLineItem) {
  if (!item) return;

  const {minValue = '', maxValue = '', isPercentage, isRange} = item;
  const min = Number(minValue),
    max = Number(maxValue);

  if (isPercentage && (min >= 100 || max >= 100)) {
    return `${ErrorMessage.percentages}`;
  }

  if (isRange && maxValue && min > max) {
    return `${ErrorMessage.min}`;
  }
}

export function getOverAllowedError(
  item?: NetSheetsLineItem,
  partnerId?: Partner,
  email?: string,
) {
  if (!item || !partnerId || !email) return;

  const {minValue = '', maxValue = ''} = item;
  const allowedMaximum = getMaximumSNSCalculationSupportedPrice(partnerId);
  if (allowedMaximum === undefined) return;
  const min = Number(minValue),
    max = Number(maxValue);

  const allowedMinMillions = allowedMaximum * 1000000;
  if (min >= allowedMinMillions || max >= allowedMinMillions) {
    return ErrorMessage.overAllowed(allowedMaximum, email);
  }
}

export function getRequiredError(item?: NetSheetsLineItem) {
  if (!item) return;

  const {minValue, maxValue, isRange, isStringValue, stringValue} = item;
  const min = minValue ? minValue.trim() : minValue,
    max = maxValue ? maxValue.trim() : maxValue;

  const errorMsg = item?.id ? RequiredFieldsError[item?.id] : undefined;

  if (isStringValue) {
    if (!stringValue?.length) {
      return errorMsg;
    }
  } else {
    if (isRange && !(min?.length || max?.length)) {
      return errorMsg;
    }
    if (!isRange && !min?.length) {
      return errorMsg;
    }
  }
  return;
}
