import React from 'react';
import {BrowserRouter} from 'react-router-dom';

import {OptimizelyStateContext} from '@/types/types';
import App from '@/components/pages/Web/components/App';
import paths from '@/paths';
import {StateProvider} from '../context/state';
import {State} from '../context/store';

type Props = {
  initialState: State;
};

const ClientPage = ({initialState}: Props) => (
  <StateProvider initialState={initialState}>
    <BrowserRouter basename={paths.viewWebBase.toPath({})}>
      <OptimizelyStateContext.Provider value={initialState.opty}>
        <App />
      </OptimizelyStateContext.Provider>
    </BrowserRouter>
  </StateProvider>
);
export default ClientPage;
