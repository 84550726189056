import {NetSheetsLineItem} from '@uc/thrift2npme/dist/cma/cma_models';

import {NetSheetsLineItemNumber} from './types';

export function fillValue(
  lineItem?: NetSheetsLineItem,
): NetSheetsLineItemNumber {
  if (lineItem) {
    const {minValue, maxValue} = lineItem;
    const max = maxValue || minValue || 0;
    const min = minValue || maxValue || 0;
    return {...lineItem, minValue: Number(min), maxValue: Number(max)};
  }

  return {
    minValue: 0,
    maxValue: 0,
  };
}

export function isEmptyValue(item: NetSheetsLineItem): boolean {
  const hasMin = Boolean(Number(item.minValue));
  const hasMax = Boolean(Number(item.maxValue));
  const isMissing = !hasMin && !hasMax;
  return isMissing;
}

export function isEmptyValueArray(items: NetSheetsLineItem[]): boolean {
  return !items.some(item => !isEmptyValue(item));
}

// Items that should be preserved even if they have empty values
const PRESERVE_ITEM_IDS = new Set(['estimatedNetProceeds']);

export function filterEmptyItem(
  items: NetSheetsLineItem[],
): NetSheetsLineItem[] {
  return items.filter(item => {
    // Always preserve items in the whitelist
    if (item.id && PRESERVE_ITEM_IDS.has(item.id)) {
      return true;
    }
    // Use existing logic for other items
    return !isEmptyValue(item);
  });
}
