import React from 'react';

import {NoSsr} from '@uc/cx.react';

import {setModalListingIndex} from '@/components/pages/Web/context/modals';
import {useStateValue} from '@/components/pages/Web/context/state';
import {StatusComparison} from '@/components/StatusComparison';

export const ListingPresentationStatusComparison = () => {
  const [
    {subjectProperty, compListings, agentInputs, allDisplayFields},
    dispatch,
  ] = useStateValue();

  const handlePropertyModalListingIndexSet = (index: number) => {
    dispatch(setModalListingIndex(index));
  };

  return (
    <NoSsr>
      <StatusComparison
        subjectProperty={subjectProperty}
        compListings={compListings}
        agentInputs={agentInputs}
        isPreview={false}
        allDisplayFields={allDisplayFields}
        onPropertyModalListingIndexSet={handlePropertyModalListingIndexSet}
      />
    </NoSsr>
  );
};
