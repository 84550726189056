import {
  CmaOutputSection,
  PresentationalSettings,
} from '@uc/thrift2npme/dist/cma/cma_models';

/**
 * Determines if breakdown full price is enabled in the netsheet section
 * @param presentationalSettings - The presentational settings object
 * @returns boolean indicating if breakdown full price is enabled
 */
export const getBreakdownFullPriceValue = (
  presentationalSettings?: PresentationalSettings,
): boolean => {
  if (!presentationalSettings?.visibleSections) {
    return false;
  }

  const visibleSections = presentationalSettings.visibleSections;
  const netSheetSection = visibleSections.find(
    section => section.sectionType === CmaOutputSection.NETSHEETS,
  );

  return Boolean(netSheetSection?.sellerNetSheetSection?.breakdownFullPrice);
};
