import {NetSheetsLineItem} from '@uc/thrift2npme/dist/cma/cma_models';
import {SellerNetSheetCalculationResponse} from '@uc/thrift2npme/dist/apex_proxy/apex_proxy_service.ucfetch';

import {splitGeneralItems} from './getSplitGeneralItems';
import {filterEmptyItem} from './fillValue';

type DataResult = {
  total: NetSheetsLineItem;
  subTotal: NetSheetsLineItem;
  estimatedClosingLayout: LayoutProps;
  salesPriceLayout: LayoutProps;
  otherCostsLayout: LayoutProps;
};

type NetItem = {
  title: string;
  minValue?: string;
  maxValue?: string;
  minPercent?: string;
  maxPercent?: string;
  description?: string[];
};

export type GroupProps = {
  header?: string;
  detail: NetItem[];
  hasFirstTotal?: boolean;
};

export type LayoutProps = {
  title?: string;
  groups?: GroupProps[];
};

export const getSellerNetSheetDataFilter = (
  response: SellerNetSheetCalculationResponse,
): DataResult => {
  const settlementCosts = filterEmptyItem(response.settlementCosts ?? []);
  const transferAndPropertyCosts = filterEmptyItem(
    response.transferAndPropertyCosts ?? [],
  );
  const otherCosts = filterEmptyItem(response.otherCosts ?? []);
  const brokerCommissionCosts = filterEmptyItem(
    response.brokerCommissionCosts ?? [],
  );
  const general = filterEmptyItem(response.general ?? []);

  const {
    estNetProceeds = {},
    subtotalOfClosingCosts = {},
    netEquityInProperty = {},
    generalCosts,
  } = splitGeneralItems(general);

  const closingCosts = [
    {
      subtitle: 'Transfer and property taxes',
      items: transferAndPropertyCosts,
    },
    {subtitle: 'Settlement costs', items: settlementCosts},
    {subtitle: 'Real estate broker commission', items: brokerCommissionCosts},
  ]
    .filter(cost => cost.items.length > 0)
    .map(({subtitle, items}) => createGroupData(subtitle, items));

  // overwrite the name of sales price manually for UI rendering
  const salesPriceAndLoanInfo = [
    ...generalCosts.map(cost =>
      cost.id === 'salesPrice'
        ? {
            ...cost,
            name: 'Projected sales price',
            subName: '',
          }
        : cost,
    ),
    netEquityInProperty,
  ];

  const salesPriceLayout = {
    title: 'Sales price and loan information',
    groups: convertNetSheetsItemsToGroups(salesPriceAndLoanInfo),
  };
  const estimatedClosingLayout =
    closingCosts.length > 0
      ? {
          title: 'Estimated closing costs',
          groups: closingCosts,
        }
      : {};

  const otherCostsLayout =
    otherCosts.length > 0
      ? {
          title: 'Other fees',
          groups: convertNetSheetsItemsToGroups(otherCosts),
        }
      : {};

  const total = {
    minValue: estNetProceeds?.minValue,
    maxValue: estNetProceeds?.maxValue,
  };

  const subTotal = {
    minValue: subtotalOfClosingCosts?.minValue,
    maxValue: subtotalOfClosingCosts?.maxValue,
  };

  return {
    total,
    subTotal,
    estimatedClosingLayout,
    salesPriceLayout,
    otherCostsLayout,
  };
};

const createGroupData = (
  subtitle: string,
  items: NetSheetsLineItem[],
): GroupProps => mapItemsToGroup({items, header: subtitle});

export const convertNetSheetsItemsToGroups = (
  netSheetsItems: NetSheetsLineItem[],
): GroupProps[] =>
  netSheetsItems.map(item =>
    mapItemsToGroup({
      items: [item],
      hasFirstTotalCondition: currentItem =>
        currentItem.id === 'netEquityInProperty',
    }),
  );

export const mapItemsToGroup = ({
  items,
  header,
  hasFirstTotalCondition,
}: {
  items: NetSheetsLineItem[];
  header?: string;
  hasFirstTotalCondition?: (item: NetSheetsLineItem) => boolean;
}): GroupProps => ({
  header,
  detail: items?.map(({name, subName, minValue, maxValue}) => ({
    title: name || '',
    description: subName ? [subName] : [],
    minValue,
    maxValue,
  })),
  hasFirstTotal: hasFirstTotalCondition
    ? items.some(hasFirstTotalCondition)
    : false,
});
