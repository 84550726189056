import styled from '@emotion/styled';
import React from 'react';

import {font} from '@uc/cx-design-tokens';

import {NetsheetAmount} from '../NetsheetAmount';

const Wrapper = styled.div<{
  hasFirstTotal?: boolean;
}>`
  font-family: var(--cx-font-familySans);
  padding-top: var(--cx-spacing-1x);
  padding-bottom: var(--cx-spacing-1x);
  font-size: var(--cx-font-sizeCaption1);
  line-height: var(--cx-font-lineHeightCompact);
  padding-left: var(--cx-spacing-3x);
  &:last-child {
    ${props =>
      props.hasFirstTotal
        ? `&::before {
            content: ' ';
            height: 1px;
            position: absolute;
            left: var(--cx-spacing-3x);
            right: 0px;
            top:-1px;
            background-color: var(--cx-color-grey50);
          }`
        : ''};
    font-weight: ${props =>
      props.hasFirstTotal
        ? 'var(--cx-font-weightMedium)'
        : 'var(--cx-font-weightRegular)'};
    position: relative;
    padding-left: ${props =>
      props.hasFirstTotal ? '0px' : 'var(--cx-spacing-3x)'};
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  color: var(--cx-color-text);
`;
Title.displayName = 'Title';

const Value = styled.div`
  color: var(--cx-color-text);
`;
Value.displayName = 'Value';

const Description = styled.div`
  font-size: ${font.sizeCaption2};
  color: var(--cx-color-grey80);
`;
Description.displayName = 'Description';

export type Props = {
  title?: string;
  value?: string[];
  description?: string[];
  hasFirstTotal?: boolean;
};

export const DetailedFeeLineItem: React.FC<Props> = ({
  title,
  value,
  description,
  hasFirstTotal,
}: Props) => {
  return (
    <Wrapper hasFirstTotal={hasFirstTotal}>
      <Row>
        <Title>{title}</Title>
        <Value data-tn="value">
          <NetsheetAmount amount={value} />
        </Value>
      </Row>
      {!!description?.length &&
        description.map(d => <Description key={d}>{d}</Description>)}
    </Wrapper>
  );
};
