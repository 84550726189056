import React from 'react';
import styled from '@emotion/styled';

import {font, spacing, color} from '@uc/cx-design-tokens';
import {PartnerMetadata} from '@uc/thrift2npme/dist/apex_proxy/apex_proxy_partners';

import {useIsTNEPartnerAvailable} from '@/hooks/useIsTNEPartnerAvailable';
import {useStateValue} from '@/context/state';
import {GeneratedByPartner} from '@/pages/EstimatePage/SellerNetSheetForPartner/GeneratedByPartner';
import {getLegalDisclaimer} from '@/utils/netsheets/getLegalDisclaimer';

export const FOOTER_INFO =
  '*Calculations include previously entered commission percentage. The information contained herein is being provided for informative purposes only and cannot be relied upon for accuracy. Property taxes are presumed to be reliable but are not guaranteed. Contact your agent for any questions.';

const Footer = styled.div`
  font-family: ${font.familyLegible};
  color: ${color.textSubtle};
  font-size: ${font.sizeCaption2};
  line-height: ${font.lineHeightCompact};
  margin-top: ${spacing['3x']};
`;

const FooterRoot = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${spacing['3x']};
`;

const PartnerFooterText = styled.div`
  font-family: ${font.familyLegible};
  color: ${color.textSubtle};
  font-size: ${font.sizeCaption2};
  line-height: ${font.lineHeightCompact};
  flex: 1;
`;

export const NetsheetFooter: React.FC = () => {
  const isTNEPartnerAvailable = useIsTNEPartnerAvailable();
  const [state] = useStateValue();
  const {partnerConfig, cma: iCma} = state ?? {};
  const {cma} = iCma ?? {};
  const {partnerMetadata = {}} = partnerConfig ?? {};
  const isShowGeneratedBy =
    !!cma?.agentInputs?.netsheets?.input?.showSettlementCostsFromPartner;

  return isTNEPartnerAvailable ? (
    <PartnerFooter
      partnerMetadata={partnerMetadata}
      isShowGeneratedBy={isShowGeneratedBy}
    />
  ) : (
    <Footer>{FOOTER_INFO}</Footer>
  );
};

export const PartnerFooter: React.FC<{
  partnerMetadata: PartnerMetadata;
  isShowGeneratedBy?: boolean;
}> = ({partnerMetadata, isShowGeneratedBy}) => {
  const legalDisclaimer = getLegalDisclaimer({
    partnerName: partnerMetadata?.displayName,
    isShowSettlementCostsFromPartner: !!isShowGeneratedBy,
  });
  return (
    <FooterRoot data-tn="netsheet-partner-footer">
      <PartnerFooterText>{legalDisclaimer}</PartnerFooterText>
      {isShowGeneratedBy && (
        <GeneratedByPartner
          labelColor={color.text}
          partnerMetadata={partnerMetadata}
        />
      )}
    </FooterRoot>
  );
};
