import {Partner} from '@uc/thrift2npme/dist/apex_proxy/apex_proxy_common';

/**
 * return the max millions a partner allowed
 * @param partnerId Partner enum
 * @returns 5 or undefined
 */
export const getMaximumSNSCalculationSupportedPrice = (partnerId: Partner) => {
  switch (partnerId) {
    case Partner.AKT:
    case Partner.CHARTWELL:
    case Partner.LTT:
    case Partner.FAT:
      return 5;
    default:
      return undefined;
  }
};

/**
 * Settlement date is required for all partners regardless of the "costs from partner" toggle.
 * The only exception is Chartwell with the "costs from partner" turned off where settlement date is not required.
 * @param partnerId
 * @returns
 */
export const getSettlementDateIsRequiredFromPartner = (
  partnerId?: Partner,
  showSettlementCostsFromPartner?: boolean,
) => {
  if (partnerId === Partner.CHARTWELL && showSettlementCostsFromPartner) {
    return false;
  }
  return true;
};
