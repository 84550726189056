import React, {FC, useState, useMemo} from 'react';
import styled from '@emotion/styled';

import {mq, font} from '@uc/cx-design-tokens';
import type {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';
import type {AgentInputs} from '@uc/thrift2npme/dist/cma/cma_models';

import {statusOrder, statusToDisplayStatus} from '@/utils/listingStatusMaps';
import {
  PropertyGeneralInfo,
  GeneralInfoLabel,
} from '@/components/PropertyGeneralInfo';
import {
  calculateAverage,
  groupByStatus,
  getAdjustedPrice,
} from '@/utils/compListings';
import {formatPriceNumber} from '@/utils/getPriceUtils';
import {StatusFilter} from './StatusFilter';
import {AverageChart, type ChartDataItem} from './AverageChart';
import {getDisplayImageFromThumbnailUrl} from '@/utils/display-helpers';

const defaultSelectedStatus = ['Active', 'Pending', 'Sold', 'Other'];

type Props = {
  compListings: ProcessedListing[];
  agentInputs: AgentInputs;
  isForWebView?: boolean;
  showStatusFilter?: boolean;
  showPriceTitle?: boolean;
  className?: string;
  printMode?: boolean;
};

export const CompListingsAverage: FC<Props> = ({
  className,
  compListings,
  agentInputs,
  isForWebView = false,
  showStatusFilter = false,
  showPriceTitle = false,
  printMode = false,
}) => {
  const {appliedAdjustments = {}} = agentInputs;
  const [statusSelected, setStatusSelected] = useState(defaultSelectedStatus);

  const compListingsWithNum = useMemo(() => {
    return compListings.map<ProcessedListing & {_compNum: number}>(
      (listing, index) => ({
        ...listing,
        _compNum: index + 1,
      }),
    );
  }, [compListings]);

  const statusToListingsMap = useMemo(
    () => groupByStatus(compListingsWithNum),
    [compListingsWithNum],
  );

  const statusOptions = useMemo(() => {
    return statusOrder
      .filter(status => !!statusToListingsMap[status]?.length)
      .map(status => ({
        label: status,
        value: statusToListingsMap[status].length,
      }));
  }, [statusToListingsMap]);

  const selectedCompListings = useMemo(() => {
    return compListingsWithNum.filter(listing =>
      statusSelected.includes(
        statusToDisplayStatus[listing.status as number]?.label,
      ),
    );
  }, [compListingsWithNum, statusSelected]);

  const averageListing = calculateAverage({
    compListings: selectedCompListings,
    agentInputs,
  });

  const chartData = selectedCompListings
    .map<ChartDataItem>(listing => {
      const price = getAdjustedPrice({listing, appliedAdjustments});
      return {
        key: listing.listingIdSHA,
        x: price,
        y: 0,
        width: 0,
        height: 0,
        radius: 0,
        value: 0,
        price,
        compNum: listing._compNum,
        status: statusToDisplayStatus[listing.status as number],
        address: listing.location?.prettyAddress ?? '-',
        coverPhoto:
          listing.media?.[0]?.originalUrl ||
          getDisplayImageFromThumbnailUrl(listing.media?.[0]?.thumbnailUrl),
        priceText: [],
      };
    })
    .filter(({price}) => !!price)
    .sort((a, b) => a.price - b.price);

  const totalPrice = chartData.reduce((prev, curr) => prev + curr.price, 0);
  const lowestPrice = chartData[0]?.price ?? 0;
  const highestPrice = chartData[chartData.length - 1]?.price ?? 0;
  const averagePrice = totalPrice / selectedCompListings.length;
  const priceStats = [
    {label: 'Lowest', value: lowestPrice},
    {
      label: 'Average',
      value: averagePrice,
    },
    {label: 'Highest', value: highestPrice},
  ];

  return (
    <div className={className} data-tn="compListingsAverage">
      <StyledPropertyGeneralInfo
        agentInputs={agentInputs}
        showLotSizeSuffix={true}
        listing={averageListing}
        showPrice={true}
        shouldCenter={false}
        isForWebView={isForWebView}
        shouldFormatIntoString={true}
        printMode={printMode}
      />
      {showStatusFilter && (
        <StatusFilter
          options={statusOptions}
          value={statusSelected}
          onChange={val => setStatusSelected(val)}
        />
      )}
      {showPriceTitle && <PricesTitle>Prices</PricesTitle>}
      <AverageChartContainer>
        {chartData.length > 0 && (
          <AverageChart data={chartData} showToolTips={isForWebView} />
        )}
      </AverageChartContainer>
      <PriceStatsContainer>
        {priceStats.map(item => (
          <PriceContainer key={item.label}>
            <ListingAveragesInfoLabel>{item.label}</ListingAveragesInfoLabel>
            <ListingAveragesInfoValue>
              {selectedCompListings.length > 0
                ? formatPriceNumber(item.value)
                : '$-'}
            </ListingAveragesInfoValue>
          </PriceContainer>
        ))}
      </PriceStatsContainer>
    </div>
  );
};

const AverageChartContainer = styled.div`
  position: relative;
  margin-top: var(--cx-spacing-2x);
  height: 400px;
  background-color: var(--cx-color-grey20);

  ${({theme}) =>
    !theme.printMode &&
    `
      @media ${mq.maxWidthMobileLandscape} {
        height: 60vh;
      }
    `}
`;

const PricesTitle = styled.div`
  color: var(--cx-color-text);
  font-size: var(--cx-font-sizeHeader4);
  line-height: var(--cx-font-sizeHeader4);
  font-weight: var(--cx-font-weightMedium);
  margin-top: var(--cx-spacing-4x);

  ${({theme}) =>
    !theme.printMode &&
    `
      @media ${mq.maxWidthMobileLandscape} {
        font-size: var(--cx-font-sizeSubheader2);
      }
    `}
`;

const PriceStatsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: var(--cx-spacing-2x) var(--cx-spacing-3x);
  font-family: var(--cx-font-familyLegible);
  text-align: center;

  ${({theme}) =>
    !theme.printMode &&
    `
      @media ${mq.maxWidthMobileLandscape} {
        font-size: var(--cx-font-sizeBody1);
        padding: var(--cx-spacing-2x) 0;
      }
    `}
`;

const ListingAveragesInfoValue = styled.div`
  font-size: var(--cx-font-sizeBody1);
  font-weight: var(--cx-font-weightRegular);
  font-family: var(--cx-font-familySans);
  line-height: var(--cx-font-sizeHeader4);
  padding-top: var(--cx-spacing-1x);

  ${({theme}) =>
    !theme.printMode &&
    `
      @media ${mq.maxWidthMobileLandscape} {
        font-size: var(--cx-font-sizeBody2);
      }
    `}
`;

const PriceContainer = styled.div`
  text-align: left;
`;

const ListingAveragesInfoLabel = styled.div`
  font: var(--cx-font-shorthandXsStrong);

  ${({theme}) =>
    !theme.printMode &&
    `
      @media ${mq.maxWidthMobileLandscape} {
        font-size: 'var(--cx-font-sizeBody2)';
      }
    `}
`;

const StyledPropertyGeneralInfo = styled(PropertyGeneralInfo)`
  ${GeneralInfoLabel} {
    font-size: ${font.sizeCaption1};
  }
`;
