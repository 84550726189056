import React, {FC, lazy, Suspense, RefObject} from 'react';

import type {Media} from '@uc/thrift2npme/dist/translation_common/media';
import {NoSsr} from '@uc/cx.react';
import {CmaOutputSection} from '@uc/thrift2npme/dist/cma/cma_models';

import {listingDescriptionField} from '@/utils/propertyFields';
import {
  setModalListingIndex,
  setPhotoViewerModalPhotoIndex,
  setPhotoViewerModalPhotos,
  setPhotoViewerModalListing,
} from '@/components/pages/Web/context/modals';
import {useStateValue} from '@/components/pages/Web/context/state';
import {SideBySideComparison} from '@/components/SideBySideComparison';
import type {PhotoImageClickType} from '@/types/types';

const PropertyModal = lazy(() => import('@/components/PropertyModal'));
const PhotoViewerModal = lazy(() => import('@/components/PhotoViewerModal'));

export const ListingPresentationSideBySideComparison: FC<{
  sectionRef?: RefObject<HTMLDivElement>;
  viewPageRef?: RefObject<HTMLDivElement>;
}> = ({sectionRef, viewPageRef}) => {
  const [
    {
      subjectProperty,
      compListings,
      agentInputs,
      cma,
      modalListingIndex,
      photoViewerModalPhotos,
      photoViewerModalListing,
      photoViewerModalPhotoIndex,
      allDisplayFields,
    },
    dispatch,
  ] = useStateValue();

  const visibleSections =
    agentInputs?.presentationalSettings?.visibleSections ?? [];
  const comparablePropertiesSectionDetails =
    visibleSections.find(
      visibleSection =>
        visibleSection.sectionType === CmaOutputSection.SIDE_BY_SIDE_COMPARISON,
    )?.comparablePropertiesSection || {};
  const hiddenSideBySideListingAttributes =
    comparablePropertiesSectionDetails.hiddenSideBySideListingAttributes || [];

  const handleListingPhotoClick = ({
    isSubject,
    index,
  }: {
    isSubject: boolean;
    index?: number;
  }) => {
    dispatch(
      setModalListingIndex(isSubject || index == undefined ? 0 : index + 1),
    );
  };

  const onPhotoImageClick: PhotoImageClickType = ({
    listing,
    media,
    mediaIndex,
  }) => {
    dispatch(setPhotoViewerModalListing(listing));
    dispatch(setPhotoViewerModalPhotos(media));
    dispatch(setPhotoViewerModalPhotoIndex(mediaIndex));
  };

  const onPropertyModalIndexUpdate = (index: number | null) => {
    dispatch(setModalListingIndex(index));
  };

  const onPhotoViewerModalPhotoIndexChange = (index: number | null) => {
    dispatch(setPhotoViewerModalPhotoIndex(index));
  };

  const onPhotoViewerModalPhotosSet = (newMedias: Media[] | null) => {
    dispatch(setPhotoViewerModalPhotos(newMedias));
  };

  const showListingDescription = !hiddenSideBySideListingAttributes?.includes(
    listingDescriptionField.processedListingPath,
  );

  return (
    <NoSsr>
      <SideBySideComparison
        cmaId={cma.cmaId || ''}
        subjectProperty={subjectProperty}
        compListings={compListings}
        agentInputs={agentInputs}
        isPreview={false}
        allDisplayFields={allDisplayFields}
        sectionRef={sectionRef}
        viewPageRef={viewPageRef}
        onPhotoImageClick={onPhotoImageClick}
        onListingPhotoClick={handleListingPhotoClick}
      />
      <Suspense fallback={null}>
        <PropertyModal
          isPreview={false}
          allDisplayFields={allDisplayFields}
          modalListingIndex={modalListingIndex}
          compListings={compListings}
          subjectProperty={subjectProperty}
          agentInputs={agentInputs || {}}
          onPropertyModalIndexUpdate={onPropertyModalIndexUpdate}
          onPhotoImageClick={onPhotoImageClick}
          showListingDescription={showListingDescription}
        />
        <PhotoViewerModal
          onPhotoViewerModalPhotoIndexChange={
            onPhotoViewerModalPhotoIndexChange
          }
          onPhotoViewerModalPhotosSet={onPhotoViewerModalPhotosSet}
          photoViewerModalPhotos={photoViewerModalPhotos}
          photoViewerModalPhotoIndex={photoViewerModalPhotoIndex}
          photoViewerModalListing={photoViewerModalListing}
        />
      </Suspense>
    </NoSsr>
  );
};
