import {cloneDeep} from 'lodash';

import {
  NetSheetsGeneric,
  NetSheetsLineItem,
} from '@uc/thrift2npme/dist/cma/cma_models';

import {CMA_ACTION_TYPES} from '../cma';
import ActionWithPayload from '../types/ActionWithPayload';

export const updateGenericSalesPrice = (salesPrice: NetSheetsLineItem) => ({
  type: CMA_ACTION_TYPES.UPDATE_GENERIC_SALES_PRICE,
  payload: {
    ...salesPrice,
  },
});

/**
 * initialize the netsheet
 * @param {NetSheetsGeneric} generic the generic in netsheets
 * @returns {ActionWithPayload<NetSheets>} the action
 */
const updateGenericNetsheets = (
  generic: NetSheetsGeneric,
): ActionWithPayload<NetSheetsGeneric> => ({
  type: CMA_ACTION_TYPES.UPDATE_GENERIC_NETSHEETS,
  payload: {
    ...cloneDeep(generic),
  },
});

export default updateGenericNetsheets;
