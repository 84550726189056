import {useMemo, VFC} from 'react';
import React from 'react';

import {CmaOutputSection} from '@uc/thrift2npme/dist/cma/cma_models';

import {
  decorateCalculationResults,
  getStatisticalResults,
  useSellerNetSheetCalculation,
} from '@/hooks/useSellerNetSheetCalculation';
import {NetsheetProps} from '@/types/netsheet';
import {getSellerNetSheetDataFilter} from '@/utils/netsheets/getSellerNetSheetDataFilter';
import {NetsheetGenericPage} from './NetsheetGenericPage';
import {useStateValue} from '@/context/state';
import {getSNSAuthorInfo} from '@/utils/netsheets/getSNSAuthorInfo';

export const PreviewNetSheetSection: VFC<NetsheetProps> = ({isPreview}) => {
  const {data = {}} = useSellerNetSheetCalculation();
  const [state] = useStateValue();

  const {author, lastUpdateDate} = getSNSAuthorInfo(
    state?.cma?.cma,
    state?.previewSettings?.previewCma,
  );
  const {
    previewSettings: {
      presentationalSettings: {visibleSections},
    },
  } = state;
  const netSheetSection = useMemo(
    () =>
      visibleSections?.find(
        section => section.sectionType === CmaOutputSection.NETSHEETS,
      ),
    [visibleSections],
  );

  const isDisplayPriceBreakdown = useMemo(
    () => netSheetSection?.sellerNetSheetSection?.breakdownFullPrice ?? false,
    [netSheetSection],
  );

  const calculationResult = useMemo(
    () =>
      isDisplayPriceBreakdown
        ? decorateCalculationResults(data)
        : getStatisticalResults(data),
    [
      data,
      isDisplayPriceBreakdown,
      decorateCalculationResults,
      getStatisticalResults,
    ],
  );

  const {
    total,
    subTotal,
    estimatedClosingLayout,
    salesPriceLayout,
    otherCostsLayout,
  } = useMemo(
    () => getSellerNetSheetDataFilter(calculationResult),
    [calculationResult, getSellerNetSheetDataFilter],
  );

  const layouts = useMemo(
    () => [salesPriceLayout, estimatedClosingLayout, otherCostsLayout],
    [salesPriceLayout, estimatedClosingLayout, otherCostsLayout],
  );

  if (!salesPriceLayout?.groups?.length) {
    return null;
  }

  return (
    <NetsheetGenericPage
      key="PreviewNetSheetSection"
      total={total}
      subTotal={subTotal}
      isPreview={isPreview}
      author={author}
      date={lastUpdateDate}
      layouts={layouts}
    />
  );
};
