import styled from '@emotion/styled';
import React, {createRef, useEffect, useState, FC} from 'react';

import {color, font, mq, spacing} from '@uc/cx-design-tokens';
import {Icon, Button} from '@uc/cx.react';
import {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';
import {AgentInputs} from '@uc/thrift2npme/dist/cma/cma_models';

import {getAdjustedPrice} from '@/utils/compListings';
import {listingDataFields} from '@/components/pages/Web/helpers/listingDataFields';
import {AllDisplayFieldsType, CompsGroupsType} from '@/types/types';
import {
  ROW_HEADER_HEIGHT_PX,
  ROW_HEIGHT_PX,
  ROW_MOBILE_HEIGHT_PX,
} from './constants';

const SCROLL_ARROW_OFFSET = 148; // PHOTO_ADDRESS_HEIGHT + adjustmentsBlockHeight + 90;

const COMP_COLUMN_WIDTH = 250;

type Props = {
  agentInputs?: AgentInputs;
  compsGroups: CompsGroupsType;
  expandedGroups: Record<string, boolean>;
  subjectProperty: ProcessedListing;
  allDisplayFields: AllDisplayFieldsType;
  onDataCellClick: (modalListingIndex: number) => void;
};

export const DataGrid: FC<Props> = ({
  agentInputs,
  compsGroups,
  expandedGroups,
  subjectProperty,
  allDisplayFields,
  onDataCellClick,
}) => {
  const appliedAdjustments = agentInputs?.appliedAdjustments || {};
  const dataGridRef = createRef<HTMLDivElement>();
  const [canScrollRight, setCanScrollRight] = useState(true);
  const [canScrollLeft, setCanScrollLeft] = useState(false);

  const scrollCompListingContainer = (val: number) => {
    const scrollPosition = dataGridRef.current?.scrollLeft || 0;
    const containerWidth = dataGridRef.current?.offsetWidth || 0;
    const contentWidth = dataGridRef.current?.scrollWidth || 0;
    if (val > 0 && contentWidth - scrollPosition - containerWidth < val) {
      val = contentWidth - scrollPosition - containerWidth;
    } else if (val < 0 && scrollPosition + val < 0) {
      val = 0 - scrollPosition;
    }
    dataGridRef.current?.scroll({
      left: scrollPosition + val,
      behavior: 'smooth',
    });
  };

  const onScrollCheckToHideArrow = () => {
    const scrollPosition = Math.round(dataGridRef.current?.scrollLeft || 0);
    const containerWidth = dataGridRef.current?.offsetWidth || 0;
    const contentWidth = dataGridRef.current?.scrollWidth || 0;

    if (scrollPosition === 0) {
      setCanScrollLeft(false);
    } else {
      setCanScrollLeft(true);
    }
    // there is a pixel difference, allowed not to exceed 1px.
    if (
      Math.round(Math.abs(contentWidth - scrollPosition - containerWidth)) <= 1
    ) {
      setCanScrollRight(false);
    } else {
      setCanScrollRight(true);
    }
  };

  // hide arrow is we don't need to scroll
  useEffect(() => {
    const containerWidth = dataGridRef.current?.offsetWidth || 0;
    const contentWidth = dataGridRef.current?.scrollWidth || 0;
    if (contentWidth <= containerWidth) {
      setCanScrollRight(false);
    }
  }, []);

  return (
    <Wrapper ref={dataGridRef} onScroll={() => onScrollCheckToHideArrow()}>
      {listingDataFields({
        selectListingAttributes: agentInputs?.selectListingAttributes || [],
        allFields: allDisplayFields,
        listing: subjectProperty,
      })
        .filter(field => field.name !== 'Status')
        .map(field => (
          <Column key={field.name}>
            <HeaderCell border="bottom" title={field.name}>
              <CellContent>{field.name}</CellContent>
            </HeaderCell>
            <DataCell
              onClick={() => {
                onDataCellClick(0);
              }}
              title={field.getValue(subjectProperty)}
              hasBorder={false}
            >
              <CellContent>{field.getValue(subjectProperty)}</CellContent>
            </DataCell>
            {compsGroups.map(([displayStatus, listings]) => (
              <React.Fragment key={`${field.name}-${displayStatus}`}>
                <HeaderCell border="top" />
                <ListingsWrapper
                  data-e2e-listings-group={displayStatus}
                  listingsCount={listings.length}
                  isExpanded={displayStatus in expandedGroups}
                >
                  {listings.map((item, idx) => {
                    const {listing, originalIndex} = item;
                    const adjustedPrice = getAdjustedPrice({
                      listing,
                      appliedAdjustments,
                    });
                    const lotSizeUnit = agentInputs?.lotSizeUnit;
                    const valueDisplay = field.getValue(listing, {
                      adjustedPrice,
                      lotSizeUnit,
                    });
                    return (
                      <DataCell
                        key={`${field.name}-${listing.listingIdSHA}`}
                        hasBorder={idx > 0}
                        onClick={() => {
                          onDataCellClick(originalIndex + 1);
                        }}
                        title={valueDisplay}
                      >
                        <CellContent>{valueDisplay}</CellContent>
                      </DataCell>
                    );
                  })}
                </ListingsWrapper>
              </React.Fragment>
            ))}
          </Column>
        ))}
      <LeftSlideButton
        variant="naked"
        shouldShow={canScrollLeft}
        topOffset={SCROLL_ARROW_OFFSET}
        onClick={() => scrollCompListingContainer(-COMP_COLUMN_WIDTH)}
        aria-label="slide to left button"
      >
        <IconChevronLeft width="12" height="12" />
      </LeftSlideButton>
      <RightSlideButton
        variant="naked"
        shouldShow={canScrollRight}
        topOffset={SCROLL_ARROW_OFFSET}
        onClick={() => scrollCompListingContainer(COMP_COLUMN_WIDTH)}
        aria-label="slide to right button"
      >
        <IconChevronRight width="12" height="12" />
      </RightSlideButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  flex-direction: row;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${color.grey80};
    box-shadow: 0 0 1px ${color.grey10};
  }
`;
// For a more complete display of data columns, we increase the value of 'max-width' to 200
// and display ellipses for content larger than 200px in width (e.g. text columns).
const Column = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 200px;
`;

const ListingsWrapper = styled.div<{
  listingsCount: number;
  isExpanded: boolean;
}>`
  width: 100%;
  transition: height 0.5s ease-in-out;
  overflow: hidden;
  display: inline-block;
  height: ${({isExpanded, listingsCount}) =>
    isExpanded ? listingsCount * ROW_HEIGHT_PX : 0}px;

  @media ${mq.maxWidthMobileLandscape} {
    height: ${({isExpanded, listingsCount}) =>
      isExpanded ? listingsCount * ROW_MOBILE_HEIGHT_PX : 0}px;
  }
`;

const CellContent = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DataCell = styled.div<{hasBorder: boolean}>`
  width: 100%;
  height: ${ROW_HEIGHT_PX}px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  padding: 0 ${spacing['2x']};
  border-top: ${({hasBorder}) => hasBorder && `1px solid ${color.grey40}`};
  cursor: pointer;
  font-size: ${font.sizeBody2};

  @media ${mq.maxWidthMobileLandscape} {
    font-size: ${font.sizeCaption1};
    height: ${ROW_MOBILE_HEIGHT_PX}px;
  }
`;

const HeaderCell = styled.div<{border: string}>`
  font: ${font.shorthandBodyStrong};
  border-${({border}) => border}: 1px solid ${color.grey40};
  text-align: center;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${ROW_HEADER_HEIGHT_PX}px;
  width: 100%;
  white-space: nowrap;
  padding: 0 ${spacing['1x']};
  font-size: ${font.sizeBody2};
  line-height: ${font.sizeBody2};

  @media ${mq.maxWidthMobileLandscape} {
    font-size: ${font.sizeCaption1};
  }
`;
//
const SlideButton = styled(Button)<{shouldShow: boolean; topOffset: number}>`
  &.cx-react-button {
    position: absolute;
  }
  width: 35px;
  height: 35px;
  top: ${({topOffset}) => topOffset}px;
  border-radius: 50%;
  padding-top: 5px;
  padding-left: 7px;
  background-color: ${color.black};
  cursor: pointer;
  ${({shouldShow}) => `display: ${shouldShow ? 'block' : 'none'};`}

  &:hover {
    background-color: ${color.black};
  }

  @media ${mq.maxWidthMobileLandscape} {
    display: none;
  }
`;

const LeftSlideButton = styled(SlideButton)`
  left: 360px;
`;

const RightSlideButton = styled(SlideButton)`
  right: 10px;
`;

const NavIconStyles = `
transform: scale(1.3);
position: absolute;
top: calc(50% - 6px);
left: calc(50% - 6px);
fill: ${color.white};
`;

const IconChevronLeft = styled(Icon.ChevronLeft)`
  ${NavIconStyles}
`;

const IconChevronRight = styled(Icon.ChevronRight)`
  ${NavIconStyles}
`;
