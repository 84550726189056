import {parseInputPriceRange} from '@uc/cma-components';
import {
  LineItem,
  NetSheetsInputs,
} from '@uc/thrift2npme/dist/apex/apex_service.ucfetch';
import {
  NetSheets,
  NetSheetsCalculations,
  NetSheetsGeneric,
  NetSheetsLineItem,
  PriceEstimate,
} from '@uc/thrift2npme/dist/cma/cma_models';

import {GENERIC_DEFAULT_FEES} from '@/constants';

export const convertMinMaxToRanges = (
  min: LineItem[] = [],
  max: LineItem[] = [],
): NetSheetsLineItem[] => {
  const initMapping: Record<string, LineItem> = {};
  const maxNameMapping = max.reduce(
    (obj, item) => (item.name ? {...obj, [item.name]: item} : obj),
    initMapping,
  );

  return min.map(mi => ({
    name: mi.name,
    minValue: mi.amount,
    maxValue: mi.name ? maxNameMapping[mi.name].amount : '',
  }));
};

export const mergeToAdditionalFees = (
  minResults: NetSheetsCalculations,
  maxResults: NetSheetsCalculations,
) => {
  const minFees = [
    ...(minResults?.calculations?.fees ?? []),
    ...(minResults?.calculations?.additionalFees ?? []),
  ];
  const maxFees = [
    ...(maxResults?.calculations?.fees ?? []),
    ...(maxResults?.calculations?.additionalFees ?? []),
  ];
  const additionalFees = convertMinMaxToRanges(minFees, maxFees);
  return additionalFees.map(fee => {
    fee.isRange = Number(fee.minValue) !== Number(fee.maxValue);
    return fee;
  });
};

export const mergeToPredefinedFees = (input?: NetSheetsInputs) => {
  const buyerCommission = input?.agentCommissions?.[0]?.commissions?.[0].value;
  const sellerCommission = input?.agentCommissions?.[1]?.commissions?.[0].value;

  return GENERIC_DEFAULT_FEES.predefinedFees?.map(fee => {
    if (fee.name === 'Buyer agent commission') {
      fee.minValue = buyerCommission;
      fee.maxValue = buyerCommission;
      fee.isPercentage = true;
    }
    if (fee.name === 'Seller agent commission') {
      fee.minValue = sellerCommission;
      fee.maxValue = sellerCommission;
      fee.isPercentage = true;
    }
    return fee;
  });
};

export const mergeToMortgage = (
  minResults: NetSheetsCalculations,
  maxResults: NetSheetsCalculations,
) => {
  return {
    ...GENERIC_DEFAULT_FEES.mortgage,
    minValue: minResults?.calculations?.equityInfo?.mortgageBalance ?? '',
    maxValue: maxResults?.calculations?.equityInfo?.mortgageBalance ?? '',
  };
};

const mergePartnerResultsToGeneric = (
  price?: PriceEstimate,
  netsheets: NetSheets = {},
): NetSheetsGeneric => {
  const prices = parseInputPriceRange(price?.value);
  const [minPrice, maxPrice] = prices;
  const {minResults, maxResults, input} = netsheets;

  const minPriceInResults = minResults?.calculations?.equityInfo?.salePrice;
  const maxPriceInResults = maxResults?.calculations?.equityInfo?.salePrice;

  // If the value of `price` is changed before opening the generic netsheets, we do not merge.
  if (
    minPriceInResults &&
    maxPriceInResults &&
    Number(minPrice) === Number(minPriceInResults) &&
    Number(maxPrice) === Number(maxPriceInResults)
  ) {
    const initFees: NetSheetsGeneric = {};

    initFees.additionalFees = mergeToAdditionalFees(minResults, maxResults);
    initFees.predefinedFees = mergeToPredefinedFees(input);
    initFees.mortgage = mergeToMortgage(minResults, maxResults);
    initFees.salesPrice = {
      ...GENERIC_DEFAULT_FEES.salesPrice,
      minValue: minPrice,
      maxValue: maxPrice,
    };

    return initFees;
  }

  // init the salesPrice
  const salesPrice = {
    name: 'Sales price',
    isRange: true,
    minValue: minPrice,
    maxValue: maxPrice,
    isPercentage: false,
  };
  return {...GENERIC_DEFAULT_FEES, salesPrice};
};

export default mergePartnerResultsToGeneric;
