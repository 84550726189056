import styled from '@emotion/styled';
import {useIntersection} from '@shopify/react-intersection-observer';
import cloneDeep from 'lodash.clonedeep';
import debounce from 'lodash.debounce';
import React, {Ref, useEffect, useRef, useState, type RefObject} from 'react';
import {useParams} from 'react-router-dom';

import {CmaOutputSection} from '@uc/thrift2npme/dist/cma/cma_models';
import {color, mq, spacing} from '@uc/cx-design-tokens';

import type {SectionConfig} from '@/types/types';
import {LoadingIndicator} from '@/pages/Web/components/loadingIndicator/loadingIndicator';
import {NavBar} from '@/pages/Web/components/navBar/navBar';
import {PageHeader} from '@/pages/Web/components/pageHeader/pageHeader';
import * as charts from '@/pages/Web/components/sections/chart/charts';
import {ListingAveragesSection} from '@/pages/Web/components/sections/listingAverages/listingAverages';
import {MapSection} from '@/pages/Web/components/sections/map/map';
import {ListingPresentationMarketReport} from '@/pages/Web/components/sections/marketReport/ListingPresentationMarketReport';
import {ListingPresentationPricingAnalysis} from '@/pages/Web/components/sections/pricingAnalysis/ListingPresentationPricingAnalysis';
import {ListingPresentationSideBySideComparison} from '@/pages/Web/components/sections/sideBySideComparison/ListingPresentationSideBySideComparison';
import {ListingPresentationStatusComparison} from '@/pages/Web/components/sections/statusComparison/ListingPresentationStatusComparison';
import {SubjectSection} from '@/pages/Web/components/sections/subject/subject';
import {UpgradeSuggestionSection} from '@/pages/Web/components/sections/upgradeSuggestion/upgradeSuggestion';
import {CoverSection} from '@/components/CoverSection';
import {CustomPdfSection} from '@/components/CustomPdfSection';
import {ThankYouSection} from '@/components/ThankYouSection';
import {NetsheetSection} from '@/components/Netsheet';
import {setError} from '../../context/ducks/data-fetching-states';
import {setNavExpanded} from '../../context/navigation';
import {useStateValue} from '../../context/state';
import {Provider as ScrollElementProvider} from '../../contexts/scroll-element';
import {trackCmaViewed} from '@/utils/analyticTracks';
import {ListingPresentationNetSheetSection as NetSheetSectionForPartner} from '@/components/Netsheet/NetSheetsGenericForPartner/ListingPresentationNetSheetSection';
const getActiveSectionIndex = (
  sectionsConfig: SectionConfig[],
  isReachedBottom: boolean,
): number => {
  if (isReachedBottom) {
    return sectionsConfig.length - 1;
  }
  const firstIntersectingIndex = sectionsConfig.findIndex(
    section => section.intersection?.[0]?.isIntersecting,
  );

  if (firstIntersectingIndex === -1) {
    // no section has 40% of its content visible. find section with the most content visible
    const firstVisibleSectionIndex = sectionsConfig.findIndex(
      section => (section.intersection?.[0]?.boundingClientRect?.y ?? 0) > 0,
    );
    if (firstVisibleSectionIndex === -1) {
      return 0;
    }
    return firstVisibleSectionIndex;
  }
  return firstIntersectingIndex;
};

export const ViewPage = () => {
  const styledViewPageRef = useRef<HTMLDivElement>(null);
  const [
    {isNavExpanded, compListings, agentInputs, loading, error, cma},
    dispatch,
  ] = useStateValue();
  const [isReachedBottom, setIsReachedBottom] = useState(false);

  const {cmaId, agentId, version} = useParams<{
    cmaId: string;
    agentId: string;
    version: string;
  }>();

  const [isMobile, setIsMobile] = useState(
    typeof window !== 'undefined' ? window.innerWidth < mq.tablet : false,
  );

  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      setIsMobile(
        typeof window !== 'undefined' ? window.innerWidth < mq.tablet : false,
      );
    }, 1000);

    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  });

  useEffect(() => {
    trackCmaViewed(cmaId);
  }, []);

  // Since all sections are using independent useIntersection.
  // So some sections are inside the viewport, then their isIntersecting is true at the same time.
  // That's why when scrolling to the bottom, two active nav bars will appear at the same time.
  // Add this hooks to determine if the screen has scrolled to the bottom, use it to determine if it is the last section
  useEffect(() => {
    const viewPageContainer = styledViewPageRef.current;
    if (!viewPageContainer) {
      return;
    }
    const lastSectionBuffer = 32;
    const debouncedHandleScroll = debounce(
      () =>
        setIsReachedBottom(
          viewPageContainer.scrollHeight -
            viewPageContainer.scrollTop -
            viewPageContainer.clientHeight <
            lastSectionBuffer,
        ),
      200,
    );
    viewPageContainer.addEventListener('scroll', debouncedHandleScroll);

    return () => {
      viewPageContainer.removeEventListener('scroll', debouncedHandleScroll);
    };
  }, []);

  const intersectionThreshold = 0.4;
  const sectionsMapping: {
    [key in CmaOutputSection]?: SectionConfig;
  } = {
    [CmaOutputSection.MARKET_TREND_REPORT]: {
      intersection: useIntersection(),
      props: {
        key: 'MARKET_TREND_REPORT',
        name: 'Market Report',
        SectionComponent: ListingPresentationMarketReport,
      },
    },
    [CmaOutputSection.COVER]: {
      intersection: useIntersection({threshold: intersectionThreshold}),
      props: {
        key: 'cover',
        name: 'Title Page',
        collapseBorder: true,
        SectionComponent: CoverSection,
      },
    },
    [CmaOutputSection.SUBJECT_DETAILS]: {
      intersection: useIntersection({threshold: intersectionThreshold}),
      props: {
        key: 'subject',
        name: 'Your Property',
        SectionComponent: SubjectSection,
      },
    },
    [CmaOutputSection.MAP]: {
      intersection: useIntersection({threshold: intersectionThreshold}),
      props: {
        key: 'map',
        name: 'Map',
        collapseBorder: true,
        SectionComponent: MapSection,
      },
    },
    [CmaOutputSection.SIDE_BY_SIDE_COMPARISON]: {
      intersection: useIntersection({threshold: intersectionThreshold}),
      props: {
        key: 'sideBySide',
        name: 'Side-by-Side Comparison',
        SectionComponent: ListingPresentationSideBySideComparison,
        margin: '72px',
      },
    },
    [CmaOutputSection.STATUS_COMPARISON]: {
      intersection: useIntersection({threshold: intersectionThreshold}),
      props: {
        key: 'statusComparison',
        name: 'Status Comparison',
        SectionComponent: ListingPresentationStatusComparison,
      },
    },
    [CmaOutputSection.CHARTS_PRICE_VS_SQFT]: {
      intersection: useIntersection({threshold: intersectionThreshold}),
      props: {
        key: 'chartPriceVsSqFt',
        name: 'Price vs SqFt',
        SectionComponent: charts.PriceVsSqFt,
      },
    },
    [CmaOutputSection.CHARTS_PRICE_VS_DOM]: {
      intersection: useIntersection({threshold: intersectionThreshold}),
      props: {
        key: 'chartPriceVsDOM',
        name: 'Price vs Days',
        SectionComponent: charts.PriceVsDom,
      },
    },
    [CmaOutputSection.CHARTS_LISTING_AVERAGES]: {
      intersection: useIntersection({threshold: intersectionThreshold}),
      props: {
        key: 'listingAverages',
        name: 'Listing Averages',
        SectionComponent: ListingAveragesSection,
      },
    },
    [CmaOutputSection.ESTIMATE]: {
      intersection: useIntersection({threshold: intersectionThreshold}),
      props: {
        key: 'pricingAnalysis',
        name: 'Pricing Analysis',
        SectionComponent: ListingPresentationPricingAnalysis,
      },
    },
    [CmaOutputSection.CONTACT]: {
      intersection: useIntersection({threshold: intersectionThreshold}),
      props: {
        key: 'contactInfo',
        name: 'Thank You',
        SectionComponent: ThankYouSection,
      },
    },
  };

  if (agentInputs?.upgradeRecommendation?.upgradeItems?.length) {
    const upgradeSection = {
      // TODO: https://compass-tech.atlassian.net/browse/ACT-12360
      // eslint-disable-next-line react-hooks/rules-of-hooks
      intersection: useIntersection({threshold: intersectionThreshold}),
      props: {
        key: 'upgradeSuggestion',
        name: 'Upgrade Suggestion',
        SectionComponent: UpgradeSuggestionSection,
      },
    };
    sectionsMapping[CmaOutputSection.UPGRADE_SUGGESTIONS] = upgradeSection;
  }

  if (agentInputs?.netsheets?.addedToCma) {
    const {sellerNetSheetBECalculations} = agentInputs?.netsheets ?? {};
    const netsheetSection = {
      // TODO: https://compass-tech.atlassian.net/browse/ACT-12360
      // eslint-disable-next-line react-hooks/rules-of-hooks
      intersection: useIntersection({threshold: intersectionThreshold}),
      props: {
        key: 'netsheet',
        name: sellerNetSheetBECalculations
          ? 'Seller net sheet'
          : 'Sell net proceeds',
        SectionComponent: sellerNetSheetBECalculations
          ? NetSheetSectionForPartner
          : NetsheetSection,
      },
    };
    sectionsMapping[CmaOutputSection.NETSHEETS] = netsheetSection;
  }

  let sectionsConfig: SectionConfig[] = [];
  if (agentInputs?.presentationalSettings) {
    const pdfSection = {
      // Multiple PDF's need to perform multiple useIntersection
      intersection: undefined,
      props: {
        key: 'customPdf',
        SectionComponent: CustomPdfSection,
      },
    };
    sectionsMapping[CmaOutputSection.CUSTOM_PDF] = pdfSection;
    const visibleSections =
      agentInputs?.presentationalSettings?.visibleSections?.filter(x => {
        // for netsheet, we still need check the opty and addedToCma flag
        if (x.sectionType === CmaOutputSection.NETSHEETS) {
          if (agentInputs?.netsheets?.addedToCma) {
            return x.sectionType;
          } else {
            return false;
          }
        }
        if (x.sectionType === CmaOutputSection.CHARTS_PRICE_VS_DOM) {
          for (let i = 0; i < compListings.length; i++) {
            if (
              compListings[i]?.date?.cumulativeDaysOnMarket ||
              compListings[i]?.date?.daysOnMarket
            ) {
              return x.sectionType;
            }
          }

          return false;
        }
        return x.sectionType;
      }) ?? [];
    sectionsConfig = visibleSections.map((section, idx) => {
      // Multiple PDF's need to perform multiple useIntersection
      const intersection = !(
        typeof section.sectionType !== 'undefined' &&
        sectionsMapping[section.sectionType]?.intersection
      )
        ? // TODO: https://compass-tech.atlassian.net/browse/ACT-12360
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useIntersection({threshold: intersectionThreshold})
        : sectionsMapping[section.sectionType]?.intersection;

      const configSection: SectionConfig = {
        intersection,
        props: (typeof section.sectionType !== 'undefined' &&
          cloneDeep(sectionsMapping[section.sectionType]?.props)) || {key: ''},
      };

      if (section.sectionType === CmaOutputSection.CUSTOM_PDF) {
        configSection.props.name = section.customPdfSection?.id;
        configSection.props.sectionIndex = idx;
        configSection.props.key = configSection.props.key + idx;
      } else if (
        section.sectionType === CmaOutputSection.COVER ||
        section.sectionType === CmaOutputSection.CONTACT
      ) {
        configSection.props.sectionIndex = idx;
      }
      return configSection;
    });
  } else {
    // UI should not show MARKET_TREND_REPORT when presentationalSettings is undefined
    delete sectionsMapping[CmaOutputSection.MARKET_TREND_REPORT];
    sectionsConfig = Object.values(sectionsMapping);
  }

  const activeSectionIndex = getActiveSectionIndex(
    sectionsConfig,
    isReachedBottom,
  );

  if (!cmaId || (!agentId && !version)) {
    dispatch(setError(true));
  }

  if (error) {
    return (
      <div data-e2e-error-message>
        There was an error loading your listing presentation. Please try again
        later.
      </div>
    );
  }

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      {/* TODO: REF SHOULD BE IN CONTAINER ON FULL SCREEN, AND ON STYLEDVIEWPAGE ON MOBILE */}
      <Container data-tn="view-page" ref={isMobile ? null : styledViewPageRef}>
        <ScrollElementProvider value={styledViewPageRef.current}>
          <PageHeader activeSectionIndex={activeSectionIndex} />
          <StyledViewPage
            data-e2e-scroll-container
            ref={isMobile ? styledViewPageRef : null}
          >
            <SectionsWrapper
              data-tn={`sections-wrapper-${isNavExpanded ? 'expanded' : 'collapsed'}`}
              isNavExpanded={isNavExpanded}
              onClick={() => {
                if (isNavExpanded) {
                  dispatch(setNavExpanded(false));
                }
              }}
            >
              {sectionsConfig.map((section, index) => {
                const {
                  intersection: [, ref] = [],
                  props: {
                    key,
                    collapseBorder,
                    margin,
                    SectionComponent,
                    sectionIndex,
                  },
                } = section;
                return SectionComponent ? (
                  <ListingPresentationSection
                    key={key}
                    data-tn={`${key}-section`}
                    ref={ref as Ref<HTMLDivElement>}
                    collapseBorder={!!collapseBorder}
                    margin={margin}
                  >
                    <SectionComponent
                      cma={cma}
                      isPreview={false}
                      sectionIndex={sectionIndex}
                      isIntersecting={index === activeSectionIndex}
                      viewPageRef={styledViewPageRef}
                      sectionRef={ref as RefObject<HTMLElement>}
                    />
                  </ListingPresentationSection>
                ) : null;
              })}
            </SectionsWrapper>
          </StyledViewPage>
        </ScrollElementProvider>
      </Container>
      <NavBar
        viewPageRef={styledViewPageRef}
        sectionsConfig={sectionsConfig}
        activeSectionIndex={activeSectionIndex}
      />
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: scroll;
`;

const StyledViewPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 67px;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  background-color: ${color.grey20};

  @media ${mq.maxWidthTabletLandscape} {
    border-left: none;
    border-right: none;
  }
  @media ${mq.maxWidthMobileLandscape} {
    top: 48px;
    overflow-x: hidden;
  }
`;

const SectionsWrapper = styled.div<{isNavExpanded: boolean}>`
  background-color: ${color.grey20};
  max-width: 1279px;
  width: 100%;

  @media ${mq.maxWidthMobileLandscape} {
    max-width: 100%;
    padding-right: 0;
  }

  &:after {
    opacity: 0;
    transition: opacity 0.4s;
    content: '\A';

    ${({isNavExpanded}) =>
      isNavExpanded &&
      `
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.9);
      opacity: 0.7;
      z-index: 5000;
    `}
  }
`;

const ListingPresentationSection = styled.div<{
  collapseBorder: boolean;
  margin?: string;
}>`
  scroll-snap-align: start;
  scroll-snap-stop: always;
  width: 100%;
  border: 1px solid ${color.grey40};
  background-color: ${color.background};

  margin-bottom: ${({collapseBorder, margin}) =>
    collapseBorder ? '-1px' : margin || spacing['4x']};
`;
