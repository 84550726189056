import {format} from 'date-fns';
import get from 'lodash.get';

import {formatPropertyType} from '@uc/format';
import {formatPrice} from '@uc/format';
import type {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';
import type {
  AgentInputs,
  CustomListingAttribute,
  LotSizeUnit,
} from '@uc/thrift2npme/dist/cma/cma_models';

import {getBathrooms} from '@/utils/getBathrooms';
import {getBedrooms} from '@/utils/getBedrooms';
import {getListingKeyDetail} from './getListingKeyDetail';
import {getPricePerSqft} from '@/utils/getPriceUtils';
import {getSquareFeet} from '@/utils/getSquareFeet';
import {AllDisplayFieldsType} from '@/types/types';

type ListDataFieldsProps = {
  name: string;
  getValue: (
    listing?: ProcessedListing,
    options?: {adjustedPrice?: number; lotSizeUnit?: LotSizeUnit, agentInputs?: AgentInputs},
  ) => string;
};

const fieldNameMap: Record<string, string> = {
  Bedrooms: 'Beds',
  'Square Feet': 'Sq Ft',
};

/* eslint-disable-next-line valid-jsdoc */
/**
 * selectListingAttributes - an array of user-selected fields that have a processedListingPath
 * and a field name mainly
 * i.e. [{displayFieldName: 'Beds', processedListingPath: 'size.bedrooms'}]
 *
 * allFields - a hashMap of processedListingPaths to their corresponding cma adapter field obj
 * for all fields
 * i.e. {
 *  'size.bedrooms': {
 *    displayFieldName: 'Beds',
 *    valueToDisplay: (listing) => { return listing.size.beds },
 *  },
 *  ...
 * }
 */
export const listingDataFields = ({
  selectListingAttributes = [],
  allFields = {},
  listing = {},
  opts,
}: {
  selectListingAttributes: CustomListingAttribute[];
  allFields: AllDisplayFieldsType;
  listing: ProcessedListing;
  opts?: {doNotUseDefaults: boolean};
}) => {
  if (selectListingAttributes.length > 0) {
    return selectListingAttributes.map<ListDataFieldsProps>(attr => {
      const attrPath = attr.processedListingPath;
      const fieldBlock = attrPath ? allFields[attrPath] : undefined;

      const fieldName =
        fieldBlock?.displayFieldName ?? attr.displayFieldName ?? '';
      const name =
        typeof fieldName === 'function' ? fieldName(listing) : fieldName;

      const getValue: ListDataFieldsProps['getValue'] = (
        listing = {},
        options = {},
      ): string => {
        const {adjustedPrice, lotSizeUnit, agentInputs} = options;
        const {displayFieldName, valueToDisplay, processedListingPath} =
          fieldBlock || {};

        if (displayFieldName === 'PPSF') {
          return valueToDisplay?.(listing, adjustedPrice)?.toString() ?? '-';
        } else if (displayFieldName === 'Lot Size') {
          return valueToDisplay?.(listing, lotSizeUnit)?.toString() ?? '-';
        }

        if (processedListingPath?.startsWith('detailedInfo.keyDetails')) {
          return (valueToDisplay?.(
            listing,
            agentInputs?.editedDisplayListings?.[
              listing.listingIdSHA as string
            ],
          ) ?? '-') as string;
        }

        const returnValue = valueToDisplay?.(listing)?.toString() ?? '-';
        return formatSQFTField(name, returnValue);
      };

      return {
        name: fieldNameMap[name] ?? name,
        getValue,
      };
    });
  }
  return opts?.doNotUseDefaults ? [] : defaultListingDataFields;
};

const formatSQFTField = (fieldName: string | undefined, value: string) => {
  if (fieldName?.endsWith('SQFT') && value) {
    value = value.replace(/\.00$/, '');
  }
  return value;
};

const defaultListingDataFields: ListDataFieldsProps[] = [
  {
    name: 'Beds',
    getValue: listing => getBedrooms(listing || {}),
  },
  {
    name: 'Baths',
    getValue: listing => getBathrooms(listing || {}),
  },
  {
    name: 'Sq Ft',
    getValue: listing => getSquareFeet(listing || {}),
  },
  {
    name: '$/Sq Ft',
    getValue: getPricePerSqft,
  },
  {
    name: 'Lot Size',
    getValue: listing => {
      const path = 'size.lotSizeInSquareFeet';
      const value = get(listing, path, '-');
      if (value === '-') {
        return value;
      }
      return `${value.toLocaleString()} Sq Ft`;
    },
  },
  {
    name: 'Property Type',
    getValue: listing => formatPropertyType(listing || {}) || '-',
  },
  {
    name: 'Taxes',
    getValue: listing => {
      return getListingKeyDetail(listing || {}, 'Taxes');
    },
  },
  {
    name: 'Listed Date',
    getValue: listing => {
      return getDateField(listing || {}, 'date.listed');
    },
  },
  {
    name: 'Days on Market',
    getValue: listing => {
      return getDaysOnMarket(listing || {}, 'date.daysOnMarket');
    },
  },
  {
    name: 'HOA Fees',
    getValue: listing => {
      return getListingKeyDetail(listing || {}, 'HOA Fees');
    },
  },
  {
    name: 'Condo Coop Fees',
    getValue: listing => {
      return getListingKeyDetail(listing || {}, 'Condo/Co-op Fees');
    },
  },
];

const getDaysOnMarket = (listing: ProcessedListing, path: string) => {
  const value = get(listing, path, '-');

  return `${value} Days`;
};

const getDateField = (listing: ProcessedListing, path: string) => {
  const value = get(listing, path, '-');

  if (value === '-') {
    return '-';
  }

  return format(new Date(value), 'LLL dd, yyyy').toUpperCase();
};

export const getValueForPropertyDetailCmaLp = (
  path: string,
  listing: ProcessedListing,
) => {
  if (path.includes('price')) {
    return formatPrice(get(listing, path, '-'));
  } else if (path === 'date.daysOnMarket') {
    return getDaysOnMarket(listing, path);
  }
  return getDateField(listing, path);
};
