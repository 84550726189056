import styled from '@emotion/styled';
import React, {FC} from 'react';

import {color, font, mq, spacing} from '@uc/cx-design-tokens';
import {Icon, Poptip, usePoptipOnHover, Button} from '@uc/cx.react';
import {getListingPrice} from '@uc/format';
import type {AgentInputs} from '@uc/thrift2npme/dist/cma/cma_models';
import {Adjustment} from '@uc/thrift2npme/dist/valuation/ai_valuation/adjustments';
import {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';

import {listingDescriptionField} from '@/utils/propertyFields';
import {
  getDisplayImageFromThumbnailUrl,
  getAdjustedPrice,
} from '@/utils/display-helpers';
import {listingDataFields} from '@/pages/Web/helpers/listingDataFields';
import {getMedia} from '@/utils/getMedia';
import {
  COMP_COLUMN_WIDTH,
  COMP_COLUMN_WIDTH_MOBILE,
  TOGGLE_ADJUSTMENTS_HEIGHT,
  VIEW_CONFIG,
  ViewKeys,
} from './constants';
import {
  type AllDisplayFieldsType,
  type PhotoImageClickType,
  type ListingPhotoClickType,
} from '@/types/types';
import {PriceBlock} from './PriceBlock';
import {AdjustedPriceBlock} from './AdjustedPriceBlock';
import {ListingPhotoWithPropertyStatus} from './ListingPhotoWithPropertyStatus';
import {PropertyTitleBlock} from './PropertyTitleBlock';

const ADJUSTMENTS_VALUE_WIDTH = 80;
const ADJUSTMENTS_EXPLANATION_WIDTH = 170;

type Props = {
  allDisplayFields: AllDisplayFieldsType;
  adjustmentsBlockHeight?: number;
  adjustmentsExist?: boolean;
  agentInputs?: AgentInputs;
  appliedAdjustments?: Record<string, Adjustment[]>;
  columnView?: string;
  hiddenSideBySideListingAttributes?: string[];
  hideAgentNotes?: boolean;
  isAdjustmentsBlockExpanded?: boolean;
  listing: ProcessedListing;
  isSubject: boolean;
  index?: number;
  setTooltipState?: (
    state: {
      index: number;
      text: string;
      boundingRect: DOMRect;
    } | null,
  ) => void;
  toggleAdjustmentsBlock?: () => void;
  onPhotoImageClick: PhotoImageClickType;
  onListingPhotoClick: ListingPhotoClickType;
};

export const PropertyColumn: FC<Props> = ({
  allDisplayFields,
  adjustmentsBlockHeight,
  adjustmentsExist,
  agentInputs = {},
  appliedAdjustments = {},
  columnView,
  index,
  isAdjustmentsBlockExpanded = false,
  isSubject = false,
  listing,
  toggleAdjustmentsBlock,
  setTooltipState,
  hiddenSideBySideListingAttributes,
  hideAgentNotes,
  onPhotoImageClick,
  onListingPhotoClick,
}) => {
  const {poptipProps, getPoptipAnchorProps} = usePoptipOnHover();
  const ColumnType = isSubject ? SubjectColumn : CompColumn;
  const view = VIEW_CONFIG.find(item => item.viewKey === columnView);
  const notesKey = isSubject ? 'subject' : listing?.listingIdSHA;

  const propertyAppliedAdjustments =
    !isSubject &&
    listing.listingIdSHA &&
    listing.listingIdSHA in appliedAdjustments
      ? appliedAdjustments[listing.listingIdSHA] || []
      : [];

  const getPoptipContent = (fieldName: string, fieldValue: string) => {
    const fieldNameLabel = `${fieldName}`;
    return (
      <>
        <PoptipFieldLabel>{fieldNameLabel}</PoptipFieldLabel>
        <div>{fieldValue}</div>
      </>
    );
  };

  // TODO(wes): Move this into its own component
  const getBottomView = () => {
    if (columnView === ViewKeys.LISTING_DETAILS) {
      const lotSizeUnit = agentInputs?.lotSizeUnit;
      const listDataFields = listingDataFields({
        selectListingAttributes:
          agentInputs.selectListingAttributes?.filter(
            attr =>
              !hiddenSideBySideListingAttributes?.includes(
                attr?.processedListingPath ?? '',
              ),
          ) || [],
        allFields: allDisplayFields,
        listing,
        opts: {doNotUseDefaults: true},
      });
      return listDataFields.map((field, fieldIdx) => {
        const adjustedPrice = getAdjustedPrice(
          getListingPrice(listing),
          propertyAppliedAdjustments,
        );
        const fieldValue = field.getValue(listing, {
          adjustedPrice,
          lotSizeUnit,
          agentInputs,
          isSubject,
        });
        const fieldName = field.name;
        const position =
          listDataFields.length - fieldIdx <= 3 ? 'top' : 'bottom';
        return (
          <DataCell
            data-tn="dataCell"
            key={fieldName}
            {...getPoptipAnchorProps(
              getPoptipContent(fieldName ?? '', fieldValue),
              position,
            )}
          >
            {isSubject ? <FieldLabel>{fieldName}</FieldLabel> : null}
            <FieldValue isSubject={isSubject}>
              {fieldValue ? fieldValue : '-'}
            </FieldValue>
          </DataCell>
        );
      });
    }

    const filteredPhotos = view?.photoFilter
      ? listing?.media?.filter(view.photoFilter)
      : listing?.media;
    if (filteredPhotos?.length) {
      const media = getMedia(
        isSubject || index == undefined ? 0 : index + 1,
        agentInputs.assets ?? [],
        listing.media ?? [],
      );
      return media.map((mediaItem, mediaIndex) => {
        if (mediaItem.thumbnailUrl && view?.photoFilter?.(mediaItem)) {
          return (
            <PhotoCell
              key={mediaItem.thumbnailUrl}
              isSubject={isSubject}
              isBottomView
            >
              <Image
                data-tn="photoCellImage"
                src={getDisplayImageFromThumbnailUrl(mediaItem.thumbnailUrl)}
                onClick={() => {
                  onPhotoImageClick({listing, media, mediaIndex});
                }}
              />
            </PhotoCell>
          );
        }
        return null;
      });
    }
    return (
      <NoPhotosMessage>
        No {view?.noPhotosLabel || ''} photos detected
      </NoPhotosMessage>
    );
  };

  const listingDescription = listingDescriptionField.getValue?.(
    listing,
  ) as string;

  return (
    <ColumnType data-tn="propertyColumn">
      <PhotoCell isSubject={isSubject} isBottomView={false}>
        <ListingPhotoWithPropertyStatus
          index={index}
          onPhotoClick={() => {
            onListingPhotoClick({isSubject, index});
          }}
          isSubject={isSubject}
          listing={listing}
        />
      </PhotoCell>
      <PropertyTitleBlockWrapper isSubject={isSubject}>
        <PropertyTitleBlock isSubject={isSubject} listing={listing} />
      </PropertyTitleBlockWrapper>
      <ListingDescription isSubject={isSubject}>
        {!hiddenSideBySideListingAttributes?.includes(
          listingDescriptionField.processedListingPath,
        ) && (
          <ListingDescriptionContent
            {...getPoptipAnchorProps(listingDescription, 'bottom')}
          >
            {listingDescription}
          </ListingDescriptionContent>
        )}
        <div>
          <Button
            variant="naked"
            size="sm"
            aria-label="View listing details"
            style={{paddingLeft: 0, paddingRight: 0}}
            onClick={() => onListingPhotoClick({isSubject, index})}
          >
            View listing details
          </Button>
        </div>
      </ListingDescription>
      {!adjustmentsExist && <Separator isSubject={isSubject} />}
      {adjustmentsExist && (
        <AdjustmentsContainer
          height={adjustmentsBlockHeight}
          isSubject={isSubject}
          hasAdjustments={propertyAppliedAdjustments.length > 0}
        >
          {!isSubject && propertyAppliedAdjustments.length === 0 && (
            <NoAdjustmentsMessage>No Adjustments</NoAdjustmentsMessage>
          )}
          {propertyAppliedAdjustments.map((adjustment, adjIndex) => {
            const adjustmentValue = adjustment.value ?? 0;
            const adjustmentValueSign = adjustmentValue >= 0 ? '+' : '-';
            // Strip the negative sign in the adjustment value so we can control the signage styling
            const displayValue = Math.abs(adjustmentValue).toLocaleString();
            return (
              <AdjustmentStyled
                key={`${index}-${adjIndex}`}
                onMouseEnter={({currentTarget}) => {
                  if (typeof index === 'number') {
                    setTooltipState?.({
                      text: adjustment.explanation ?? '',
                      index,
                      boundingRect: currentTarget.getBoundingClientRect(),
                    });
                  }
                }}
                onMouseLeave={() => {
                  setTooltipState?.(null);
                }}
              >
                <AdjustmentsExplanation>
                  {adjustment.explanation}
                </AdjustmentsExplanation>
                <AdjustmentsValue>{`${adjustmentValueSign} ${displayValue}`}</AdjustmentsValue>
              </AdjustmentStyled>
            );
          })}

          {propertyAppliedAdjustments.length > 3 ? (
            <ToggleAdjustments
              onClick={toggleAdjustmentsBlock}
              isExpanded={isAdjustmentsBlockExpanded}
            >
              <ExpandLabel>
                {isAdjustmentsBlockExpanded ? 'Minimize' : 'Show More'}
              </ExpandLabel>
              <ChevronSvg
                height="12"
                width="12"
                isExpanded={isAdjustmentsBlockExpanded}
              />
            </ToggleAdjustments>
          ) : null}
          {!isSubject && propertyAppliedAdjustments.length ? (
            <AdjustedPriceBlock
              adjustments={propertyAppliedAdjustments}
              listing={listing}
            />
          ) : null}
        </AdjustmentsContainer>
      )}
      {columnView === ViewKeys.LISTING_DETAILS ? (
        <PriceBlock isSubject={isSubject} listing={listing} />
      ) : null}
      <DataCellsContainer>{getBottomView()}</DataCellsContainer>
      {!hideAgentNotes && agentInputs.compsNotes && (
        <>
          <NoteSeparator />
          <NoteWrapper>
            {notesKey && agentInputs.compsNotes[notesKey] ? (
              <NotesFieldValue>
                <IconWrapper>
                  <Icon.TalkBubble />
                  <NotesHeader>Agent Note</NotesHeader>
                </IconWrapper>
                <NoteContent>
                  {agentInputs.compsNotes[notesKey]
                    .split('\n')
                    .map((item, idx) => {
                      return (
                        <span key={idx}>
                          {item}
                          <br />
                        </span>
                      );
                    })}
                </NoteContent>
              </NotesFieldValue>
            ) : (
              <NoNotesFieldValue>No note added</NoNotesFieldValue>
            )}
          </NoteWrapper>
        </>
      )}
      <Poptip {...poptipProps} zIndex="var(--cx-zIndex-10)" />
    </ColumnType>
  );
};

const Column = styled.div`
  display: inline-block;
  vertical-align: top;
  @media ${mq.maxWidthMobileLandscape} {
    width: ${COMP_COLUMN_WIDTH_MOBILE}px;
    min-width: ${COMP_COLUMN_WIDTH_MOBILE}px;
    max-width: ${COMP_COLUMN_WIDTH_MOBILE}px;
    padding-top: ${spacing['1x']};
  }
`;

const NoteWrapper = styled.div`
  padding: ${spacing['2x']};
  font-family: ${font.familySans};
`;

const SubjectColumn = styled(Column)`
  width: 250px;
  flex-shrink: 0;
  @media ${mq.maxWidthMobileLandscape} {
    width: 100%;
    border-right: 1px solid ${color.border};
  }
`;

const CompColumn = styled(Column)`
  width: ${COMP_COLUMN_WIDTH}px;
  border-right: 1px solid ${color.border};
  flex-shrink: 0;
  @media ${mq.maxWidthMobileLandscape} {
    overflow: hidden;
    width: 100%;
  }
`;

const PhotoCell = styled.div<{isSubject: boolean; isBottomView: boolean}>`
  height: ${({isBottomView}) => !isBottomView && '120px'};
  ${({isSubject}) => (isSubject ? 'margin-left: 30px;' : 'padding: 0 4px;')}
  cursor: pointer;
  @media ${mq.maxWidthMobileLandscape} {
    margin-left: 0;
    padding: 0 ${spacing['1x']};
  }
`;

const PropertyTitleBlockWrapper = styled.div<{isSubject: boolean}>`
  padding: 10px;
  margin-left: ${({isSubject}) => isSubject && '30px'};
  font-family: ${font.familyLegible};
  line-height: ${font.sizeSubheader2};
  @media ${mq.maxWidthMobileLandscape} {
    margin-left: 0;
    padding: ${spacing['1x']} ${spacing['2x']};
  }
`;

const Separator = styled.hr<{isSubject: boolean}>`
  margin: 0 10px;
  margin-bottom: ${spacing['2x']};
  border-top: 1.5px solid ${color.grey80};
  ${({isSubject}) => (isSubject ? 'margin-left: 40px;' : '')}
  @media ${mq.maxWidthMobileLandscape} {
    margin: 0 ${spacing['1x']};
  }
`;

const NoteSeparator = styled.hr<{isSubject?: boolean}>`
  margin: 0 10px;
  margin-top: ${spacing['2x']};
  border-top: 1.5px solid ${color.grey80};
  ${({isSubject}) => (isSubject ? 'margin-left: 40px;' : '')}
  @media ${mq.maxWidthMobileLandscape} {
    margin: 0 ${spacing['1x']};
  }
`;

const NoteContent = styled.div`
  padding-top: ${spacing['2x']};
  overflow-wrap: break-word;
`;

const AdjustmentsContainer = styled.div<{
  isSubject: boolean;
  hasAdjustments: boolean;
  height?: number;
}>`
  position: relative;
  overflow: hidden;
  border-bottom: 2px solid ${color.grey80};
  margin-left: ${({isSubject}) => isSubject && '30px'};
  transition: height 0.5s ease-in-out;
  height: ${({height}) => height}px;
  font-family: ${font.familyLegible};
  font-size: ${font.sizeCaption1};
  background-color: ${({hasAdjustments}) =>
    hasAdjustments ? color.blue40 : color.grey20};
  @media ${mq.maxWidthMobileLandscape} {
    margin-left: 0;
    border: 0;
    font-size: ${font.sizeCaption1};
  }
`;

const NoAdjustmentsMessage = styled.div`
  padding: ${spacing['half']} ${spacing['2x']};
  color: ${color.grey90};
  font-size: ${font.sizeCaption1};
  font-family: ${font.familyLegible};
`;

const AdjustmentStyled = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${spacing['1x']} ${spacing['2x']};
  position: relative;
  &:hover .cx-poptip {
    display: block;
  }
`;

const AdjustmentsExplanation = styled.div`
  display: inline-block;
  color: ${color.text};
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: ${font.weightSemiBold};
  max-width: ${ADJUSTMENTS_EXPLANATION_WIDTH}px;
`;

const AdjustmentsValue = styled.div`
  display: inline-block;
  text-align: right;
  font-weight: ${font.weightSemiBold};
  color: ${color.text};
  width: ${ADJUSTMENTS_VALUE_WIDTH}px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ToggleAdjustments = styled.div<{isExpanded: boolean}>`
  position: absolute;
  /* 40px is the height of AdjustedPriceBlock */
  bottom: 40px;
  left: 0;
  right: 0;
  cursor: pointer;
  height: ${TOGGLE_ADJUSTMENTS_HEIGHT}px;
  line-height: 32px;
  padding-left: ${spacing['1x']};
  background-color: ${color.blue40};
  ${({isExpanded}) =>
    isExpanded ? '' : `border-top: 1px solid ${color.border};`}
`;

const ChevronSvg = styled(Icon.ChevronDown)<{isExpanded: boolean}>`
  transform: scale(1.5) ${({isExpanded}) => (isExpanded ? ' scale(1, -1)' : '')};
  transition: transform 0.5s ease-in-out;
  vertical-align: middle;
  margin-left: ${spacing['1x']};
`;

const ExpandLabel = styled.div`
  display: inline-block;
`;

const DataCell = styled.div`
  display: flex;
  height: 32px;
  align-items: center;
  font-size: ${font.sizeBody2};
  font-family: ${font.familyLegible};
  padding: 0 ${spacing.half};
  position: relative;
  &:nth-of-type(odd) {
    background-color: ${color.grey20};
  }
  @media ${mq.maxWidthMobileLandscape} {
    height: initial;
    font-size: ${font.sizeCaption1};
    justify-content: space-between;
    padding: ${spacing['1x']} ${spacing['2x']};
  }
`;

const FieldLabel = styled.span`
  flex: 1;
  text-align: right;
  font-weight: ${font.weightBold};
  margin-right: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-family: ${font.familySans};
  padding-left: ${spacing['half']};
  @media ${mq.maxWidthMobileLandscape} {
    margin-right: 0;
    text-align: left;
    display: inline-block;
    max-width: 75%;
  }
`;

const NoNotesFieldValue = styled.div`
  width: '100%';
  white-space: normal;
  color: ${color.grey90};
  font-weight: ${font.weightMedium};
  font-size: ${font.sizeBody1};
  @media ${mq.maxWidthMobileLandscape} {
    width: fit-content;
    max-width: 70%;
    margin-left: ${spacing['1x']};
  }
`;

const NotesFieldValue = styled.div`
  width: '100%';
  white-space: normal;
  @media ${mq.maxWidthMobileLandscape} {
    width: fit-content;
    max-width: 70%;
    margin-left: ${spacing['1x']};
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const FieldValue = styled.span<{isSubject: boolean}>`
  margin-right: ${spacing['1x']};
  width: ${({isSubject}) => (isSubject ? '100px' : '100%')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: ${font.familySans};
  text-align: right;
  @media ${mq.maxWidthMobileLandscape} {
    width: fit-content;
    max-width: 70%;
    margin-left: ${spacing['1x']};
  }
`;

const Image = styled.img`
  width: 100%;
  @media ${mq.maxWidthMobileLandscape} {
    height: auto;
  }
`;

const NotesHeader = styled.div`
  display: inline-block;
  margin-left: ${spacing.half};
  padding-left: ${spacing['1x']};
  vertical-align: text-bottom;
  font-weight: ${font.weightMedium};
  font-size: ${font.sizeBody1};
`;

const NoPhotosMessage = styled.div`
  white-space: normal;
  text-align: center;
  color: ${color.textSubtle};
  font-family: ${font.familyLegible};
  font-style: italic;
  font-size: ${font.sizeCaption1};
  margin-top: ${spacing['3x']};
`;

const PoptipFieldLabel = styled.div`
  font-weight: ${font.weightSemiBold};
`;

const DataCellsContainer = styled.div`
  position: relative;
  // TODO: Once we can use an extensible Poptip from DS, replace the
  // class targeting here with the Poptip component
  .cx-react-poptip {
    text-align: center;
    max-width: 250px;
  }
  @media ${mq.maxWidthMobileLandscape} {
    // TODO: Once we can use an extensible Poptip from DS, replace the
    // class targeting here with the Poptip component
    .cx-react-poptip {
      max-width: ${COMP_COLUMN_WIDTH_MOBILE}px;
    }
  }
`;

const ListingDescription = styled.div<{isSubject?: boolean}>`
  padding: 0px 10px 8px;
  ${({isSubject}) =>
    isSubject &&
    `
    margin-left: 30px;
  `}

  @media ${mq.maxWidthMobileLandscape} {
    margin-left: 0px;
    padding: 0px 16px 8px;
  }
`;

const ListingDescriptionContent = styled.div`
  display: -webkit-box;
  height: 26px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 13px;
  font-size: var(--cx-font-sizeCaption2);
  overflow: hidden;
`;
