import {NetSheetsLineItem} from '@uc/thrift2npme/dist/cma/cma_models';

interface SplitGeneralItemsResult {
  netEquityInProperty?: NetSheetsLineItem;
  estNetProceeds?: NetSheetsLineItem;
  subtotalOfClosingCosts?: NetSheetsLineItem;
  generalCosts: NetSheetsLineItem[];
}

enum SectionTotalLineItemType {
  NET_EQUITY_IN_PROPERTY = 'netEquityInProperty',
  SUBTOTAL_OF_CLOSING_COSTS = 'subtotalOfClosingCosts',
  EST_NET_PROCEEDS = 'estimatedNetProceeds',
}
export const splitGeneralItems = (
  items: NetSheetsLineItem[],
): SplitGeneralItemsResult => {
  const result: SplitGeneralItemsResult = {
    generalCosts: [],
  };

  for (const item of items) {
    if (!item?.id) continue;

    switch (item.id) {
      case SectionTotalLineItemType.NET_EQUITY_IN_PROPERTY:
        result.netEquityInProperty = item;
        break;
      case SectionTotalLineItemType.EST_NET_PROCEEDS:
        result.estNetProceeds = item;
        break;
      case SectionTotalLineItemType.SUBTOTAL_OF_CLOSING_COSTS:
        result.subtotalOfClosingCosts = item;
        break;
      default:
        result.generalCosts.push(item);
    }
  }

  return result;
};
