import styled from '@emotion/styled';
import React, {FC} from 'react';

import {Icon, TextLink} from '@uc/cx.react';
import {formatListingUrl} from '@uc/format';
import {color, font, spacing, mq} from '@uc/cx-design-tokens';
import {AgentInputs} from '@uc/thrift2npme/dist/cma/cma_models';
import {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';

import {getGeneralInfo} from '@/utils/getGeneralInfo';
import {getColorByStatusValue} from '@/utils/getColorByStatusValue';
import {useResponsive} from '@/hooks';

type Props = {
  agentInputs: AgentInputs;
  listing: ProcessedListing;
  isForWebView?: boolean;
  className?: string;
  showPrice?: boolean;
  showStatus?: boolean;
  topBorder?: boolean;
  bottomBorder?: boolean;
  shouldCenter?: boolean;
  showListingPageUrl?: boolean;
  showLotSizeSuffix?: boolean;
  isCalculateAcres?: boolean;
  shouldFormatIntoString?: boolean;
  printMode?: boolean;
};

type Info = {
  Bed: string;
  Bath: string;
  SqFt: string;
  'Lot Size': string | number;
  '$/SqFt'?: string | number;
  DOM?: string;
  Price?: string;
};

export const PropertyGeneralInfo: FC<Props> = ({
  agentInputs,
  className,
  listing,
  isForWebView = false,
  showPrice = false,
  showStatus = false,
  topBorder = false,
  bottomBorder = false,
  shouldCenter = false,
  showListingPageUrl = false,
  showLotSizeSuffix = false,
  shouldFormatIntoString = false,
  printMode = false,
}) => {
  const {isMobile} = useResponsive();
  const info = getGeneralInfo({
    agentInputs,
    listing,
    showPrice,
    showLotSizeSuffix,
    shouldFormatIntoString,
    isMobile: !printMode && isMobile,
  });

  const listingUrl = formatListingUrl(listing);
  const showListingUrl = listingUrl && showListingPageUrl;

  return (
    <Wrapper
      data-tn="Wrapper-view"
      shouldCenter={shouldCenter}
      bottomBorder={bottomBorder}
      topBorder={topBorder}
      className={className}
    >
      <PropertyGeneralInfoGroup
        data-tn="PropertyGeneralInfoGroup-view-group"
        isForWebView={isForWebView}
      >
        <PropertyGeneralInfoCell
          isHidden={!showStatus}
          isFirst={true}
          isForWebView={isForWebView}
        >
          <GeneralInfoLabel isForWebView={isForWebView}>
            Status
          </GeneralInfoLabel>
          <GeneralInfoValue
            data-tn="GeneralInfoValue-text-statusValue"
            status={listing?.status}
            isForWebView={isForWebView}
          >
            {listing?.localizedStatus}
          </GeneralInfoValue>
        </PropertyGeneralInfoCell>

        {Object.keys(info).map((label: string, idx: number) => (
          <React.Fragment key={`property-info-${label}`}>
            {isForWebView && (idx > 0 || (idx === 0 && showStatus)) ? (
              <Separator />
            ) : null}

            <PropertyGeneralInfoCell
              data-tn="PropertyGeneralInfoCell-view-cell"
              data-label={label}
              isFirst={idx === 0 && !showStatus}
              isForWebView={isForWebView}
            >
              <GeneralInfoLabel isForWebView={isForWebView}>
                {label}
              </GeneralInfoLabel>
              <GeneralInfoValue
                data-tn={`PropertyGeneralInfoCell-view-value-${label}`}
                isForWebView={isForWebView}
              >
                {info[label as keyof Info]}
              </GeneralInfoValue>
            </PropertyGeneralInfoCell>
          </React.Fragment>
        ))}
        {isForWebView && showListingUrl && (
          <Separator data-tn="Separator-view" />
        )}
      </PropertyGeneralInfoGroup>
      {isForWebView && showListingUrl && (
        <PropertyViewDetailsLink>
          <Icon.DoubleBoxedArrowUpRight
            width="16"
            height="16"
            className="link-icon"
          />
          <TextLink
            data-tn="TextLink-link-viewDetails"
            href={listingUrl}
            target="_blank"
          >
            View full details
          </TextLink>
        </PropertyViewDetailsLink>
      )}
    </Wrapper>
  );
};

type WrapperProps = {
  shouldCenter?: boolean;
  topBorder: boolean;
  bottomBorder: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  padding: ${spacing['1x']} ${spacing['2x']};
  padding-left: ${(props: WrapperProps) => !props.shouldCenter && '0'};
  padding-top: ${(props: WrapperProps) => props.topBorder && spacing['2x']};
  border-top: ${(props: WrapperProps) =>
    props.topBorder && `1px solid ${color.border}`};
  padding-bottom: ${(props: WrapperProps) =>
    props.bottomBorder && spacing['2x']};
  border-bottom: ${(props: WrapperProps) =>
    props.bottomBorder && `1px solid ${color.border}`};
`;

const Separator = styled.div`
  border-right: 1px solid ${color.border};
  display: none;

  @media ${mq.maxWidthMobileLandscape} {
    display: initial;

    &:last-of-type {
      display: none;
    }
  }
`;

type PropertyGeneralInfoCellProps = {
  isFirst?: boolean;
  isHidden?: boolean;
  isForWebView: boolean;
};

const PropertyGeneralInfoCell = styled.div<PropertyGeneralInfoCellProps>`
  align-items: space-between;
  border-left: ${(props: PropertyGeneralInfoCellProps) =>
    props.isForWebView && !props.isFirst
      ? `1px solid ${color.border}`
      : 'none'};
  display: ${(props: PropertyGeneralInfoCellProps) =>
    props.isHidden ? 'none' : 'flex'};
  flex-direction: column;
  justify-content: space-between;
  padding: 0 ${spacing['2x']};
  padding-left: ${(props: PropertyGeneralInfoCellProps) => props.isFirst && 0};
  text-align: center;

  ${(props: PropertyGeneralInfoCellProps) =>
    !props.isForWebView &&
    `
    font-style: normal;
    font-weight: ${font.weightRegular};
    font-size: ${font.sizeBody1};
    line-height: ${spacing['3x']};
  
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 15%;
    `}

  ${(props: PropertyGeneralInfoCellProps) =>
    props.isForWebView &&
    `
      @media ${mq.maxWidthMobileLandscape} {
        border: 0;
        margin-right: 0;
        padding: 0 ${spacing['1x']};
        padding-left: ${(props: PropertyGeneralInfoCellProps) => props.isFirst && 0};
      }
      `}
`;

export const GeneralInfoLabel = styled.div<{
  isForWebView?: boolean;
}>`
  font-weight: ${font.weightMedium};
  ${({isForWebView}) =>
    isForWebView &&
    `
    line-height: ${font.sizeCaption1};
    font-weight: ${font.weightBold};
    font-family: ${font.familyLegible};
    `}
`;

type GeneralInfoValueProps = {status?: number; isForWebView: boolean};

const GeneralInfoValue = styled.div<GeneralInfoValueProps>`
  ${(props: GeneralInfoValueProps) =>
    props.isForWebView &&
    `
  font-size: ${font.sizeBody1};
  line-height: ${font.sizeBody1};
  padding-top: ${spacing['1x']};
  color: ${props.status && getColorByStatusValue(props.status)};
  font-weight: ${props.status && font.weightBold};
  

  @media ${mq.maxWidthMobileLandscape} {
    font-size: ${font.sizeBody2};
    line-height: ${font.sizeBody2};
  }
  `}
`;

type PropertyGeneralInfoGroupProps = {
  showListingPageUrl?: boolean | null | '';
  isForWebView: boolean;
};

const PropertyGeneralInfoGroup = styled.div<PropertyGeneralInfoGroupProps>`
  display: flex;
  ${(props: PropertyGeneralInfoGroupProps) =>
    !props.showListingPageUrl && 'flex-grow: 1;'}

  ${(props: PropertyGeneralInfoGroupProps) =>
    !props.isForWebView &&
    ' margin-top: 16px; margin-bottom: 16px; padding-right: 12px;'}

      ${(props: PropertyGeneralInfoGroupProps) =>
    props.isForWebView &&
    `
      @media ${mq.maxWidthMobileLandscape} {
        justify-content: space-around;
      }
    `}
`;

PropertyGeneralInfoGroup.displayName = 'PropertyGeneralInfoGroup';

const PropertyViewDetailsLink = styled.div`
  display: flex;
  align-items: center;
  padding-left: ${spacing['2x']};
  font-size: ${font.sizeBody2};
  border-left: 1px solid ${color.border};
  letter-spacing: 0.5px;
  font-weight: ${font.weightMedium};

  .link-icon {
    color: ${color.link};
    fill: currentColor;
    margin: 0 ${spacing['1x']} 0 ${spacing.half};
    transform: scale(1.2);
  }
  @media ${mq.maxWidthMobileLandscape} {
    padding: ${spacing['2x']};
    border-top: ${`1px solid ${color.border}`};
    border-left: 0;
    margin: ${spacing['1x']} -${spacing['2x']} -${spacing['1x']};
  }
`;
