export const MAX_ROWS_NUMBER = 12;
export const MAX_LAST_ROWS_NUMBER = 16;

export const ErrorMessage = {
  percentages: 'Must be less than 100.',
  min: 'Must be smaller than maximum',
  overAllowed: (max: number, partnerEmail: string) =>
    `For sales over $${max}M, email ${partnerEmail} for a custom quote.`,
  salesPriceRequired: 'Enter a sales price',
  settlementDateRequired: 'Select a settlement date ',
};

export const RequiredFieldsError: {[key: string]: string} = {
  salesPrice: ErrorMessage.salesPriceRequired,
  settlementDate: ErrorMessage.settlementDateRequired,
};

export const commissions = [
  'Buyer agent commission',
  'Seller agent commission',
];

export const commissionsNameMap: Record<string, string> = {
  'Buyer agent commission': 'Agent commissions (buyer)',
  'Seller agent commission': 'Agent commissions (seller)',
};
