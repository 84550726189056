import styled from '@emotion/styled';
import React, {VFC} from 'react';

import {NetSheetsLineItem} from '@uc/thrift2npme/dist/cma/cma_models';
import {font, mq, spacing} from '@uc/cx-design-tokens';

import {NetsheetAmount} from '../NetsheetAmount';
import {FeesSectionLayout} from './FeesSectionLayout';
import {DetailedFeeLineItem} from './DetailedFeeLineItem';
import {getAmount} from '@/utils/netsheet-utils';
import {NetsheetFooter} from '../NetsheetFooter';
import {
  GroupProps,
  LayoutProps,
} from '@/utils/netsheets/getSellerNetSheetDataFilter';

const Wrapper = styled.div<{isPreview?: boolean}>`
  padding: 48px;
  font-family: ${font.familySans};
  ${(props: {isPreview?: boolean}) => props.isPreview && 'min-height: 50vh;'};
  @media ${mq.maxWidthMobileLandscape} {
    padding: ${spacing['1x']};
  }
`;

const ListDetail = styled.div`
  flex: 1;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--cx-color-text);
  line-height: var(--cx-font-lineHeightCompact);
  margin-bottom: var(--cx-spacing-3x);
`;

const Title = styled.div`
  flex: 1;
  font-weight: var(--cx-font-weightMedium);
  font-size: var(--cx-font-sizeHeader4);
`;

const DateAndAuthor = styled.div`
  text-align: right;
  font-weight: var(--cx-font-weightRegular);
  font-size: var(--cx-font-sizeCaption2);
`;

const SubHeader = styled.div`
  padding-top: var(--cx-spacing-1x);
  color: var(--cx-color-text);
  font-weight: var(--cx-font-weightRegular);
  font-size: var(--cx-font-sizeBody2);
  line-height: var(--cx-font-lineHeightCompact);
`;

const Section = styled.div`
  margin-bottom: var(--cx-spacing-1x);
`;

const SubTotalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  border-top: 1px solid var(--cx-color-grey80);
  color: var(--cx-color-text);
  font-size: var(--cx-font-sizeBody2);
  font-weight: var(--cx-font-weightMedium);
  line-height: var(--cx-font-lineHeightCompact);
`;

const TotalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  border-top: 1px solid var(--cx-color-grey120);
  color: var(--cx-color-text);
  font-size: var(--cx-font-sizeBody2);
  font-weight: var(--cx-font-weightMedium);
  line-height: var(--cx-font-lineHeightCompact);
  padding-top: var(--cx-spacing-3x);
`;

type Props = {
  total: NetSheetsLineItem;
  subTotal: NetSheetsLineItem;
  layouts: LayoutProps[];
  author: string;
  date: string;
  isPreview?: boolean;
  footer?: React.ReactNode;
};

const Group: VFC<GroupProps> = ({header, detail, hasFirstTotal}) => {
  return (
    <Section>
      {header && <SubHeader data-tn={header}>{header}</SubHeader>}
      {detail.map(d => {
        return (
          <DetailedFeeLineItem
            key={d.title}
            title={d.title}
            value={getAmount({
              amount1: Number(d.minValue) ? d.minValue : undefined,
              amount2: Number(d.maxValue) ? d.maxValue : undefined,
            })}
            description={d.description}
            hasFirstTotal={hasFirstTotal}
          />
        );
      })}
    </Section>
  );
};

export const NetsheetGenericPage: VFC<Props> = ({
  total,
  subTotal,
  layouts,
  author,
  date,
  isPreview,
  footer,
}) => {
  return (
    <Wrapper isPreview={isPreview}>
      <ListDetail>
        <Header>
          <Title>{'Estimated seller net sheet'}</Title>
          <DateAndAuthor>
            {date}
            <br />
            {author ? `Prepared by ${author}` : ''}
          </DateAndAuthor>
        </Header>

        {layouts.map(layout => (
          <FeesSectionLayout title={layout.title} key={layout.title}>
            {layout.groups?.map((group, i) => (
              <Group
                key={i}
                header={group.header}
                hasFirstTotal={group.hasFirstTotal}
                detail={group.detail}
              />
            ))}
          </FeesSectionLayout>
        ))}
      </ListDetail>

      <SubTotalWrapper>
        <div>Estimated closing costs subtotal</div>
        <div>
          <NetsheetAmount
            showUniqueValueWithAsterisk
            amount={getAmount({
              amount1: subTotal.minValue,
              amount2: subTotal.maxValue,
              asterisk: true,
            })}
          />
        </div>
      </SubTotalWrapper>
      <TotalWrapper>
        <div>Estimated net proceeds</div>
        <div>
          <NetsheetAmount
            showUniqueValueWithAsterisk
            amount={getAmount({
              amount1: total.minValue,
              amount2: total.maxValue,
              asterisk: true,
            })}
          />
        </div>
      </TotalWrapper>
      {footer ? footer : <NetsheetFooter />}
    </Wrapper>
  );
};
