import styled from '@emotion/styled';
import React from 'react';

import {color, font, spacing} from '@uc/cx-design-tokens';
import {Partner} from '@uc/thrift2npme/dist/apex_proxy/apex_proxy_common';
import {PartnerMetadata} from '@uc/thrift2npme/dist/apex_proxy/apex_proxy_partners';

import {useStateValue} from '@/context/state';

const Root = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacing['1x']};
  padding: ${spacing['half']} 0;
  line-height: 1;
`;

const Label = styled.span<{labelColor?: string}>`
  color: ${({labelColor}) => labelColor || color.black};
  font-size: ${font.sizeCaption2};
`;

const getLogoWidth = (partnerId: Partner) => {
  switch (partnerId) {
    case Partner.AKT:
      return '146px';
    case Partner.CHARTWELL:
    case Partner.LTT:
      return '150px';
    case Partner.KVS:
      return '72px';
    case Partner.SQS:
      return '48px';
    case Partner.FAT:
      return '48px';
    case Partner.CTC:
      return '145px';
    default:
      return '90px';
  }
};

const LogoImg = styled.img<{partnerId: Partner | undefined}>`
  width: ${({partnerId}) => partnerId && getLogoWidth(partnerId)};
`;

export const GeneratedByPartner = ({
  labelColor,
  partnerMetadata,
}: {
  labelColor?: string;
  partnerMetadata?: PartnerMetadata;
}) => {
  const [state] = useStateValue();
  const {partnerConfig, cma: iCma} = state ?? {};
  const {cma} = iCma ?? {};

  const speedUpWorkflowEnabled =
    cma?.agentInputs?.netsheets?.input?.showSettlementCostsFromPartner ?? false;
  // listing-presentation does not have partner config, it will come from partnerMetadata
  const partnerId = partnerConfig?.partnerId ?? partnerMetadata?.partner;
  const partnerInfo = partnerConfig?.partnerMetadata ?? partnerMetadata;

  const shouldShowLogo = Boolean(
    (partnerConfig?.partnerMetadata?.logo && speedUpWorkflowEnabled) ||
      partnerMetadata?.logo,
  );

  return shouldShowLogo ? (
    <Root>
      <Label labelColor={labelColor}>Generated by</Label>
      <LogoImg
        src={partnerInfo?.logo}
        data-tn="SellerNetSheet_partner-logo"
        alt={partnerInfo?.displayName}
        partnerId={partnerId}
      />
    </Root>
  ) : null;
};
