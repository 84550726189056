import {NetSheetsLineItem} from '@uc/thrift2npme/dist/cma/cma_models';

import {OPTY_FLAGS} from '../constants';
import {useStateValue} from '../context/state';

const useIsTNEPartnerAvailable = (): boolean => {
  const [state] = useStateValue();
  const {partnerConfig, cma: iCma} = state ?? {};
  const {cma} = iCma ?? {};
  const hasPartnerConfig = !!partnerConfig?.partnerId;
  // if generic exists, check if belongs to old flow
  if (cma?.agentInputs?.netsheets?.generic) {
    const {
      salesPrice = {},
      predefinedFees = [],
      mortgage = {},
    } = cma.agentInputs?.netsheets?.generic;
    if (checkItemsNotExistId([salesPrice, ...predefinedFees, mortgage])) {
      return false;
    }
  }

  return (
    OPTY_FLAGS.IS_TNE_PARTNER_SPECIFIC_SELLER_NET_SHEET_ENABLED &&
    hasPartnerConfig
  );
};

const checkItemsNotExistId = (items: NetSheetsLineItem[]) => {
  return items.every(item => !item.id);
};

export {useIsTNEPartnerAvailable};
