import styled from '@emotion/styled';
import React, {FC} from 'react';

import {Icon} from '@uc/cx.react';
import {color, font, mq, spacing} from '@uc/cx-design-tokens';
import type {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';
import type {Asset} from '@uc/thrift2npme/dist/asset_library/asset_library_models';

import {displayStatuses} from '@/utils/listingStatusMaps';
import {ListingPhoto} from '@/components/pages/Web/components/listingPhoto/listingPhoto';
import {CUSTOM_LISTING_PREFIX} from '@/constants';
import {getDisplayImageFromThumbnailUrl} from '@/utils/display-helpers';
import {CompsGroupsType} from '@/types/types';
import {
  ROW_HEIGHT_PX,
  ROW_MOBILE_HEIGHT_PX,
  SUBJECT_PROPERTY_TITLE_TEXT,
} from './constants';
import {GroupHeader} from './GroupHeader';

type FixedColumnProps = {
  compsGroups: CompsGroupsType;
  expandedGroups: Record<string, boolean>;
  subjectProperty: ProcessedListing;
  agentInputsAssets?: Asset[];
  toggleGroupIsExpanded?: (displayStatus: string) => void;
  onColumnRowClick: (modalListingIndex: number) => void;
};

export const FixedColumn: FC<FixedColumnProps> = ({
  compsGroups,
  expandedGroups,
  subjectProperty,
  agentInputsAssets,
  toggleGroupIsExpanded,
  onColumnRowClick,
}) => {
  const asset = agentInputsAssets?.[0];
  const subjectCoverPhotoUrl =
    asset?.originalDetails?.url ||
    getDisplayImageFromThumbnailUrl(subjectProperty?.media?.[0]?.thumbnailUrl);

  return (
    <Wrapper data-tn="statusComparison-fixedColumn">
      <GroupHeader />
      <FixedColumnRow
        listing={subjectProperty}
        imgUrl={subjectCoverPhotoUrl}
        titleText={SUBJECT_PROPERTY_TITLE_TEXT}
        titleColor={color.link}
        onClick={() => {
          onColumnRowClick(0);
        }}
      />
      {compsGroups.map(([displayStatus, listings]) => {
        const isExpanded = displayStatus in expandedGroups;
        return (
          <React.Fragment key={displayStatus}>
            <GroupHeader
              displayStatus={displayStatus}
              listingsCount={listings.length}
              isExpanded={isExpanded}
              onClick={() => toggleGroupIsExpanded?.(displayStatus)}
            />
            <ListingsWrapper
              key={displayStatus}
              data-e2e-listings-group={displayStatus}
              listingsCount={listings.length}
              isExpanded={isExpanded}
            >
              {listings.map(item => {
                const {listing, originalIndex} = item;
                const statusColor =
                  displayStatuses[displayStatus]?.color || color.link;
                const titleColor =
                  statusColor === color.highlight
                    ? color.orange90
                    : statusColor;
                return (
                  <FixedColumnRow
                    key={listing.listingIdSHA}
                    listing={listing}
                    titleText={`Comparable #${originalIndex + 1}`}
                    titleColor={titleColor}
                    onClick={() => {
                      onColumnRowClick(originalIndex + 1);
                    }}
                    imgUrl={getDisplayImageFromThumbnailUrl(
                      listing?.media?.[0]?.thumbnailUrl,
                    )}
                  />
                );
              })}
            </ListingsWrapper>
          </React.Fragment>
        );
      })}
    </Wrapper>
  );
};

type FixedColumnRowProps = {
  imgUrl?: string;
  listing?: ProcessedListing;
  onClick?: () => void;
  titleColor?: string;
  titleText?: string;
};

export const FixedColumnRow: FC<FixedColumnRowProps> = ({
  listing,
  onClick,
  imgUrl,
  titleColor,
  titleText,
}) => (
  <Row
    data-e2e-listing={`listingIdSHA-${listing?.listingIdSHA}`}
    onClick={onClick}
  >
    <ListingPhotoWrapper>
      <StyledListingPhoto
        listingPhotoUrl={imgUrl}
        listingPhotoAriaLabel={`listing thumbnail photo for ${listing?.location?.prettyAddress}`}
      />
      <SubjectListingPhotoOverlay
        isSubject={titleText === SUBJECT_PROPERTY_TITLE_TEXT}
        data-tn="listing-photo-overlay"
      />
      {titleText === SUBJECT_PROPERTY_TITLE_TEXT ? (
        <StarSvg width="16" height="16" />
      ) : null}
      {listing?.listingIdSHA?.startsWith(CUSTOM_LISTING_PREFIX) && (
        <StatusLabel>CUSTOM</StatusLabel>
      )}
    </ListingPhotoWrapper>
    <Address className="textIntent-title2">
      <ListingTitle color={titleColor}>{titleText}</ListingTitle>
      {listing?.location?.prettyAddress}
    </Address>
  </Row>
);

const Wrapper = styled.div`
  box-shadow: 5px 0px 5px -3px ${color.innerShadow};
  display: flex;
  flex-direction: column;
  min-width: 350px;
  width: 350px;
  z-index: 1;

  @media ${mq.maxWidthMobileLandscape} {
    min-width: 50vw;
    width: 50vw;
  }
`;

const ListingsWrapper = styled.div<{
  isExpanded: boolean;
  listingsCount: number;
}>`
  display: inline-block;
  height: ${({isExpanded, listingsCount}) =>
    isExpanded ? listingsCount * ROW_HEIGHT_PX : 0}px;
  overflow: hidden;
  width: 100%;
  transition: height 0.5s ease-in-out;

  @media ${mq.maxWidthMobileLandscape} {
    height: ${({isExpanded, listingsCount}) =>
      isExpanded ? listingsCount * ROW_MOBILE_HEIGHT_PX : 0}px;
  }
`;

const Row = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: ${ROW_HEIGHT_PX}px;
  padding: ${spacing['1x']};

  & + & {
    border-top: 1px solid ${color.grey40};
  }

  @media ${mq.maxWidthMobileLandscape} {
    height: ${ROW_MOBILE_HEIGHT_PX}px;
    padding: 0;
  }
`;

const ListingPhotoWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 60px;
  justify-content: center;
  position: relative;
  width: 108px;

  @media ${mq.maxWidthMobileLandscape} {
    height: ${ROW_MOBILE_HEIGHT_PX}px;
    width: 50px;
  }
`;

const StatusLabel = styled.div`
  position: absolute;
  background: ${color.teal80};
  color: ${color.white};
  left: ${spacing['half']};
  top: ${spacing['half']};
  padding: ${spacing['half']};
  font-weight: ${font.weightBold};
  font-size: ${font.sizeCaption2};
`;

const StyledListingPhoto = styled(ListingPhoto)`
  height: 100%;
  position: absolute;
`;

const SubjectListingPhotoOverlay = styled.div<{isSubject: boolean}>`
  background: ${color.innerShadow};
  display: ${({isSubject}) => (isSubject ? 'initial' : 'none')};
  height: 100%;
  position: absolute;
  width: 100%;
`;

const StarSvg = styled(Icon.Star)`
  transform: scale(2);
  fill: ${color.white};

  @media ${mq.maxWidthMobileLandscape} {
    transform: scale(1.3);
  }
`;

const ListingTitle = styled.div`
  font: ${font.shorthandSmStrong};
  font-family: ${font.familyLegible};
  font-weight: ${font.weightSemiBold};
  color: ${props => props.color};
  font-size: ${font.sizeCaption1};
`;

const Address = styled.div`
  display: inline-block;
  max-width: 60%;
  overflow: hidden;
  padding-left: ${spacing['1x']};
  font-size: ${font.sizeBody2};

  @media ${mq.maxWidthMobileLandscape} {
    width: initial;
    font-size: ${font.sizeCaption1};
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 75%;
    padding-right: ${spacing['2x']};
  }
`;
