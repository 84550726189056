import styled from '@emotion/styled';
import React, {RefObject} from 'react';

import {color, font, mq, spacing} from '@uc/cx-design-tokens';

import type {SectionConfig} from '@/types/types';
import {AgentAvatar} from '@/components/pages/Web/components/agentAvatar/agentAvatar';
import {setNavExpanded} from '../../context/navigation';
import {useStateValue} from '../../context/state';
import {scrollContainerToElement} from '../../helpers/scrollContainerToElement';
import {KEYS, useKeyPress} from '../../hooks/use-key-press';

const StyledNavBar = styled.div<{isNavExpanded: boolean}>`
  background: ${color.background};
  border-left: 1px solid ${color.dropShadow};
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: 100vh;
  justify-content: space-between;
  padding-top: ${spacing['8x']};
  position: fixed;
  top: 0;
  transition: transform 0.5s ease-in;
  width: 350px;
  z-index: 99999;
  overflow-y: scroll;
  overflow-x: hidden;
  transform: ${({isNavExpanded}) =>
    isNavExpanded ? 'translateX(0px)' : 'translateX(-350px)'};

  @media ${mq.maxWidthMobileLandscape} {
    max-width: 90vw;
    padding-top: ${spacing['2x']};
  }
`;

const OpenCloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  right: ${spacing['3x']};
  top: ${spacing['3x']};
  padding: ${spacing.half};
`;

const CloseButtonIconSvg = styled.svg`
  transform: scale(1.2);
`;

const NavWrapper = styled.div`
  padding-left: ${spacing['4x']};
  font-size: ${font.sizeSubheader2};
  font-weight: ${font.weightMedium};

  @media ${mq.maxWidthMobileLandscape} {
    padding-left: ${spacing['3x']};
    font-size: ${font.sizeBody1};
  }
`;

const NavItem = styled.div<{isActive: boolean}>`
  color: ${({isActive}) => isActive && color.interactive};
  cursor: pointer;
  padding-top: calc(${spacing['3x']} / 2);
  padding-bottom: calc(${spacing['3x']} / 2);
`;

const Agent = styled.div`
  display: none;

  @media ${mq.maxWidthMobileLandscape} {
    align-items: center;
    border-top: 1px solid ${color.grey40};
    display: flex;
    font-size: ${font.sizeBody2};
    font-weight: ${font.weightMedium};
    padding: ${spacing['2x']};
  }
`;

const ContactInfo = styled.a`
  color: ${color.grey80};
  display: flex;
`;

const StyledAgentAvatar = styled(AgentAvatar)<{avatarUrl: string}>`
  background-image: url(${({avatarUrl}) => avatarUrl});
  border-radius: 0;
  height: 64px;
  margin-right: ${spacing['2x']};
  vertical-align: middle;
  width: 64px;
`;

type Props = {
  sectionsConfig?: SectionConfig[];
  viewPageRef?: RefObject<HTMLDivElement>;
  activeSectionIndex?: number;
};

export const NavBar = ({
  sectionsConfig,
  viewPageRef,
  activeSectionIndex,
}: Props) => {
  const [{agent, isNavExpanded}, dispatch] = useStateValue();

  useKeyPress(KEYS.ESC, () => dispatch(setNavExpanded(false)));

  return (
    <StyledNavBar isNavExpanded={isNavExpanded}>
      <OpenCloseButton
        data-tn="nav-bar__close-btn"
        data-e2e-nav-toggle={isNavExpanded}
        onClick={() => dispatch(setNavExpanded(!isNavExpanded))}
      >
        <CloseButtonIconSvg className="cx-icon" width="20" height="20">
          <use xlinkHref="/ucfe-assets/cx-icons/4/cx-icons.cdn.svg#cx-icon-x_16x16" />
        </CloseButtonIconSvg>
      </OpenCloseButton>
      <NavWrapper>
        {sectionsConfig?.map(
          ({intersection: [, ref] = [], props: {key, name} = {}}, index) => (
            <NavItem
              data-e2e-nav-item={key}
              key={key}
              isActive={index === activeSectionIndex}
              onClick={() => {
                dispatch(setNavExpanded(false));
                setTimeout(() => {
                  if (viewPageRef && ref) {
                    scrollContainerToElement(
                      viewPageRef,
                      ref as RefObject<HTMLElement>,
                    );
                  }
                }, 700);
              }}
            >
              {name}
            </NavItem>
          ),
        )}
      </NavWrapper>
      <Agent>
        {agent.avatarUrl ? (
          <StyledAgentAvatar avatarUrl={agent.avatarUrl} />
        ) : null}
        <div>
          <div>{agent.displayName}</div>
          <ContactInfo href={`mailto:${agent.email}`}>
            {agent.email}
          </ContactInfo>
          <ContactInfo href={`tel:${agent.phone}`}>{agent.phone}</ContactInfo>
        </div>
      </Agent>
    </StyledNavBar>
  );
};
