import React from 'react';

import {useStateValue} from '@/components/pages/Web/context/state';
import {PricingAnalysis} from '@/components/PricingAnalysis';

export const ListingPresentationPricingAnalysis = () => {
  const [{cma}] = useStateValue();

  return <PricingAnalysis cma={cma} isPreview={false} />;
};
