import {NetSheetsLineItemNumber, Value} from './types';

export function getNetEquity(
  salesPrice: NetSheetsLineItemNumber,
  mortgage: NetSheetsLineItemNumber,
  secondSellerMortgageBalance?: NetSheetsLineItemNumber,
): Value {
  let minValue = salesPrice.minValue - mortgage.minValue;
  let maxValue = salesPrice.maxValue - mortgage.maxValue;

  if (secondSellerMortgageBalance) {
    minValue -= secondSellerMortgageBalance.minValue;
    maxValue -= secondSellerMortgageBalance.maxValue;
  }

  return {
    minValue: minValue || maxValue,
    maxValue: maxValue || minValue,
  };
}
