import React from 'react';

import {useStateValue} from '@/components/pages/Web/context/state';
import {MarketReport} from '@/components/MarketReport';

export function ListingPresentationMarketReport() {
  const [{cma}] = useStateValue();

  return <MarketReport cma={cma} isPreview={false} />;
}
