import styled from '@emotion/styled';
import React, {FC} from 'react';

import {color, font, mq, spacing} from '@uc/cx-design-tokens';
import {Icon, TextLink} from '@uc/cx.react';
import {NoSsr} from '@uc/cx.react';
import {formatListingUrl} from '@uc/format';
import type {CustomListingAttribute} from '@uc/thrift2npme/dist/cma/cma_models';
import type {ProcessedListing} from '@uc/thrift2npme/dist/listing_translation/processed_listing';

import {getPropertyGeneralInfo} from './getPropertyGeneralInfo';

const Wrapper = styled.div<{topBorder: boolean}>`
  display: flex;
  padding: ${spacing['1x']} ${spacing['2x']};
  padding-top: ${({topBorder}) => topBorder && spacing['2x']};
  border-top: ${({topBorder}) => topBorder && `1px solid ${color.border}`};
`;

const Separator = styled.div`
  border-right: 1px solid ${color.border};
  display: none;
  @media ${mq.maxWidthMobileLandscape} {
    display: initial;
    &:last-of-type {
      display: none;
    }
  }
`;

const PropertyGeneralInfoCell = styled.div<{isFirst: boolean}>`
  align-items: space-between;
  border-left: ${({isFirst}) => !isFirst && `1px solid ${color.border}`};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 ${spacing['2x']};
  padding-left: ${({isFirst}) => isFirst && 0};
  text-align: center;

  @media ${mq.maxWidthMobileLandscape} {
    border: 0;
    margin-right: 0;
    padding: 0 ${spacing['1x']};
    padding-left: ${({isFirst}) => isFirst && 0};
  }
`;

const GeneralInfoLabel = styled.div`
  font-size: ${font.sizeCaption1};
  line-height: ${font.sizeCaption1};
  font-weight: ${font.weightBold};
  font-family: ${font.familyLegible};
`;

const GeneralInfoValue = styled.div`
  font-size: ${font.sizeBody1};
  line-height: ${font.sizeBody1};
  padding-top: ${spacing['1x']};

  @media ${mq.maxWidthMobileLandscape} {
    font-size: ${font.sizeBody2};
    line-height: ${font.sizeBody2};
  }
`;

const PropertyGeneralInfoGroup = styled.div<{showListingPageUrl: boolean}>`
  display: flex;
  ${({showListingPageUrl}) => !showListingPageUrl && 'flex-grow: 1;'}

  @media ${mq.maxWidthMobileLandscape} {
    justify-content: space-around;
  }
`;

const PropertyViewDetailsLink = styled.div`
  display: flex;
  align-items: center;
  padding-left: ${spacing['2x']};
  font-size: ${font.sizeBody2};
  border-left: 1px solid ${color.border};
  letter-spacing: 0.5px;
  font-weight: ${font.weightMedium};
  .link-icon {
    color: ${color.link};
    fill: currentColor;
    margin: 0 ${spacing['1x']} 0 ${spacing.half};
    transform: scale(1.2);
  }
  @media ${mq.maxWidthMobileLandscape} {
    padding: ${spacing['2x']};
    border-top: ${`1px solid ${color.border}`};
    border-left: 0;
    margin: ${spacing['1x']} -${spacing['2x']} -${spacing['1x']};
  }
`;

type Props = {
  hiddenPropertyInformation?: string[];
  listing?: ProcessedListing;
  showListingPageUrl?: boolean;
  topBorder?: boolean;
  selectListingAttributes?: CustomListingAttribute[];
  // TODO[https://compass-tech.atlassian.net/browse/ACT-5294]: update @uc/cma-output-sections to export the defined AllDisplayFieldsType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  allDisplayFields?: any;
};

export const PropertyGeneralInfoForCmaLp: FC<Props> = ({
  listing = {},
  topBorder = false,
  showListingPageUrl = false,
  hiddenPropertyInformation = [],
  selectListingAttributes,
  allDisplayFields,
}) => {
  const info = getPropertyGeneralInfo({
    listing,
    hiddenPropertyInformation,
    selectListingAttributes,
    allDisplayFields,
  });
  const listingUrl = formatListingUrl(listing);
  const showListingUrl = !!(listingUrl && showListingPageUrl);

  return (
    <Wrapper data-tn="Wrapper-view" topBorder={topBorder}>
      <NoSsr>
        <PropertyGeneralInfoGroup
          data-tn="PropertyGeneralInfoGroup-view-group"
          showListingPageUrl={showListingUrl}
        >
          {Object.keys(info).map((label, idx) => {
            return (
              <PropertyGeneralInfoCell
                data-tn="PropertyGeneralInfoCell-view-cell"
                data-label={label}
                key={idx}
                isFirst={idx === 0}
              >
                <GeneralInfoLabel>{label}</GeneralInfoLabel>
                <GeneralInfoValue data-tn="PropertyGeneralInfoCell-view-value">
                  {info[label]}
                </GeneralInfoValue>
              </PropertyGeneralInfoCell>
            );
          })}
          {showListingUrl && <Separator data-tn="Separator-view" />}
        </PropertyGeneralInfoGroup>
      </NoSsr>
      {showListingUrl && (
        <PropertyViewDetailsLink>
          <Icon.DoubleBoxedArrowUpRight
            width="16"
            height="16"
            className="link-icon"
          />
          <TextLink
            data-tn="TextLink-link-viewDetails"
            href={listingUrl}
            target="_blank"
          >
            View full details
          </TextLink>
        </PropertyViewDetailsLink>
      )}
    </Wrapper>
  );
};
