import {useMemo, VFC} from 'react';
import React from 'react';

import {SellerNetSheetCalculationResponse} from '@uc/thrift2npme/dist/apex_proxy/apex_proxy_service.ucfetch';

import {useStateValue} from '@/pages/Web/context/state';
import {NetsheetProps} from '@/types/netsheet';
import {getSellerNetSheetDataFilter} from '@/utils/netsheets/getSellerNetSheetDataFilter';
import {NetsheetGenericPage} from './NetsheetGenericPage';
import {getSNSAuthorInfo} from '@/utils/netsheets/getSNSAuthorInfo';
import {PartnerFooter} from '../NetsheetFooter';
import {getBreakdownFullPriceValue} from '@/utils/getBreakdownFullPriceValue';
import {
  decorateCalculationResults,
  getStatisticalResults,
} from '@/hooks/useSellerNetSheetCalculation';

export const ListingPresentationNetSheetSection: VFC<NetsheetProps> = ({
  isPreview,
}) => {
  const [state] = useStateValue();
  const {cma = {}} = state ?? {};
  const {author, lastUpdateDate} = getSNSAuthorInfo(cma, cma);

  // Results saved as string
  const sellerNetSheetCalculationResult = JSON.parse(
    cma?.agentInputs?.netsheets?.sellerNetSheetBECalculations ?? '{}',
  ) as unknown as SellerNetSheetCalculationResponse;

  const isDisplayPriceBreakdown = getBreakdownFullPriceValue(
    state?.agentInputs?.presentationalSettings,
  );

  const calculationResult = useMemo(
    () =>
      isDisplayPriceBreakdown
        ? decorateCalculationResults(sellerNetSheetCalculationResult)
        : getStatisticalResults(sellerNetSheetCalculationResult),
    [
      sellerNetSheetCalculationResult,
      isDisplayPriceBreakdown,
      decorateCalculationResults,
      getStatisticalResults,
    ],
  );

  const partnerMetadata =
    cma.agentInputs?.netsheets?.input?.partnerMetadata ?? {};
  const isShowPartnerLogo =
    !!cma.agentInputs?.netsheets?.input?.showSettlementCostsFromPartner;

  const {
    total,
    subTotal,
    estimatedClosingLayout,
    salesPriceLayout,
    otherCostsLayout,
  } = useMemo(
    () => getSellerNetSheetDataFilter(calculationResult),
    [calculationResult, getSellerNetSheetDataFilter],
  );

  const layouts = useMemo(
    () => [salesPriceLayout, estimatedClosingLayout, otherCostsLayout],
    [salesPriceLayout, estimatedClosingLayout, otherCostsLayout],
  );

  if (!salesPriceLayout?.groups?.length) {
    return null;
  }

  return (
    <NetsheetGenericPage
      total={total}
      subTotal={subTotal}
      isPreview={isPreview}
      author={author}
      date={lastUpdateDate}
      layouts={layouts}
      footer={
        <PartnerFooter
          partnerMetadata={partnerMetadata}
          isShowGeneratedBy={isShowPartnerLogo}
        />
      }
    />
  );
};
